// // 10:30

export const Ten = [
  [
    { id: 1, value: "12", color: "black" },
    { id: 2, value: "45", color: "black" },
    { id: 3, value: "46", color: "black" },
    { id: 4, value: "34", color: "black" },
    { id: 5, value: "11", color: "black" },
    { id: 6, value: "69", color: "black" },
    { id: 7, value: "23", color: "black" },
  ],
  [
    { id: 8, value: "23", color: "black" },
    { id: 9, value: "56", color: "black" },
    { id: 10, value: "20", color: "black" },
    { id: 11, value: "19", color: "black" },
    { id: 12, value: "13", color: "black" },
    { id: 13, value: "32", color: "black" },
    { id: 14, value: "23", color: "black" },
  ],
  [
    { id: 15, value: "62", color: "black" },
    { id: 16, value: "54", color: "black" },
    { id: 17, value: "62", color: "black" },
    { id: 18, value: "17", color: "black" },
    { id: 19, value: "20", color: "black" },
    { id: 20, value: "06", color: "black" },
    { id: 21, value: "10", color: "black" },
  ],
  [
    { id: 22, value: "03", color: "black" },
    { id: 23, value: "26", color: "black" },
    { id: 24, value: "34", color: "black" },
    { id: 25, value: "33", color: "black" },
    { id: 26, value: "20", color: "black" },
    { id: 27, value: "03", color: "black" },
    { id: 28, value: "01", color: "black" },
  ],
  [
    { id: 29, value: "53", color: "black" },
    { id: 30, value: "02", color: "black" },
    { id: 31, value: "25", color: "black" },
    { id: 32, value: "97", color: "black" },
    { id: 33, value: "04", color: "black" },
    { id: 34, value: "46", color: "black" },
    { id: 35, value: "94", color: "black" },
  ],
  [
    { id: 36, value: "07", color: "black" },
    { id: 37, value: "46", color: "black" },
    { id: 38, value: "17", color: "black" },
    { id: 39, value: "30", color: "black" },
    { id: 40, value: "02", color: "black" },
    { id: 41, value: "11", color: "black" },
    { id: 42, value: "29", color: "black" },
  ],
  [
    { id: 43, value: "08", color: "black" },
    { id: 44, value: "56", color: "black" },
    { id: 45, value: "29", color: "black" },
    { id: 46, value: "53", color: "black" },
    { id: 47, value: "38", color: "black" },
    { id: 48, value: "17", color: "black" },
    { id: 49, value: "25", color: "black" },
  ],
  [
    { id: 50, value: "46", color: "black" },
    { id: 51, value: "03", color: "black" },
    { id: 52, value: "36", color: "black" },
    { id: 53, value: "25", color: "black" },
    { id: 54, value: "90", color: "black" },
    { id: 55, value: "13", color: "black" },
    { id: 56, value: "15", color: "black" },
  ],
  [
    { id: 57, value: "18", color: "black" },
    { id: 58, value: "02", color: "black" },
    { id: 59, value: "99", color: "black" },
    { id: 60, value: "25", color: "black" },
    { id: 61, value: "01", color: "black" },
    { id: 62, value: "40", color: "black" },
    { id: 63, value: "13", color: "black" },
  ],
  [
    { id: 64, value: "89", color: "black" },
    { id: 65, value: "10", color: "black" },
    { id: 66, value: "99", color: "black" },
    { id: 67, value: "93", color: "black" },
    { id: 68, value: "97", color: "black" },
    { id: 69, value: "04", color: "black" },
    { id: 70, value: "96", color: "black" },
  ],
  [
    { id: 71, value: "92", color: "black" },
    { id: 72, value: "02", color: "black" },
    { id: 73, value: "24", color: "black" },
    { id: 74, value: "03", color: "black" },
    { id: 75, value: "93", color: "black" },
    { id: 76, value: "02", color: "black" },
    { id: 77, value: "75", color: "black" },
  ],
  [
    { id: 78, value: "01", color: "black" },
    { id: 79, value: "19", color: "black" },
    { id: 80, value: "29", color: "black" },
    { id: 81, value: "31", color: "black" },
    { id: 82, value: "64", color: "black" },
    { id: 83, value: "13", color: "black" },
    { id: 84, value: "07", color: "black" },
  ],
  [
    { id: 85, value: "61", color: "black" },
    { id: 86, value: "25", color: "black" },
    { id: 87, value: "64", color: "black" },
    { id: 88, value: "86", color: "black" },
    { id: 89, value: "63", color: "black" },
    { id: 90, value: "93", color: "black" },
    { id: 91, value: "58", color: "black" },
  ],
  [
    { id: 92, value: "61", color: "black" },
    { id: 93, value: "43", color: "black" },
    { id: 94, value: "72", color: "black" },
    { id: 95, value: "04", color: "black" },
    { id: 96, value: "46", color: "black" },
    { id: 97, value: "56", color: "black" },
    { id: 98, value: "42", color: "black" },
  ],
  [
    { id: 99, value: "27", color: "black" },
    { id: 100, value: "08", color: "black" },
    { id: 101, value: "55", color: "black" },
    { id: 102, value: "59", color: "black" },
    { id: 103, value: "98", color: "black" },
    { id: 104, value: "16", color: "black" },
    { id: 105, value: "19", color: "black" },
  ],
  [
    { id: 106, value: "92", color: "black" },
    { id: 107, value: "85", color: "black" },
    { id: 108, value: "06", color: "black" },
    { id: 109, value: "94", color: "black" },
    { id: 110, value: "67", color: "black" },
    { id: 111, value: "43", color: "black" },
    { id: 112, value: "28", color: "black" },
  ],
  [
    { id: 113, value: "27", color: "black" },
    { id: 114, value: "09", color: "black" },
    { id: 115, value: "55", color: "black" },
    { id: 116, value: "76", color: "black" },
    { id: 117, value: "06", color: "black" },
    { id: 118, value: "17", color: "black" },
    { id: 119, value: "84", color: "black" },
  ],
  [
    { id: 120, value: "49", color: "black" },
    { id: 121, value: "63", color: "black" },
    { id: 122, value: "16", color: "black" },
    { id: 123, value: "68", color: "black" },
    { id: 124, value: "06", color: "black" },
    { id: 125, value: "30", color: "black" },
    { id: 126, value: "70", color: "black" },
  ],
  [
    { id: 127, value: "20", color: "black" },
    { id: 128, value: "08", color: "black" },
    { id: 129, value: "41", color: "black" },
    { id: 130, value: "36", color: "black" },
    { id: 131, value: "71", color: "black" },
    { id: 132, value: "93", color: "black" },
    { id: 133, value: "49", color: "black" },
  ],
  [
    { id: 134, value: "08", color: "black" },
    { id: 135, value: "58", color: "black" },
    { id: 136, value: "50", color: "black" },
    { id: 137, value: "38", color: "black" },
    { id: 138, value: "29", color: "black" },
    { id: 139, value: "89", color: "black" },
    { id: 140, value: "02", color: "black" },
  ],
  [
    { id: 141, value: "16", color: "black" },
    { id: 142, value: "94", color: "black" },
    { id: 143, value: "35", color: "black" },
    { id: 144, value: "71", color: "black" },
    { id: 145, value: "22", color: "black" },
    { id: 146, value: "96", color: "black" },
    { id: 147, value: "03", color: "black" },
  ],
  [
    { id: 148, value: "90", color: "black" },
    { id: 149, value: "34", color: "black" },
    { id: 150, value: "10", color: "black" },
    { id: 151, value: "05", color: "black" },
    { id: 152, value: "60", color: "black" },
    { id: 153, value: "27", color: "black" },
    { id: 154, value: "37", color: "black" },
  ],
  [
    { id: 155, value: "23", color: "black" },
    { id: 156, value: "26", color: "black" },
    { id: 157, value: "20", color: "black" },
    { id: 158, value: "39", color: "black" },
    { id: 159, value: "09", color: "black" },
    { id: 160, value: "79", color: "black" },
    { id: 161, value: "08", color: "black" },
  ],
  [
    { id: 162, value: "15", color: "black" },
    { id: 163, value: "38", color: "black" },
    { id: 164, value: "56", color: "black" },
    { id: 165, value: "23", color: "black" },
    { id: 166, value: "37", color: "black" },
    { id: 167, value: "75", color: "black" },
    { id: 168, value: "04", color: "black" },
  ],
  [
    { id: 169, value: "84", color: "black" },
    { id: 170, value: "12", color: "black" },
    { id: 171, value: "15", color: "black" },
    { id: 172, value: "12", color: "black" },
    { id: 173, value: "58", color: "black" },
    { id: 174, value: "56", color: "black" },
    { id: 175, value: "23", color: "black" },
  ],
  [
    { id: 176, value: "13", color: "black" },
    { id: 177, value: "73", color: "black" },
    { id: 178, value: "68", color: "black" },
    { id: 179, value: "12", color: "black" },
    { id: 180, value: "59", color: "black" },
    { id: 181, value: "19", color: "black" },
    { id: 182, value: "14", color: "black" },
  ],
  [
    { id: 183, value: "14", color: "black" },
    { id: 184, value: "11", color: "black" },
    { id: 185, value: "23", color: "black" },
    { id: 186, value: "12", color: "black" },
    { id: 187, value: "12", color: "black" },
    { id: 188, value: "11", color: "black" },
    { id: 189, value: "11", color: "black" },
  ],
  [
    { id: 190, value: "11", color: "black" },
    { id: 191, value: "71", color: "black" },
    { id: 192, value: "52", color: "black" },
    { id: 193, value: "78", color: "black" },
    { id: 194, value: "61", color: "black" },
    { id: 195, value: "70", color: "black" },
    { id: 196, value: "63", color: "black" },
  ],
  [
    { id: 197, value: "56", color: "black" },
    { id: 198, value: "75", color: "black" },
    { id: 199, value: "93", color: "black" },
    { id: 200, value: "68", color: "black" },
    { id: 201, value: "45", color: "black" },
    { id: 202, value: "71", color: "black" },
    { id: 203, value: "**", color: "black" },
  ],
];

// 11.30

export const Eleven = [
  [
    { id: 1, value: "76", color: "black" },
    { id: 2, value: "77", color: "black" },
    { id: 3, value: "55", color: "black" },
    { id: 4, value: "43", color: "black" },
    { id: 5, value: "14", color: "black" },
    { id: 6, value: "75", color: "black" },
    { id: 7, value: "85", color: "black" },
  ],
  [
    { id: 8, value: "31", color: "black" },
    { id: 9, value: "08", color: "black" },
    { id: 10, value: "52", color: "black" },
    { id: 11, value: "15", color: "black" },
    { id: 12, value: "01", color: "black" },
    { id: 13, value: "49", color: "black" },
    { id: 14, value: "79", color: "black" },
  ],
  [
    { id: 15, value: "16", color: "black" },
    { id: 16, value: "73", color: "black" },
    { id: 17, value: "42", color: "black" },
    { id: 18, value: "27", color: "black" },
    { id: 19, value: "03", color: "black" },
    { id: 20, value: "28", color: "black" },
    { id: 21, value: "29", color: "black" },
  ],
  [
    { id: 22, value: "32", color: "black" },
    { id: 23, value: "83", color: "black" },
    { id: 24, value: "11", color: "black" },
    { id: 25, value: "11", color: "black" },
    { id: 26, value: "07", color: "black" },
    { id: 27, value: "12", color: "black" },
    { id: 28, value: "18", color: "black" },
  ],
  [
    { id: 29, value: "41", color: "black" },
    { id: 30, value: "28", color: "black" },
    { id: 31, value: "49", color: "black" },
    { id: 32, value: "61", color: "black" },
    { id: 33, value: "94", color: "black" },
    { id: 34, value: "99", color: "black" },
    { id: 35, value: "69", color: "black" },
  ],
  [
    { id: 36, value: "72", color: "black" },
    { id: 37, value: "11", color: "black" },
    { id: 38, value: "84", color: "black" },
    { id: 39, value: "07", color: "black" },
    { id: 40, value: "98", color: "black" },
    { id: 41, value: "11", color: "black" },
    { id: 42, value: "07", color: "black" },
  ],
  [
    { id: 43, value: "99", color: "black" },
    { id: 44, value: "41", color: "black" },
    { id: 45, value: "67", color: "black" },
    { id: 46, value: "74", color: "black" },
    { id: 47, value: "15", color: "black" },
    { id: 48, value: "88", color: "black" },
    { id: 49, value: "40", color: "black" },
  ],
  [
    { id: 50, value: "05", color: "black" },
    { id: 51, value: "62", color: "black" },
    { id: 52, value: "71", color: "black" },
    { id: 53, value: "71", color: "black" },
    { id: 54, value: "13", color: "black" },
    { id: 55, value: "29", color: "black" },
    { id: 56, value: "54", color: "black" },
  ],
  [
    { id: 57, value: "11", color: "black" },
    { id: 58, value: "11", color: "black" },
    { id: 59, value: "22", color: "black" },
    { id: 60, value: "53", color: "black" },
    { id: 61, value: "76", color: "black" },
    { id: 62, value: "52", color: "black" },
    { id: 63, value: "66", color: "black" },
  ],
  [
    { id: 64, value: "30", color: "black" },
    { id: 65, value: "68", color: "black" },
    { id: 66, value: "82", color: "black" },
    { id: 67, value: "77", color: "black" },
    { id: 68, value: "48", color: "black" },
    { id: 69, value: "30", color: "black" },
    { id: 70, value: "16", color: "black" },
  ],
  [
    { id: 71, value: "65", color: "black" },
    { id: 72, value: "66", color: "black" },
    { id: 73, value: "11", color: "black" },
    { id: 74, value: "90", color: "black" },
    { id: 75, value: "48", color: "black" },
    { id: 76, value: "78", color: "black" },
    { id: 77, value: "90", color: "black" },
  ],
  [
    { id: 78, value: "26", color: "black" },
    { id: 79, value: "37", color: "black" },
    { id: 80, value: "96", color: "black" },
    { id: 81, value: "31", color: "black" },
    { id: 82, value: "43", color: "black" },
    { id: 83, value: "89", color: "black" },
    { id: 84, value: "25", color: "black" },
  ],
  [
    { id: 85, value: "78", color: "black" },
    { id: 86, value: "07", color: "black" },
    { id: 87, value: "29", color: "black" },
    { id: 88, value: "37", color: "black" },
    { id: 89, value: "52", color: "black" },
    { id: 90, value: "40", color: "black" },
    { id: 91, value: "78", color: "black" },
  ],
  [
    { id: 92, value: "84", color: "black" },
    { id: 93, value: "32", color: "black" },
    { id: 94, value: "63", color: "black" },
    { id: 95, value: "83", color: "black" },
    { id: 96, value: "02", color: "black" },
    { id: 97, value: "66", color: "black" },
    { id: 98, value: "47", color: "black" },
  ],
  [
    { id: 99, value: "39", color: "black" },
    { id: 100, value: "25", color: "black" },
    { id: 101, value: "07", color: "black" },
    { id: 102, value: "89", color: "black" },
    { id: 103, value: "17", color: "black" },
    { id: 104, value: "97", color: "black" },
    { id: 105, value: "15", color: "black" },
  ],
  [
    { id: 106, value: "39", color: "black" },
    { id: 107, value: "25", color: "black" },
    { id: 108, value: "98", color: "black" },
    { id: 109, value: "63", color: "black" },
    { id: 110, value: "93", color: "black" },
    { id: 111, value: "49", color: "black" },
    { id: 112, value: "79", color: "black" },
  ],
  [
    { id: 113, value: "44", color: "black" },
    { id: 114, value: "69", color: "black" },
    { id: 115, value: "98", color: "black" },
    { id: 116, value: "05", color: "black" },
    { id: 117, value: "17", color: "black" },
    { id: 118, value: "85", color: "black" },
    { id: 119, value: "15", color: "black" },
  ],
  [
    { id: 120, value: "18", color: "black" },
    { id: 121, value: "63", color: "black" },
    { id: 122, value: "16", color: "black" },
    { id: 123, value: "93", color: "black" },
    { id: 124, value: "29", color: "black" },
    { id: 125, value: "31", color: "black" },
    { id: 126, value: "97", color: "black" },
  ],
  [
    { id: 127, value: "83", color: "black" },
    { id: 128, value: "37", color: "black" },
    { id: 129, value: "21", color: "black" },
    { id: 130, value: "04", color: "black" },
    { id: 131, value: "89", color: "black" },
    { id: 132, value: "66", color: "black" },
    { id: 133, value: "84", color: "black" },
  ],
  [
    { id: 134, value: "37", color: "black" },
    { id: 135, value: "12", color: "black" },
    { id: 136, value: "42", color: "black" },
    { id: 137, value: "18", color: "black" },
    { id: 138, value: "64", color: "black" },
    { id: 139, value: "27", color: "black" },
    { id: 140, value: "35", color: "black" },
  ],
  [
    { id: 141, value: "68", color: "black" },
    { id: 142, value: "45", color: "black" },
    { id: 143, value: "19", color: "black" },
    { id: 144, value: "34", color: "black" },
    { id: 145, value: "63", color: "black" },
    { id: 146, value: "64", color: "black" },
    { id: 147, value: "20", color: "black" },
  ],
  [
    { id: 148, value: "91", color: "black" },
    { id: 149, value: "49", color: "black" },
    { id: 150, value: "53", color: "black" },
    { id: 151, value: "13", color: "black" },
    { id: 152, value: "95", color: "black" },
    { id: 153, value: "56", color: "black" },
    { id: 154, value: "89", color: "black" },
  ],
  [
    { id: 155, value: "85", color: "black" },
    { id: 156, value: "98", color: "black" },
    { id: 157, value: "83", color: "black" },
    { id: 158, value: "51", color: "black" },
    { id: 159, value: "65", color: "black" },
    { id: 160, value: "51", color: "black" },
    { id: 161, value: "62", color: "black" },
  ],
  [
    { id: 162, value: "94", color: "black" },
    { id: 163, value: "04", color: "black" },
    { id: 164, value: "67", color: "black" },
    { id: 165, value: "92", color: "black" },
    { id: 166, value: "61", color: "black" },
    { id: 167, value: "59", color: "black" },
    { id: 168, value: "53", color: "black" },
  ],
  [
    { id: 169, value: "72", color: "black" },
    { id: 170, value: "15", color: "black" },
    { id: 171, value: "64", color: "black" },
    { id: 172, value: "66", color: "black" },
    { id: 173, value: "94", color: "black" },
    { id: 174, value: "87", color: "black" },
    { id: 175, value: "67", color: "black" },
  ],
  [
    { id: 176, value: "15", color: "black" },
    { id: 177, value: "87", color: "black" },
    { id: 178, value: "34", color: "black" },
    { id: 179, value: "68", color: "black" },
    { id: 180, value: "49", color: "black" },
    { id: 181, value: "88", color: "black" },
    { id: 182, value: "18", color: "black" },
  ],
  [
    { id: 183, value: "18", color: "black" },
    { id: 184, value: "16", color: "black" },
    { id: 185, value: "12", color: "black" },
    { id: 186, value: "19", color: "black" },
    { id: 187, value: "13", color: "black" },
    { id: 188, value: "12", color: "black" },
    { id: 189, value: "45", color: "black" },
  ],
  [
    { id: 190, value: "12", color: "black" },
    { id: 191, value: "42", color: "black" },
    { id: 192, value: "81", color: "black" },
    { id: 193, value: "39", color: "black" },
    { id: 194, value: "36", color: "black" },
    { id: 195, value: "35", color: "black" },
    { id: 196, value: "36", color: "black" },
  ],
  [
    { id: 197, value: "28", color: "black" },
    { id: 198, value: "49", color: "black" },
    { id: 199, value: "46", color: "black" },
    { id: 200, value: "32", color: "black" },
    { id: 201, value: "21", color: "black" },
    { id: 202, value: "19", color: "black" },
    { id: 203, value: "**", color: "black" },
  ],
];

// 12.30

export const Twelve = [
  [
    { id: 1, value: "77", color: "black" },
    { id: 2, value: "79", color: "black" },
    { id: 3, value: "20", color: "black" },
    { id: 4, value: "63", color: "black" },
    { id: 5, value: "93", color: "black" },
    { id: 6, value: "12", color: "black" },
    { id: 7, value: "81", color: "black" },
  ],
  [
    { id: 8, value: "54", color: "black" },
    { id: 9, value: "36", color: "black" },
    { id: 10, value: "11", color: "black" },
    { id: 11, value: "13", color: "black" },
    { id: 12, value: "17", color: "black" },
    { id: 13, value: "30", color: "black" },
    { id: 14, value: "69", color: "black" },
  ],
  [
    { id: 15, value: "65", color: "black" },
    { id: 16, value: "80", color: "black" },
    { id: 17, value: "04", color: "black" },
    { id: 18, value: "35", color: "black" },
    { id: 19, value: "33", color: "black" },
    { id: 20, value: "25", color: "black" },
    { id: 21, value: "83", color: "black" },
  ],
  [
    { id: 22, value: "99", color: "black" },
    { id: 23, value: "25", color: "black" },
    { id: 24, value: "46", color: "black" },
    { id: 25, value: "99", color: "black" },
    { id: 26, value: "56", color: "black" },
    { id: 27, value: "30", color: "black" },
    { id: 28, value: "25", color: "black" },
  ],
  [
    { id: 29, value: "45", color: "black" },
    { id: 30, value: "59", color: "black" },
    { id: 31, value: "19", color: "black" },
    { id: 32, value: "16", color: "black" },
    { id: 33, value: "10", color: "black" },
    { id: 34, value: "18", color: "black" },
    { id: 35, value: "20", color: "black" },
  ],
  [
    { id: 36, value: "96", color: "black" },
    { id: 37, value: "60", color: "black" },
    { id: 38, value: "32", color: "black" },
    { id: 39, value: "72", color: "black" },
    { id: 40, value: "17", color: "black" },
    { id: 41, value: "61", color: "black" },
    { id: 42, value: "50", color: "black" },
  ],
  [
    { id: 43, value: "01", color: "black" },
    { id: 44, value: "43", color: "black" },
    { id: 45, value: "73", color: "black" },
    { id: 46, value: "01", color: "black" },
    { id: 47, value: "73", color: "black" },
    { id: 48, value: "28", color: "black" },
    { id: 49, value: "62", color: "black" },
  ],
  [
    { id: 50, value: "57", color: "black" },
    { id: 51, value: "60", color: "black" },
    { id: 52, value: "99", color: "black" },
    { id: 53, value: "49", color: "black" },
    { id: 54, value: "20", color: "black" },
    { id: 55, value: "72", color: "black" },
    { id: 56, value: "58", color: "black" },
  ],
  [
    { id: 57, value: "58", color: "black" },
    { id: 58, value: "32", color: "black" },
    { id: 59, value: "33", color: "black" },
    { id: 60, value: "68", color: "black" },
    { id: 61, value: "71", color: "black" },
    { id: 62, value: "86", color: "black" },
    { id: 63, value: "63", color: "black" },
  ],
  [
    { id: 64, value: "68", color: "black" },
    { id: 65, value: "70", color: "black" },
    { id: 66, value: "72", color: "black" },
    { id: 67, value: "43", color: "black" },
    { id: 68, value: "41", color: "black" },
    { id: 69, value: "11", color: "black" },
    { id: 70, value: "99", color: "black" },
  ],
  [
    { id: 71, value: "33", color: "black" },
    { id: 72, value: "33", color: "black" },
    { id: 73, value: "21", color: "black" },
    { id: 74, value: "31", color: "black" },
    { id: 75, value: "21", color: "black" },
    { id: 76, value: "11", color: "black" },
    { id: 77, value: "81", color: "black" },
  ],
  [
    { id: 78, value: "27", color: "black" },
    { id: 79, value: "36", color: "black" },
    { id: 80, value: "31", color: "black" },
    { id: 81, value: "88", color: "black" },
    { id: 82, value: "31", color: "black" },
    { id: 83, value: "51", color: "black" },
    { id: 84, value: "16", color: "black" },
  ],
  [
    { id: 85, value: "84", color: "black" },
    { id: 86, value: "55", color: "black" },
    { id: 87, value: "66", color: "black" },
    { id: 88, value: "33", color: "black" },
    { id: 89, value: "94", color: "black" },
    { id: 90, value: "67", color: "black" },
    { id: 91, value: "75", color: "black" },
  ],
  [
    { id: 92, value: "41", color: "black" },
    { id: 93, value: "88", color: "black" },
    { id: 94, value: "97", color: "black" },
    { id: 95, value: "61", color: "black" },
    { id: 96, value: "88", color: "black" },
    { id: 97, value: "90", color: "black" },
    { id: 98, value: "67", color: "black" },
  ],
  [
    { id: 99, value: "54", color: "black" },
    { id: 100, value: "42", color: "black" },
    { id: 101, value: "77", color: "black" },
    { id: 102, value: "27", color: "black" },
    { id: 103, value: "80", color: "black" },
    { id: 104, value: "27", color: "black" },
    { id: 105, value: "18", color: "black" },
  ],
  [
    { id: 106, value: "11", color: "black" },
    { id: 107, value: "81", color: "black" },
    { id: 108, value: "69", color: "black" },
    { id: 109, value: "10", color: "black" },
    { id: 110, value: "20", color: "black" },
    { id: 111, value: "16", color: "black" },
    { id: 112, value: "05", color: "black" },
  ],
  [
    { id: 113, value: "55", color: "black" },
    { id: 114, value: "79", color: "black" },
    { id: 115, value: "42", color: "black" },
    { id: 116, value: "26", color: "black" },
    { id: 117, value: "52", color: "black" },
    { id: 118, value: "69", color: "black" },
    { id: 119, value: "90", color: "black" },
  ],
  [
    { id: 120, value: "77", color: "black" },
    { id: 121, value: "38", color: "black" },
    { id: 122, value: "83", color: "black" },
    { id: 123, value: "75", color: "black" },
    { id: 124, value: "31", color: "black" },
    { id: 125, value: "18", color: "black" },
    { id: 126, value: "72", color: "black" },
  ],
  [
    { id: 127, value: "70", color: "black" },
    { id: 128, value: "74", color: "black" },
    { id: 129, value: "84", color: "black" },
    { id: 130, value: "81", color: "black" },
    { id: 131, value: "86", color: "black" },
    { id: 132, value: "12", color: "black" },
    { id: 133, value: "12", color: "black" },
  ],
  [
    { id: 134, value: "87", color: "black" },
    { id: 135, value: "37", color: "black" },
    { id: 136, value: "19", color: "black" },
    { id: 137, value: "40", color: "black" },
    { id: 138, value: "67", color: "black" },
    { id: 139, value: "13", color: "black" },
    { id: 140, value: "59", color: "black" },
  ],
  [
    { id: 141, value: "41", color: "black" },
    { id: 142, value: "33", color: "black" },
    { id: 143, value: "70", color: "black" },
    { id: 144, value: "55", color: "black" },
    { id: 145, value: "35", color: "black" },
    { id: 146, value: "56", color: "black" },
    { id: 147, value: "31", color: "black" },
  ],
  [
    { id: 148, value: "86", color: "black" },
    { id: 149, value: "95", color: "black" },
    { id: 150, value: "18", color: "black" },
    { id: 151, value: "45", color: "black" },
    { id: 152, value: "03", color: "black" },
    { id: 153, value: "48", color: "black" },
    { id: 154, value: "26", color: "black" },
  ],
  [
    { id: 155, value: "67", color: "black" },
    { id: 156, value: "31", color: "black" },
    { id: 157, value: "93", color: "black" },
    { id: 158, value: "51", color: "black" },
    { id: 159, value: "52", color: "black" },
    { id: 160, value: "26", color: "black" },
    { id: 161, value: "88", color: "black" },
  ],
  [
    { id: 162, value: "91", color: "black" },
    { id: 163, value: "75", color: "black" },
    { id: 164, value: "30", color: "black" },
    { id: 165, value: "31", color: "black" },
    { id: 166, value: "05", color: "black" },
    { id: 167, value: "02", color: "black" },
    { id: 168, value: "80", color: "black" },
  ],
  [
    { id: 169, value: "58", color: "black" },
    { id: 170, value: "16", color: "black" },
    { id: 171, value: "68", color: "black" },
    { id: 172, value: "45", color: "black" },
    { id: 173, value: "78", color: "black" },
    { id: 174, value: "45", color: "black" },
    { id: 175, value: "19", color: "black" },
  ],
  [
    { id: 176, value: "10", color: "black" },
    { id: 177, value: "94", color: "black" },
    { id: 178, value: "48", color: "black" },
    { id: 179, value: "23", color: "black" },
    { id: 180, value: "30", color: "black" },
    { id: 181, value: "66", color: "black" },
    { id: 182, value: "38", color: "black" },
  ],
  [
    { id: 183, value: "10", color: "black" },
    { id: 184, value: "32", color: "black" },
    { id: 185, value: "17", color: "black" },
    { id: 186, value: "14", color: "black" },
    { id: 187, value: "14", color: "black" },
    { id: 188, value: "13", color: "black" },
    { id: 189, value: "58", color: "black" },
  ],
  [
    { id: 190, value: "13", color: "black" },
    { id: 191, value: "84", color: "black" },
    { id: 192, value: "73", color: "black" },
    { id: 193, value: "93", color: "black" },
    { id: 194, value: "12", color: "black" },
    { id: 195, value: "17", color: "black" },
    { id: 196, value: "18", color: "black" },
  ],
  [
    { id: 197, value: "14", color: "black" },
    { id: 198, value: "63", color: "black" },
    { id: 199, value: "23", color: "black" },
    { id: 200, value: "16", color: "black" },
    { id: 201, value: "57", color: "black" },
    { id: 202, value: "38", color: "black" },
    { id: 203, value: "**", color: "black" },
  ],
];

// 1.30

export const One = [
  [
    { id: 1, value: "40", color: "black" },
    { id: 2, value: "31", color: "black" },
    { id: 3, value: "41", color: "black" },
    { id: 4, value: "19", color: "black" },
    { id: 5, value: "83", color: "black" },
    { id: 6, value: "51", color: "black" },
    { id: 7, value: "45", color: "black" },
  ],
  [
    { id: 8, value: "21", color: "black" },
    { id: 9, value: "93", color: "black" },
    { id: 10, value: "15", color: "black" },
    { id: 11, value: "63", color: "black" },
    { id: 12, value: "09", color: "black" },
    { id: 13, value: "16", color: "black" },
    { id: 14, value: "49", color: "black" },
  ],
  [
    { id: 15, value: "08", color: "black" },
    { id: 16, value: "16", color: "black" },
    { id: 17, value: "34", color: "black" },
    { id: 18, value: "55", color: "black" },
    { id: 19, value: "06", color: "black" },
    { id: 20, value: "87", color: "black" },
    { id: 21, value: "02", color: "black" },
  ],
  [
    { id: 22, value: "87", color: "black" },
    { id: 23, value: "79", color: "black" },
    { id: 24, value: "21", color: "black" },
    { id: 25, value: "10", color: "black" },
    { id: 26, value: "22", color: "black" },
    { id: 27, value: "31", color: "black" },
    { id: 28, value: "38", color: "black" },
  ],
  [
    { id: 29, value: "90", color: "black" },
    { id: 30, value: "18", color: "black" },
    { id: 31, value: "82", color: "black" },
    { id: 32, value: "14", color: "black" },
    { id: 33, value: "67", color: "black" },
    { id: 34, value: "87", color: "black" },
    { id: 35, value: "66", color: "black" },
  ],
  [
    { id: 36, value: "81", color: "black" },
    { id: 37, value: "99", color: "black" },
    { id: 38, value: "01", color: "black" },
    { id: 39, value: "93", color: "black" },
    { id: 40, value: "44", color: "black" },
    { id: 41, value: "64", color: "black" },
    { id: 42, value: "20", color: "black" },
  ],
  [
    { id: 43, value: "37", color: "black" },
    { id: 44, value: "37", color: "black" },
    { id: 45, value: "16", color: "black" },
    { id: 46, value: "36", color: "black" },
    { id: 47, value: "84", color: "black" },
    { id: 48, value: "04", color: "black" },
    { id: 49, value: "75", color: "black" },
  ],
  [
    { id: 50, value: "75", color: "black" },
    { id: 51, value: "81", color: "black" },
    { id: 52, value: "62", color: "black" },
    { id: 53, value: "80", color: "black" },
    { id: 54, value: "27", color: "black" },
    { id: 55, value: "48", color: "black" },
    { id: 56, value: "78", color: "black" },
  ],
  [
    { id: 57, value: "82", color: "black" },
    { id: 58, value: "06", color: "black" },
    { id: 59, value: "50", color: "black" },
    { id: 60, value: "13", color: "black" },
    { id: 61, value: "09", color: "black" },
    { id: 62, value: "03", color: "black" },
    { id: 63, value: "33", color: "black" },
  ],
  [
    { id: 64, value: "13", color: "black" },
    { id: 65, value: "02", color: "black" },
    { id: 66, value: "72", color: "black" },
    { id: 67, value: "70", color: "black" },
    { id: 68, value: "59", color: "black" },
    { id: 69, value: "47", color: "black" },
    { id: 70, value: "74", color: "black" },
  ],
  [
    { id: 71, value: "49", color: "black" },
    { id: 72, value: "39", color: "black" },
    { id: 73, value: "61", color: "black" },
    { id: 74, value: "53", color: "black" },
    { id: 75, value: "17", color: "black" },
    { id: 76, value: "91", color: "black" },
    { id: 77, value: "05", color: "black" },
  ],
  [
    { id: 78, value: "64", color: "black" },
    { id: 79, value: "96", color: "black" },
    { id: 80, value: "78", color: "black" },
    { id: 81, value: "96", color: "black" },
    { id: 82, value: "10", color: "black" },
    { id: 83, value: "81", color: "black" },
    { id: 84, value: "54", color: "black" },
  ],
  [
    { id: 85, value: "38", color: "black" },
    { id: 86, value: "49", color: "black" },
    { id: 87, value: "89", color: "black" },
    { id: 88, value: "80", color: "black" },
    { id: 89, value: "69", color: "black" },
    { id: 90, value: "31", color: "black" },
    { id: 91, value: "94", color: "black" },
  ],
  [
    { id: 92, value: "19", color: "black" },
    { id: 93, value: "45", color: "black" },
    { id: 94, value: "75", color: "black" },
    { id: 95, value: "36", color: "black" },
    { id: 96, value: "70", color: "black" },
    { id: 97, value: "01", color: "black" },
    { id: 98, value: "30", color: "black" },
  ],
  [
    { id: 99, value: "89", color: "black" },
    { id: 100, value: "54", color: "black" },
    { id: 101, value: "98", color: "black" },
    { id: 102, value: "20", color: "black" },
    { id: 103, value: "50", color: "black" },
    { id: 104, value: "76", color: "black" },
    { id: 105, value: "37", color: "black" },
  ],
  [
    { id: 106, value: "68", color: "black" },
    { id: 107, value: "14", color: "black" },
    { id: 108, value: "35", color: "black" },
    { id: 109, value: "26", color: "black" },
    { id: 110, value: "16", color: "black" },
    { id: 111, value: "07", color: "black" },
    { id: 112, value: "71", color: "black" },
  ],
  [
    { id: 113, value: "15", color: "black" },
    { id: 114, value: "16", color: "black" },
    { id: 115, value: "47", color: "black" },
    { id: 116, value: "67", color: "black" },
    { id: 117, value: "14", color: "black" },
    { id: 118, value: "30", color: "black" },
    { id: 119, value: "43", color: "black" },
  ],
  [
    { id: 120, value: "23", color: "black" },
    { id: 121, value: "03", color: "black" },
    { id: 122, value: "53", color: "black" },
    { id: 123, value: "36", color: "black" },
    { id: 124, value: "51", color: "black" },
    { id: 125, value: "74", color: "black" },
    { id: 126, value: "19", color: "black" },
  ],
  [
    { id: 127, value: "38", color: "black" },
    { id: 128, value: "23", color: "black" },
    { id: 129, value: "30", color: "black" },
    { id: 130, value: "12", color: "black" },
    { id: 131, value: "74", color: "black" },
    { id: 132, value: "01", color: "black" },
    { id: 133, value: "41", color: "black" },
  ],
  [
    { id: 134, value: "34", color: "black" },
    { id: 135, value: "30", color: "black" },
    { id: 136, value: "01", color: "black" },
    { id: 137, value: "15", color: "black" },
    { id: 138, value: "08", color: "black" },
    { id: 139, value: "78", color: "black" },
    { id: 140, value: "65", color: "black" },
  ],
  [
    { id: 141, value: "70", color: "black" },
    { id: 142, value: "93", color: "black" },
    { id: 143, value: "43", color: "black" },
    { id: 144, value: "21", color: "black" },
    { id: 145, value: "90", color: "black" },
    { id: 146, value: "66", color: "black" },
    { id: 147, value: "63", color: "black" },
  ],
  [
    { id: 148, value: "24", color: "black" },
    { id: 149, value: "68", color: "black" },
    { id: 150, value: "23", color: "black" },
    { id: 151, value: "58", color: "black" },
    { id: 152, value: "83", color: "black" },
    { id: 153, value: "59", color: "black" },
    { id: 154, value: "50", color: "black" },
  ],
  [
    { id: 155, value: "33", color: "black" },
    { id: 156, value: "58", color: "black" },
    { id: 157, value: "03", color: "black" },
    { id: 158, value: "46", color: "black" },
    { id: 159, value: "61", color: "black" },
    { id: 160, value: "72", color: "black" },
    { id: 161, value: "74", color: "black" },
  ],
  [
    { id: 162, value: "26", color: "black" },
    { id: 163, value: "92", color: "black" },
    { id: 164, value: "71", color: "black" },
    { id: 165, value: "93", color: "black" },
    { id: 166, value: "15", color: "black" },
    { id: 167, value: "03", color: "black" },
    { id: 168, value: "29", color: "black" },
  ],
  [
    { id: 169, value: "47", color: "black" },
    { id: 170, value: "58", color: "black" },
    { id: 171, value: "95", color: "black" },
    { id: 172, value: "88", color: "black" },
    { id: 173, value: "48", color: "black" },
    { id: 174, value: "78", color: "black" },
    { id: 175, value: "11", color: "black" },
  ],
  [
    { id: 176, value: "20", color: "black" },
    { id: 177, value: "53", color: "black" },
    { id: 178, value: "90", color: "black" },
    { id: 179, value: "26", color: "black" },
    { id: 180, value: "83", color: "black" },
    { id: 181, value: "87", color: "black" },
    { id: 182, value: "39", color: "black" },
  ],
  [
    { id: 183, value: "12", color: "black" },
    { id: 184, value: "54", color: "black" },
    { id: 185, value: "88", color: "black" },
    { id: 186, value: "18", color: "black" },
    { id: 187, value: "16", color: "black" },
    { id: 188, value: "43", color: "black" },
    { id: 189, value: "69", color: "black" },
  ],
  [
    { id: 190, value: "15", color: "black" },
    { id: 191, value: "68", color: "black" },
    { id: 192, value: "90", color: "black" },
    { id: 193, value: "62", color: "black" },
    { id: 194, value: "24", color: "black" },
    { id: 195, value: "29", color: "black" },
    { id: 196, value: "56", color: "black" },
  ],
  [
    { id: 197, value: "85", color: "black" },
    { id: 198, value: "44", color: "black" },
    { id: 199, value: "78", color: "black" },
    { id: 200, value: "47", color: "black" },
    { id: 201, value: "69", color: "black" },
    { id: 202, value: "27", color: "black" },
    { id: 203, value: "**", color: "black" },
  ],
];

// 2.30
export const Two = [
  [
    { id: 1, value: "49", color: "black" },
    { id: 2, value: "42", color: "black" },
    { id: 3, value: "09", color: "black" },
    { id: 4, value: "41", color: "black" },
    { id: 5, value: "80", color: "black" },
    { id: 6, value: "54", color: "black" },
    { id: 7, value: "56", color: "black" },
  ],
  [
    { id: 8, value: "96", color: "black" },
    { id: 9, value: "33", color: "black" },
    { id: 10, value: "22", color: "black" },
    { id: 11, value: "45", color: "black" },
    { id: 12, value: "19", color: "black" },
    { id: 13, value: "48", color: "black" },
    { id: 14, value: "89", color: "black" },
  ],
  [
    { id: 15, value: "54", color: "black" },
    { id: 16, value: "48", color: "black" },
    { id: 17, value: "21", color: "black" },
    { id: 18, value: "30", color: "black" },
    { id: 19, value: "38", color: "black" },
    { id: 20, value: "42", color: "black" },
    { id: 21, value: "12", color: "black" },
  ],
  [
    { id: 22, value: "76", color: "black" },
    { id: 23, value: "51", color: "black" },
    { id: 24, value: "69", color: "black" },
    { id: 25, value: "15", color: "black" },
    { id: 26, value: "19", color: "black" },
    { id: 27, value: "10", color: "black" },
    { id: 28, value: "82", color: "black" },
  ],
  [
    { id: 29, value: "07", color: "black" },
    { id: 30, value: "65", color: "black" },
    { id: 31, value: "08", color: "black" },
    { id: 32, value: "39", color: "black" },
    { id: 33, value: "60", color: "black" },
    { id: 34, value: "85", color: "black" },
    { id: 35, value: "11", color: "black" },
  ],
  [
    { id: 36, value: "19", color: "black" },
    { id: 37, value: "28", color: "black" },
    { id: 38, value: "75", color: "black" },
    { id: 39, value: "52", color: "black" },
    { id: 40, value: "55", color: "black" },
    { id: 41, value: "98", color: "black" },
    { id: 42, value: "38", color: "black" },
  ],
  [
    { id: 43, value: "86", color: "black" },
    { id: 44, value: "62", color: "black" },
    { id: 45, value: "51", color: "black" },
    { id: 46, value: "21", color: "black" },
    { id: 47, value: "99", color: "black" },
    { id: 48, value: "24", color: "black" },
    { id: 49, value: "62", color: "black" },
  ],
  [
    { id: 50, value: "91", color: "black" },
    { id: 51, value: "36", color: "black" },
    { id: 52, value: "35", color: "black" },
    { id: 53, value: "54", color: "black" },
    { id: 54, value: "30", color: "black" },
    { id: 55, value: "30", color: "black" },
    { id: 56, value: "62", color: "black" },
  ],
  [
    { id: 57, value: "98", color: "black" },
    { id: 58, value: "22", color: "black" },
    { id: 59, value: "88", color: "black" },
    { id: 60, value: "60", color: "black" },
    { id: 61, value: "63", color: "black" },
    { id: 62, value: "77", color: "black" },
    { id: 63, value: "01", color: "black" },
  ],
  [
    { id: 64, value: "48", color: "black" },
    { id: 65, value: "61", color: "black" },
    { id: 66, value: "51", color: "black" },
    { id: 67, value: "89", color: "black" },
    { id: 68, value: "51", color: "black" },
    { id: 69, value: "62", color: "black" },
    { id: 70, value: "50", color: "black" },
  ],
  [
    { id: 71, value: "83", color: "black" },
    { id: 72, value: "78", color: "black" },
    { id: 73, value: "50", color: "black" },
    { id: 74, value: "27", color: "black" },
    { id: 75, value: "39", color: "black" },
    { id: 76, value: "36", color: "black" },
    { id: 77, value: "13", color: "black" },
  ],
  [
    { id: 78, value: "82", color: "black" },
    { id: 79, value: "74", color: "black" },
    { id: 80, value: "68", color: "black" },
    { id: 81, value: "24", color: "black" },
    { id: 82, value: "03", color: "black" },
    { id: 83, value: "61", color: "black" },
    { id: 84, value: "56", color: "black" },
  ],
  [
    { id: 85, value: "49", color: "black" },
    { id: 86, value: "97", color: "black" },
    { id: 87, value: "58", color: "black" },
    { id: 88, value: "45", color: "black" },
    { id: 89, value: "99", color: "black" },
    { id: 90, value: "94", color: "black" },
    { id: 91, value: "29", color: "black" },
  ],
  [
    { id: 92, value: "14", color: "black" },
    { id: 93, value: "96", color: "black" },
    { id: 94, value: "14", color: "black" },
    { id: 95, value: "48", color: "black" },
    { id: 96, value: "95", color: "black" },
    { id: 97, value: "13", color: "black" },
    { id: 98, value: "24", color: "black" },
  ],
  [
    { id: 99, value: "13", color: "black" },
    { id: 100, value: "61", color: "black" },
    { id: 101, value: "93", color: "black" },
    { id: 102, value: "34", color: "black" },
    { id: 103, value: "40", color: "black" },
    { id: 104, value: "51", color: "black" },
    { id: 105, value: "61", color: "black" },
  ],
  [
    { id: 106, value: "96", color: "black" },
    { id: 107, value: "02", color: "black" },
    { id: 108, value: "41", color: "black" },
    { id: 109, value: "11", color: "black" },
    { id: 110, value: "97", color: "black" },
    { id: 111, value: "15", color: "black" },
    { id: 112, value: "33", color: "black" },
  ],
  [
    { id: 113, value: "56", color: "black" },
    { id: 114, value: "20", color: "black" },
    { id: 115, value: "37", color: "black" },
    { id: 116, value: "59", color: "black" },
    { id: 117, value: "69", color: "black" },
    { id: 118, value: "69", color: "black" },
    { id: 119, value: "78", color: "black" },
  ],
  [
    { id: 120, value: "80", color: "black" },
    { id: 121, value: "50", color: "black" },
    { id: 122, value: "28", color: "black" },
    { id: 123, value: "29", color: "black" },
    { id: 124, value: "91", color: "black" },
    { id: 125, value: "07", color: "black" },
    { id: 126, value: "59", color: "black" },
  ],
  [
    { id: 127, value: "39", color: "black" },
    { id: 128, value: "92", color: "black" },
    { id: 129, value: "52", color: "black" },
    { id: 130, value: "94", color: "black" },
    { id: 131, value: "05", color: "black" },
    { id: 132, value: "07", color: "black" },
    { id: 133, value: "54", color: "black" },
  ],
  [
    { id: 134, value: "74", color: "black" },
    { id: 135, value: "04", color: "black" },
    { id: 136, value: "43", color: "black" },
    { id: 137, value: "79", color: "black" },
    { id: 138, value: "82", color: "black" },
    { id: 139, value: "09", color: "black" },
    { id: 140, value: "21", color: "black" },
  ],
  [
    { id: 141, value: "51", color: "black" },
    { id: 142, value: "32", color: "black" },
    { id: 143, value: "76", color: "black" },
    { id: 144, value: "28", color: "black" },
    { id: 145, value: "50", color: "black" },
    { id: 146, value: "55", color: "black" },
    { id: 147, value: "11", color: "black" },
  ],
  [
    { id: 148, value: "59", color: "black" },
    { id: 149, value: "28", color: "black" },
    { id: 150, value: "76", color: "black" },
    { id: 151, value: "61", color: "black" },
    { id: 152, value: "72", color: "black" },
    { id: 153, value: "91", color: "black" },
    { id: 154, value: "61", color: "black" },
  ],
  [
    { id: 155, value: "76", color: "black" },
    { id: 156, value: "63", color: "black" },
    { id: 157, value: "88", color: "black" },
    { id: 158, value: "41", color: "black" },
    { id: 159, value: "83", color: "black" },
    { id: 160, value: "02", color: "black" },
    { id: 161, value: "29", color: "black" },
  ],
  [
    { id: 162, value: "78", color: "black" },
    { id: 163, value: "60", color: "black" },
    { id: 164, value: "21", color: "black" },
    { id: 165, value: "26", color: "black" },
    { id: 166, value: "20", color: "black" },
    { id: 167, value: "49", color: "black" },
    { id: 168, value: "89", color: "black" },
  ],
  [
    { id: 169, value: "38", color: "black" },
    { id: 170, value: "50", color: "black" },
    { id: 171, value: "62", color: "black" },
    { id: 172, value: "95", color: "black" },
    { id: 173, value: "73", color: "black" },
    { id: 174, value: "47", color: "black" },
    { id: 175, value: "33", color: "black" },
  ],
  [
    { id: 176, value: "30", color: "black" },
    { id: 177, value: "85", color: "black" },
    { id: 178, value: "78", color: "black" },
    { id: 179, value: "66", color: "black" },
    { id: 180, value: "72", color: "black" },
    { id: 181, value: "93", color: "black" },
    { id: 182, value: "89", color: "black" },
  ],
  [
    { id: 183, value: "15", color: "black" },
    { id: 184, value: "32", color: "black" },
    { id: 185, value: "19", color: "black" },
    { id: 186, value: "53", color: "black" },
    { id: 187, value: "25", color: "black" },
    { id: 188, value: "21", color: "black" },
    { id: 189, value: "46", color: "black" },
  ],
  [
    { id: 190, value: "55", color: "black" },
    { id: 191, value: "34", color: "black" },
    { id: 192, value: "65", color: "black" },
    { id: 193, value: "31", color: "black" },
    { id: 194, value: "48", color: "black" },
    { id: 195, value: "48", color: "black" },
    { id: 196, value: "29", color: "black" },
  ],
  [
    { id: 197, value: "63", color: "black" },
    { id: 198, value: "26", color: "black" },
    { id: 199, value: "27", color: "black" },
    { id: 200, value: "25", color: "black" },
    { id: 201, value: "94", color: "black" },
    { id: 202, value: "64", color: "black" },
    { id: 203, value: "**", color: "black" },
  ],
];

// 3.30

export const Three = [
  [
    { id: 1, value: "11", color: "black" },
    { id: 2, value: "20", color: "black" },
    { id: 3, value: "77", color: "black" },
    { id: 4, value: "39", color: "black" },
    { id: 5, value: "20", color: "black" },
    { id: 6, value: "68", color: "black" },
    { id: 7, value: "25", color: "black" },
  ],
  [
    { id: 8, value: "83", color: "black" },
    { id: 9, value: "34", color: "black" },
    { id: 10, value: "79", color: "black" },
    { id: 11, value: "03", color: "black" },
    { id: 12, value: "54", color: "black" },
    { id: 13, value: "53", color: "black" },
    { id: 14, value: "20", color: "black" },
  ],
  [
    { id: 15, value: "20", color: "black" },
    { id: 16, value: "49", color: "black" },
    { id: 17, value: "17", color: "black" },
    { id: 18, value: "81", color: "black" },
    { id: 19, value: "52", color: "black" },
    { id: 20, value: "12", color: "black" },
    { id: 21, value: "99", color: "black" },
  ],
  [
    { id: 22, value: "48", color: "black" },
    { id: 23, value: "32", color: "black" },
    { id: 24, value: "28", color: "black" },
    { id: 25, value: "38", color: "black" },
    { id: 26, value: "79", color: "black" },
    { id: 27, value: "99", color: "black" },
    { id: 28, value: "89", color: "black" },
  ],
  [
    { id: 29, value: "62", color: "black" },
    { id: 30, value: "92", color: "black" },
    { id: 31, value: "30", color: "black" },
    { id: 32, value: "55", color: "black" },
    { id: 33, value: "79", color: "black" },
    { id: 34, value: "58", color: "black" },
    { id: 35, value: "92", color: "black" },
  ],
  [
    { id: 36, value: "37", color: "black" },
    { id: 37, value: "91", color: "black" },
    { id: 38, value: "13", color: "black" },
    { id: 39, value: "98", color: "black" },
    { id: 40, value: "04", color: "black" },
    { id: 41, value: "06", color: "black" },
    { id: 42, value: "52", color: "black" },
  ],
  [
    { id: 43, value: "74", color: "black" },
    { id: 44, value: "50", color: "black" },
    { id: 45, value: "28", color: "black" },
    { id: 46, value: "11", color: "black" },
    { id: 47, value: "30", color: "black" },
    { id: 48, value: "35", color: "black" },
    { id: 49, value: "**", color: "black" },
  ],
  [
    { id: 50, value: "66", color: "black" },
    { id: 51, value: "08", color: "black" },
    { id: 52, value: "55", color: "black" },
    { id: 53, value: "95", color: "black" },
    { id: 54, value: "79", color: "black" },
    { id: 55, value: "50", color: "black" },
    { id: 56, value: "99", color: "black" },
  ],
  [
    { id: 57, value: "69", color: "black" },
    { id: 58, value: "99", color: "black" },
    { id: 59, value: "02", color: "black" },
    { id: 60, value: "91", color: "black" },
    { id: 61, value: "91", color: "black" },
    { id: 62, value: "22", color: "black" },
    { id: 63, value: "48", color: "black" },
  ],
  [
    { id: 64, value: "61", color: "black" },
    { id: 65, value: "19", color: "black" },
    { id: 66, value: "57", color: "black" },
    { id: 67, value: "01", color: "black" },
    { id: 68, value: "73", color: "black" },
    { id: 69, value: "81", color: "black" },
    { id: 70, value: "65", color: "black" },
  ],
  [
    { id: 71, value: "98", color: "black" },
    { id: 72, value: "99", color: "black" },
    { id: 73, value: "54", color: "black" },
    { id: 74, value: "38", color: "black" },
    { id: 75, value: "62", color: "black" },
    { id: 76, value: "42", color: "black" },
    { id: 77, value: "79", color: "black" },
  ],
  [
    { id: 78, value: "35", color: "black" },
    { id: 79, value: "28", color: "black" },
    { id: 80, value: "45", color: "black" },
    { id: 81, value: "42", color: "black" },
    { id: 82, value: "64", color: "black" },
    { id: 83, value: "53", color: "black" },
    { id: 84, value: "94", color: "black" },
  ],
  [
    { id: 85, value: "40", color: "black" },
    { id: 86, value: "22", color: "black" },
    { id: 87, value: "70", color: "black" },
    { id: 88, value: "27", color: "black" },
    { id: 89, value: "50", color: "black" },
    { id: 90, value: "84", color: "black" },
    { id: 91, value: "19", color: "black" },
  ],
  [
    { id: 92, value: "08", color: "black" },
    { id: 93, value: "18", color: "black" },
    { id: 94, value: "69", color: "black" },
    { id: 95, value: "62", color: "black" },
    { id: 96, value: "89", color: "black" },
    { id: 97, value: "47", color: "black" },
    { id: 98, value: "69", color: "black" },
  ],
  [
    { id: 99, value: "96", color: "black" },
    { id: 100, value: "57", color: "black" },
    { id: 101, value: "49", color: "black" },
    { id: 102, value: "83", color: "black" },
    { id: 103, value: "48", color: "black" },
    { id: 104, value: "47", color: "black" },
    { id: 105, value: "08", color: "black" },
  ],
  [
    { id: 106, value: "82", color: "black" },
    { id: 107, value: "78", color: "black" },
    { id: 108, value: "09", color: "black" },
    { id: 109, value: "42", color: "black" },
    { id: 110, value: "01", color: "black" },
    { id: 111, value: "94", color: "black" },
    { id: 112, value: "36", color: "black" },
  ],
  [
    { id: 113, value: "08", color: "black" },
    { id: 114, value: "81", color: "black" },
    { id: 115, value: "36", color: "black" },
    { id: 116, value: "04", color: "black" },
    { id: 117, value: "70", color: "black" },
    { id: 118, value: "57", color: "black" },
    { id: 119, value: "73", color: "black" },
  ],
  [
    { id: 120, value: "34", color: "black" },
    { id: 121, value: "42", color: "black" },
    { id: 122, value: "37", color: "black" },
    { id: 123, value: "62", color: "black" },
    { id: 124, value: "24", color: "black" },
    { id: 125, value: "75", color: "black" },
    { id: 126, value: "22", color: "black" },
  ],
  [
    { id: 127, value: "11", color: "black" },
    { id: 128, value: "99", color: "black" },
    { id: 129, value: "76", color: "black" },
    { id: 130, value: "64", color: "black" },
    { id: 131, value: "27", color: "black" },
    { id: 132, value: "25", color: "black" },
    { id: 133, value: "65", color: "black" },
  ],
  [
    { id: 134, value: "04", color: "black" },
    { id: 135, value: "65", color: "black" },
    { id: 136, value: "76", color: "black" },
    { id: 137, value: "23", color: "black" },
    { id: 138, value: "58", color: "black" },
    { id: 139, value: "39", color: "black" },
    { id: 140, value: "69", color: "black" },
  ],
  [
    { id: 141, value: "36", color: "black" },
    { id: 142, value: "24", color: "black" },
    { id: 143, value: "01", color: "black" },
    { id: 144, value: "84", color: "black" },
    { id: 145, value: "06", color: "black" },
    { id: 146, value: "52", color: "black" },
    { id: 147, value: "57", color: "black" },
  ],
  [
    { id: 148, value: "26", color: "black" },
    { id: 149, value: "40", color: "black" },
    { id: 150, value: "79", color: "black" },
    { id: 151, value: "94", color: "black" },
    { id: 152, value: "38", color: "black" },
    { id: 153, value: "67", color: "black" },
    { id: 154, value: "13", color: "black" },
  ],
  [
    { id: 155, value: "21", color: "black" },
    { id: 156, value: "39", color: "black" },
    { id: 157, value: "42", color: "black" },
    { id: 158, value: "27", color: "black" },
    { id: 159, value: "57", color: "black" },
    { id: 160, value: "37", color: "black" },
    { id: 161, value: "32", color: "black" },
  ],
  [
    { id: 162, value: "87", color: "black" },
    { id: 163, value: "61", color: "black" },
    { id: 164, value: "30", color: "black" },
    { id: 165, value: "64", color: "black" },
    { id: 166, value: "91", color: "black" },
    { id: 167, value: "28", color: "black" },
    { id: 168, value: "57", color: "black" },
  ],
  [
    { id: 169, value: "46", color: "black" },
    { id: 170, value: "75", color: "black" },
    { id: 171, value: "83", color: "black" },
    { id: 172, value: "14", color: "black" },
    { id: 173, value: "32", color: "black" },
    { id: 174, value: "44", color: "black" },
    { id: 175, value: "44", color: "black" },
  ],
  [
    { id: 176, value: "50", color: "black" },
    { id: 177, value: "16", color: "black" },
    { id: 178, value: "53", color: "black" },
    { id: 179, value: "19", color: "black" },
    { id: 180, value: "37", color: "black" },
    { id: 181, value: "79", color: "black" },
    { id: 182, value: "91", color: "black" },
  ],
  [
    { id: 183, value: "19", color: "black" },
    { id: 184, value: "12", color: "black" },
    { id: 185, value: "42", color: "black" },
    { id: 186, value: "32", color: "black" },
    { id: 187, value: "43", color: "black" },
    { id: 188, value: "16", color: "black" },
    { id: 189, value: "79", color: "black" },
  ],
  [
    { id: 190, value: "79", color: "black" },
    { id: 191, value: "53", color: "black" },
    { id: 192, value: "38", color: "black" },
    { id: 193, value: "17", color: "black" },
    { id: 194, value: "92", color: "black" },
    { id: 195, value: "96", color: "black" },
    { id: 196, value: "42", color: "black" },
  ],
  [
    { id: 197, value: "99", color: "black" },
    { id: 198, value: "14", color: "black" },
    { id: 199, value: "37", color: "black" },
    { id: 200, value: "54", color: "black" },
    { id: 201, value: "82", color: "black" },
    { id: 202, value: "56", color: "black" },
    { id: 203, value: "**", color: "black" },
  ],
];

// 4.30
export const Four = [
  [
    { id: 1, value: "45", color: "black" },
    { id: 2, value: "78", color: "black" },
    { id: 3, value: "51", color: "black" },
    { id: 4, value: "55", color: "black" },
    { id: 5, value: "33", color: "black" },
    { id: 6, value: "16", color: "black" },
    { id: 7, value: "63", color: "black" },
  ],
  [
    { id: 8, value: "12", color: "black" },
    { id: 9, value: "28", color: "black" },
    { id: 10, value: "44", color: "black" },
    { id: 11, value: "27", color: "black" },
    { id: 12, value: "15", color: "black" },
    { id: 13, value: "98", color: "black" },
    { id: 14, value: "30", color: "black" },
  ],
  [
    { id: 15, value: "46", color: "black" },
    { id: 16, value: "63", color: "black" },
    { id: 17, value: "37", color: "black" },
    { id: 18, value: "03", color: "black" },
    { id: 19, value: "76", color: "black" },
    { id: 20, value: "91", color: "black" },
    { id: 21, value: "37", color: "black" },
  ],
  [
    { id: 22, value: "10", color: "black" },
    { id: 23, value: "49", color: "black" },
    { id: 24, value: "12", color: "black" },
    { id: 25, value: "27", color: "black" },
    { id: 26, value: "30", color: "black" },
    { id: 27, value: "32", color: "black" },
    { id: 28, value: "03", color: "black" },
  ],
  [
    { id: 29, value: "94", color: "black" },
    { id: 30, value: "54", color: "black" },
    { id: 31, value: "41", color: "black" },
    { id: 32, value: "05", color: "black" },
    { id: 33, value: "10", color: "black" },
    { id: 34, value: "08", color: "black" },
    { id: 35, value: "48", color: "black" },
  ],
  [
    { id: 36, value: "49", color: "black" },
    { id: 37, value: "19", color: "black" },
    { id: 38, value: "40", color: "black" },
    { id: 39, value: "86", color: "black" },
    { id: 40, value: "24", color: "black" },
    { id: 41, value: "22", color: "black" },
    { id: 42, value: "96", color: "black" },
  ],
  [
    { id: 43, value: "59", color: "black" },
    { id: 44, value: "32", color: "black" },
    { id: 45, value: "94", color: "black" },
    { id: 46, value: "92", color: "black" },
    { id: 47, value: "07", color: "black" },
    { id: 48, value: "01", color: "black" },
    { id: 49, value: "71", color: "black" },
  ],
  [
    { id: 50, value: "02", color: "black" },
    { id: 51, value: "76", color: "black" },
    { id: 52, value: "83", color: "black" },
    { id: 53, value: "92", color: "black" },
    { id: 54, value: "01", color: "black" },
    { id: 55, value: "87", color: "black" },
    { id: 56, value: "21", color: "black" },
  ],
  [
    { id: 57, value: "02", color: "black" },
    { id: 58, value: "54", color: "black" },
    { id: 59, value: "08", color: "black" },
    { id: 60, value: "40", color: "black" },
    { id: 61, value: "12", color: "black" },
    { id: 62, value: "80", color: "black" },
    { id: 63, value: "06", color: "black" },
  ],
  [
    { id: 64, value: "02", color: "black" },
    { id: 65, value: "21", color: "black" },
    { id: 66, value: "33", color: "black" },
    { id: 67, value: "59", color: "black" },
    { id: 68, value: "01", color: "black" },
    { id: 69, value: "50", color: "black" },
    { id: 70, value: "99", color: "black" },
  ],
  [
    { id: 71, value: "18", color: "black" },
    { id: 72, value: "80", color: "black" },
    { id: 73, value: "40", color: "black" },
    { id: 74, value: "35", color: "black" },
    { id: 75, value: "51", color: "black" },
    { id: 76, value: "28", color: "black" },
    { id: 77, value: "18", color: "black" },
  ],
  [
    { id: 78, value: "47", color: "black" },
    { id: 79, value: "52", color: "black" },
    { id: 80, value: "70", color: "black" },
    { id: 81, value: "91", color: "black" },
    { id: 82, value: "15", color: "black" },
    { id: 83, value: "72", color: "black" },
    { id: 84, value: "36", color: "black" },
  ],
  [
    { id: 85, value: "53", color: "black" },
    { id: 86, value: "73", color: "black" },
    { id: 87, value: "03", color: "black" },
    { id: 88, value: "90", color: "black" },
    { id: 89, value: "14", color: "black" },
    { id: 90, value: "22", color: "black" },
    { id: 91, value: "80", color: "black" },
  ],
  [
    { id: 92, value: "24", color: "black" },
    { id: 93, value: "35", color: "black" },
    { id: 94, value: "18", color: "black" },
    { id: 95, value: "67", color: "black" },
    { id: 96, value: "97", color: "black" },
    { id: 97, value: "81", color: "black" },
    { id: 98, value: "63", color: "black" },
  ],
  [
    { id: 99, value: "99", color: "black" },
    { id: 100, value: "48", color: "black" },
    { id: 101, value: "28", color: "black" },
    { id: 102, value: "98", color: "black" },
    { id: 103, value: "83", color: "black" },
    { id: 104, value: "67", color: "black" },
    { id: 105, value: "03", color: "black" },
  ],
  [
    { id: 106, value: "57", color: "black" },
    { id: 107, value: "41", color: "black" },
    { id: 108, value: "53", color: "black" },
    { id: 109, value: "33", color: "black" },
    { id: 110, value: "24", color: "black" },
    { id: 111, value: "91", color: "black" },
    { id: 112, value: "14", color: "black" },
  ],
  [
    { id: 113, value: "12", color: "black" },
    { id: 114, value: "44", color: "black" },
    { id: 115, value: "02", color: "black" },
    { id: 116, value: "52", color: "black" },
    { id: 117, value: "57", color: "black" },
    { id: 118, value: "06", color: "black" },
    { id: 119, value: "62", color: "black" },
  ],
  [
    { id: 120, value: "72", color: "black" },
    { id: 121, value: "94", color: "black" },
    { id: 122, value: "08", color: "black" },
    { id: 123, value: "21", color: "black" },
    { id: 124, value: "77", color: "black" },
    { id: 125, value: "21", color: "black" },
    { id: 126, value: "94", color: "black" },
  ],
  [
    { id: 127, value: "01", color: "black" },
    { id: 128, value: "11", color: "black" },
    { id: 129, value: "19", color: "black" },
    { id: 130, value: "75", color: "black" },
    { id: 131, value: "16", color: "black" },
    { id: 132, value: "11", color: "black" },
    { id: 133, value: "97", color: "black" },
  ],
  [
    { id: 134, value: "90", color: "black" },
    { id: 135, value: "16", color: "black" },
    { id: 136, value: "30", color: "black" },
    { id: 137, value: "85", color: "black" },
    { id: 138, value: "90", color: "black" },
    { id: 139, value: "31", color: "black" },
    { id: 140, value: "10", color: "black" },
  ],
  [
    { id: 141, value: "99", color: "black" },
    { id: 142, value: "29", color: "black" },
    { id: 143, value: "98", color: "black" },
    { id: 144, value: "59", color: "black" },
    { id: 145, value: "98", color: "black" },
    { id: 146, value: "18", color: "black" },
    { id: 147, value: "60", color: "black" },
  ],
  [
    { id: 148, value: "75", color: "black" },
    { id: 149, value: "01", color: "black" },
    { id: 150, value: "41", color: "black" },
    { id: 151, value: "15", color: "black" },
    { id: 152, value: "24", color: "black" },
    { id: 153, value: "06", color: "black" },
    { id: 154, value: "20", color: "black" },
  ],
  [
    { id: 155, value: "11", color: "black" },
    { id: 156, value: "66", color: "black" },
    { id: 157, value: "60", color: "black" },
    { id: 158, value: "66", color: "black" },
    { id: 159, value: "55", color: "black" },
    { id: 160, value: "22", color: "black" },
    { id: 161, value: "43", color: "black" },
  ],
  [
    { id: 162, value: "93", color: "black" },
    { id: 163, value: "75", color: "black" },
    { id: 164, value: "83", color: "black" },
    { id: 165, value: "48", color: "black" },
    { id: 166, value: "10", color: "black" },
    { id: 167, value: "97", color: "black" },
    { id: 168, value: "69", color: "black" },
  ],
  [
    { id: 169, value: "32", color: "black" },
    { id: 170, value: "88", color: "black" },
    { id: 171, value: "96", color: "black" },
    { id: 172, value: "16", color: "black" },
    { id: 173, value: "56", color: "black" },
    { id: 174, value: "80", color: "black" },
    { id: 175, value: "55", color: "black" },
  ],
  [
    { id: 176, value: "88", color: "black" },
    { id: 177, value: "63", color: "black" },
    { id: 178, value: "36", color: "black" },
    { id: 179, value: "27", color: "black" },
    { id: 180, value: "48", color: "black" },
    { id: 181, value: "18", color: "black" },
    { id: 182, value: "79", color: "black" },
  ],
  [
    { id: 183, value: "25", color: "black" },
    { id: 184, value: "30", color: "black" },
    { id: 185, value: "27", color: "black" },
    { id: 186, value: "33", color: "black" },
    { id: 187, value: "33", color: "black" },
    { id: 188, value: "67", color: "black" },
    { id: 189, value: "11", color: "black" },
  ],
  [
    { id: 190, value: "59", color: "black" },
    { id: 191, value: "87", color: "black" },
    { id: 192, value: "46", color: "black" },
    { id: 193, value: "59", color: "black" },
    { id: 194, value: "56", color: "black" },
    { id: 195, value: "71", color: "black" },
    { id: 196, value: "84", color: "black" },
  ],
  [
    { id: 197, value: "55", color: "black" },
    { id: 198, value: "88", color: "black" },
    { id: 199, value: "66", color: "black" },
    { id: 200, value: "73", color: "black" },
    { id: 201, value: "34", color: "black" },
    { id: 202, value: "**", color: "black" },
    { id: 203, value: "**", color: "black" },
  ],
];

// 5.30
export const Five = [
  [
    { id: 1, value: "90", color: "black" },
    { id: 2, value: "84", color: "black" },
    { id: 3, value: "33", color: "black" },
    { id: 4, value: "33", color: "black" },
    { id: 5, value: "25", color: "black" },
    { id: 6, value: "25", color: "black" },
    { id: 7, value: "08", color: "black" },
  ],
  [
    { id: 8, value: "79", color: "black" },
    { id: 9, value: "96", color: "black" },
    { id: 10, value: "78", color: "black" },
    { id: 11, value: "74", color: "black" },
    { id: 12, value: "58", color: "black" },
    { id: 13, value: "55", color: "black" },
    { id: 14, value: "14", color: "black" },
  ],
  [
    { id: 15, value: "34", color: "black" },
    { id: 16, value: "09", color: "black" },
    { id: 17, value: "81", color: "black" },
    { id: 18, value: "91", color: "black" },
    { id: 19, value: "01", color: "black" },
    { id: 20, value: "60", color: "black" },
    { id: 21, value: "47", color: "black" },
  ],
  [
    { id: 22, value: "12", color: "black" },
    { id: 23, value: "03", color: "black" },
    { id: 24, value: "99", color: "black" },
    { id: 25, value: "55", color: "black" },
    { id: 26, value: "64", color: "black" },
    { id: 27, value: "39", color: "black" },
    { id: 28, value: "31", color: "black" },
  ],
  [
    { id: 29, value: "34", color: "black" },
    { id: 30, value: "89", color: "black" },
    { id: 31, value: "94", color: "black" },
    { id: 32, value: "79", color: "black" },
    { id: 33, value: "14", color: "black" },
    { id: 34, value: "30", color: "black" },
    { id: 35, value: "67", color: "black" },
  ],
  [
    { id: 36, value: "69", color: "black" },
    { id: 37, value: "38", color: "black" },
    { id: 38, value: "62", color: "black" },
    { id: 39, value: "60", color: "black" },
    { id: 40, value: "83", color: "black" },
    { id: 41, value: "51", color: "black" },
    { id: 42, value: "81", color: "black" },
  ],
  [
    { id: 43, value: "21", color: "black" },
    { id: 44, value: "87", color: "black" },
    { id: 45, value: "83", color: "black" },
    { id: 46, value: "06", color: "black" },
    { id: 47, value: "52", color: "black" },
    { id: 48, value: "33", color: "black" },
    { id: 49, value: "26", color: "black" },
  ],
  [
    { id: 50, value: "55", color: "black" },
    { id: 51, value: "55", color: "black" },
    { id: 52, value: "30", color: "black" },
    { id: 53, value: "62", color: "black" },
    { id: 54, value: "65", color: "black" },
    { id: 55, value: "65", color: "black" },
    { id: 56, value: "27", color: "black" },
  ],
  [
    { id: 57, value: "98", color: "black" },
    { id: 58, value: "45", color: "black" },
    { id: 59, value: "47", color: "black" },
    { id: 60, value: "96", color: "black" },
    { id: 61, value: "88", color: "black" },
    { id: 62, value: "92", color: "black" },
    { id: 63, value: "27", color: "black" },
  ],
  [
    { id: 64, value: "71", color: "black" },
    { id: 65, value: "11", color: "black" },
    { id: 66, value: "90", color: "black" },
    { id: 67, value: "25", color: "black" },
    { id: 68, value: "36", color: "black" },
    { id: 69, value: "11", color: "black" },
    { id: 70, value: "47", color: "black" },
  ],
  [
    { id: 71, value: "73", color: "black" },
    { id: 72, value: "11", color: "black" },
    { id: 73, value: "85", color: "black" },
    { id: 74, value: "44", color: "black" },
    { id: 75, value: "99", color: "black" },
    { id: 76, value: "73", color: "black" },
    { id: 77, value: "23", color: "black" },
  ],
  [
    { id: 78, value: "70", color: "black" },
    { id: 79, value: "58", color: "black" },
    { id: 80, value: "82", color: "black" },
    { id: 81, value: "07", color: "black" },
    { id: 82, value: "53", color: "black" },
    { id: 83, value: "99", color: "black" },
    { id: 84, value: "59", color: "black" },
  ],
  [
    { id: 85, value: "51", color: "black" },
    { id: 86, value: "18", color: "black" },
    { id: 87, value: "17", color: "black" },
    { id: 88, value: "64", color: "black" },
    { id: 89, value: "14", color: "black" },
    { id: 90, value: "36", color: "black" },
    { id: 91, value: "44", color: "black" },
  ],
  [
    { id: 92, value: "97", color: "black" },
    { id: 93, value: "87", color: "black" },
    { id: 94, value: "22", color: "black" },
    { id: 95, value: "54", color: "black" },
    { id: 96, value: "98", color: "black" },
    { id: 97, value: "17", color: "black" },
    { id: 98, value: "09", color: "black" },
  ],
  [
    { id: 99, value: "92", color: "black" },
    { id: 100, value: "78", color: "black" },
    { id: 101, value: "61", color: "black" },
    { id: 102, value: "39", color: "black" },
    { id: 103, value: "05", color: "black" },
    { id: 104, value: "80", color: "black" },
    { id: 105, value: "32", color: "black" },
  ],
  [
    { id: 106, value: "79", color: "black" },
    { id: 107, value: "37", color: "black" },
    { id: 108, value: "86", color: "black" },
    { id: 109, value: "52", color: "black" },
    { id: 110, value: "62", color: "black" },
    { id: 111, value: "79", color: "black" },
    { id: 112, value: "60", color: "black" },
  ],
  [
    { id: 113, value: "94", color: "black" },
    { id: 114, value: "10", color: "black" },
    { id: 115, value: "26", color: "black" },
    { id: 116, value: "68", color: "black" },
    { id: 117, value: "13", color: "black" },
    { id: 118, value: "46", color: "black" },
    { id: 119, value: "41", color: "black" },
  ],
  [
    { id: 120, value: "88", color: "black" },
    { id: 121, value: "85", color: "black" },
    { id: 122, value: "10", color: "black" },
    { id: 123, value: "42", color: "black" },
    { id: 124, value: "64", color: "black" },
    { id: 125, value: "02", color: "black" },
    { id: 126, value: "26", color: "black" },
  ],
  [
    { id: 127, value: "90", color: "black" },
    { id: 128, value: "18", color: "black" },
    { id: 129, value: "87", color: "black" },
    { id: 130, value: "97", color: "black" },
    { id: 131, value: "46", color: "black" },
    { id: 132, value: "23", color: "black" },
    { id: 133, value: "20", color: "black" },
  ],
  [
    { id: 134, value: "66", color: "black" },
    { id: 135, value: "81", color: "black" },
    { id: 136, value: "13", color: "black" },
    { id: 137, value: "53", color: "black" },
    { id: 138, value: "48", color: "black" },
    { id: 139, value: "96", color: "black" },
    { id: 140, value: "08", color: "black" },
  ],
  [
    { id: 141, value: "68", color: "black" },
    { id: 142, value: "14", color: "black" },
    { id: 143, value: "21", color: "black" },
    { id: 144, value: "15", color: "black" },
    { id: 145, value: "24", color: "black" },
    { id: 146, value: "13", color: "black" },
    { id: 147, value: "87", color: "black" },
  ],
  [
    { id: 148, value: "76", color: "black" },
    { id: 149, value: "51", color: "black" },
    { id: 150, value: "92", color: "black" },
    { id: 151, value: "81", color: "black" },
    { id: 152, value: "84", color: "black" },
    { id: 153, value: "28", color: "black" },
    { id: 154, value: "28", color: "black" },
  ],
  [
    { id: 155, value: "91", color: "black" },
    { id: 156, value: "03", color: "black" },
    { id: 157, value: "14", color: "black" },
    { id: 158, value: "86", color: "black" },
    { id: 159, value: "49", color: "black" },
    { id: 160, value: "99", color: "black" },
    { id: 161, value: "70", color: "black" },
  ],
  [
    { id: 162, value: "14", color: "black" },
    { id: 163, value: "86", color: "black" },
    { id: 164, value: "78", color: "black" },
    { id: 165, value: "30", color: "black" },
    { id: 166, value: "09", color: "black" },
    { id: 167, value: "45", color: "black" },
    { id: 168, value: "10", color: "black" },
  ],
  [
    { id: 169, value: "95", color: "black" },
    { id: 170, value: "56", color: "black" },
    { id: 171, value: "78", color: "black" },
    { id: 172, value: "58", color: "black" },
    { id: 173, value: "65", color: "black" },
    { id: 174, value: "94", color: "black" },
    { id: 175, value: "96", color: "black" },
  ],
  [
    { id: 176, value: "80", color: "black" },
    { id: 177, value: "27", color: "black" },
    { id: 178, value: "28", color: "black" },
    { id: 179, value: "99", color: "black" },
    { id: 180, value: "36", color: "black" },
    { id: 181, value: "27", color: "black" },
    { id: 182, value: "51", color: "black" },
  ],
  [
    { id: 183, value: "77", color: "black" },
    { id: 184, value: "21", color: "black" },
    { id: 185, value: "32", color: "black" },
    { id: 186, value: "48", color: "black" },
    { id: 187, value: "88", color: "black" },
    { id: 188, value: "58", color: "black" },
    { id: 189, value: "38", color: "black" },
  ],
  [
    { id: 190, value: "67", color: "black" },
    { id: 191, value: "91", color: "black" },
    { id: 192, value: "14", color: "black" },
    { id: 193, value: "83", color: "black" },
    { id: 194, value: "15", color: "black" },
    { id: 195, value: "77", color: "black" },
    { id: 196, value: "67", color: "black" },
  ],
  [
    { id: 197, value: "29", color: "black" },
    { id: 198, value: "51", color: "black" },
    { id: 199, value: "68", color: "black" },
    { id: 200, value: "55", color: "black" },
    { id: 201, value: "11", color: "black" },
    { id: 202, value: "**", color: "black" },
    { id: 203, value: "**", color: "black" },
  ],
];

// 6.30

export const Six = [
  [
    { id: 1, value: "96", color: "black" },
    { id: 2, value: "80", color: "black" },
    { id: 3, value: "60", color: "black" },
    { id: 4, value: "30", color: "black" },
    { id: 5, value: "13", color: "black" },
    { id: 6, value: "21", color: "black" },
    { id: 7, value: "25", color: "black" },
  ],
  [
    { id: 8, value: "88", color: "black" },
    { id: 9, value: "25", color: "black" },
    { id: 10, value: "32", color: "black" },
    { id: 11, value: "45", color: "black" },
    { id: 12, value: "22", color: "black" },
    { id: 13, value: "05", color: "black" },
    { id: 14, value: "96", color: "black" },
  ],
  [
    { id: 15, value: "45", color: "black" },
    { id: 16, value: "33", color: "black" },
    { id: 17, value: "33", color: "black" },
    { id: 18, value: "15", color: "black" },
    { id: 19, value: "64", color: "black" },
    { id: 20, value: "46", color: "black" },
    { id: 21, value: "21", color: "black" },
  ],
  [
    { id: 22, value: "56", color: "black" },
    { id: 23, value: "68", color: "black" },
    { id: 24, value: "07", color: "black" },
    { id: 25, value: "08", color: "black" },
    { id: 26, value: "30", color: "black" },
    { id: 27, value: "45", color: "black" },
    { id: 28, value: "65", color: "black" },
  ],
  [
    { id: 29, value: "26", color: "black" },
    { id: 30, value: "27", color: "black" },
    { id: 31, value: "71", color: "black" },
    { id: 32, value: "16", color: "black" },
    { id: 33, value: "89", color: "black" },
    { id: 34, value: "79", color: "black" },
    { id: 35, value: "64", color: "black" },
  ],
  [
    { id: 36, value: "20", color: "black" },
    { id: 37, value: "91", color: "black" },
    { id: 38, value: "89", color: "black" },
    { id: 39, value: "81", color: "black" },
    { id: 40, value: "41", color: "black" },
    { id: 41, value: "46", color: "black" },
    { id: 42, value: "21", color: "black" },
  ],
  [
    { id: 43, value: "11", color: "black" },
    { id: 44, value: "41", color: "black" },
    { id: 45, value: "94", color: "black" },
    { id: 46, value: "10", color: "black" },
    { id: 47, value: "28", color: "black" },
    { id: 48, value: "67", color: "black" },
    { id: 49, value: "14", color: "black" },
  ],
  [
    { id: 50, value: "10", color: "black" },
    { id: 51, value: "09", color: "black" },
    { id: 52, value: "11", color: "black" },
    { id: 53, value: "11", color: "black" },
    { id: 54, value: "13", color: "black" },
    { id: 55, value: "29", color: "black" },
    { id: 56, value: "49", color: "black" },
  ],
  [
    { id: 57, value: "50", color: "black" },
    { id: 58, value: "25", color: "black" },
    { id: 59, value: "36", color: "black" },
    { id: 60, value: "06", color: "black" },
    { id: 61, value: "87", color: "black" },
    { id: 62, value: "95", color: "black" },
    { id: 63, value: "16", color: "black" },
  ],
  [
    { id: 64, value: "26", color: "black" },
    { id: 65, value: "94", color: "black" },
    { id: 66, value: "95", color: "black" },
    { id: 67, value: "02", color: "black" },
    { id: 68, value: "54", color: "black" },
    { id: 69, value: "76", color: "black" },
    { id: 70, value: "02", color: "black" },
  ],
  [
    { id: 71, value: "26", color: "black" },
    { id: 72, value: "20", color: "black" },
    { id: 73, value: "81", color: "black" },
    { id: 74, value: "19", color: "black" },
    { id: 75, value: "27", color: "black" },
    { id: 76, value: "83", color: "black" },
    { id: 77, value: "78", color: "black" },
  ],
  [
    { id: 78, value: "21", color: "black" },
    { id: 79, value: "44", color: "black" },
    { id: 80, value: "20", color: "black" },
    { id: 81, value: "53", color: "black" },
    { id: 82, value: "80", color: "black" },
    { id: 83, value: "09", color: "black" },
    { id: 84, value: "31", color: "black" },
  ],
  [
    { id: 85, value: "31", color: "black" },
    { id: 86, value: "93", color: "black" },
    { id: 87, value: "79", color: "black" },
    { id: 88, value: "69", color: "black" },
    { id: 89, value: "80", color: "black" },
    { id: 90, value: "07", color: "black" },
    { id: 91, value: "39", color: "black" },
  ],
  [
    { id: 92, value: "73", color: "black" },
    { id: 93, value: "02", color: "black" },
    { id: 94, value: "85", color: "black" },
    { id: 95, value: "01", color: "black" },
    { id: 96, value: "56", color: "black" },
    { id: 97, value: "40", color: "black" },
    { id: 98, value: "31", color: "black" },
  ],
  [
    { id: 99, value: "95", color: "black" },
    { id: 100, value: "47", color: "black" },
    { id: 101, value: "74", color: "black" },
    { id: 102, value: "53", color: "black" },
    { id: 103, value: "37", color: "black" },
    { id: 104, value: "77", color: "black" },
    { id: 105, value: "04", color: "black" },
  ],
  [
    { id: 106, value: "43", color: "black" },
    { id: 107, value: "98", color: "black" },
    { id: 108, value: "88", color: "black" },
    { id: 109, value: "72", color: "black" },
    { id: 110, value: "34", color: "black" },
    { id: 111, value: "17", color: "black" },
    { id: 112, value: "94", color: "black" },
  ],
  [
    { id: 113, value: "34", color: "black" },
    { id: 114, value: "32", color: "black" },
    { id: 115, value: "20", color: "black" },
    { id: 116, value: "26", color: "black" },
    { id: 117, value: "36", color: "black" },
    { id: 118, value: "79", color: "black" },
    { id: 119, value: "12", color: "black" },
  ],
  [
    { id: 120, value: "27", color: "black" },
    { id: 121, value: "21", color: "black" },
    { id: 122, value: "70", color: "black" },
    { id: 123, value: "13", color: "black" },
    { id: 124, value: "80", color: "black" },
    { id: 125, value: "76", color: "black" },
    { id: 126, value: "67", color: "black" },
  ],
  [
    { id: 127, value: "72", color: "black" },
    { id: 128, value: "92", color: "black" },
    { id: 129, value: "54", color: "black" },
    { id: 130, value: "25", color: "black" },
    { id: 131, value: "82", color: "black" },
    { id: 132, value: "96", color: "black" },
    { id: 133, value: "36", color: "black" },
  ],
  [
    { id: 134, value: "50", color: "black" },
    { id: 135, value: "71", color: "black" },
    { id: 136, value: "48", color: "black" },
    { id: 137, value: "58", color: "black" },
    { id: 138, value: "56", color: "black" },
    { id: 139, value: "61", color: "black" },
    { id: 140, value: "50", color: "black" },
  ],
  [
    { id: 141, value: "43", color: "black" },
    { id: 142, value: "85", color: "black" },
    { id: 143, value: "60", color: "black" },
    { id: 144, value: "58", color: "black" },
    { id: 145, value: "40", color: "black" },
    { id: 146, value: "88", color: "black" },
    { id: 147, value: "68", color: "black" },
  ],
  [
    { id: 148, value: "51", color: "black" },
    { id: 149, value: "25", color: "black" },
    { id: 150, value: "88", color: "black" },
    { id: 151, value: "17", color: "black" },
    { id: 152, value: "49", color: "black" },
    { id: 153, value: "19", color: "black" },
    { id: 154, value: "92", color: "black" },
  ],
  [
    { id: 155, value: "05", color: "black" },
    { id: 156, value: "59", color: "black" },
    { id: 157, value: "58", color: "black" },
    { id: 158, value: "12", color: "black" },
    { id: 159, value: "58", color: "black" },
    { id: 160, value: "50", color: "black" },
    { id: 161, value: "72", color: "black" },
  ],
  [
    { id: 162, value: "42", color: "black" },
    { id: 163, value: "98", color: "black" },
    { id: 164, value: "32", color: "black" },
    { id: 165, value: "37", color: "black" },
    { id: 166, value: "70", color: "black" },
    { id: 167, value: "65", color: "black" },
    { id: 168, value: "19", color: "black" },
  ],
  [
    { id: 169, value: "84", color: "black" },
    { id: 170, value: "48", color: "black" },
    { id: 171, value: "93", color: "black" },
    { id: 172, value: "01", color: "black" },
    { id: 173, value: "27", color: "black" },
    { id: 174, value: "96", color: "black" },
    { id: 175, value: "81", color: "black" },
  ],
  [
    { id: 176, value: "30", color: "black" },
    { id: 177, value: "42", color: "black" },
    { id: 178, value: "72", color: "black" },
    { id: 179, value: "12", color: "black" },
    { id: 180, value: "50", color: "black" },
    { id: 181, value: "31", color: "black" },
    { id: 182, value: "67", color: "black" },
  ],
  [
    { id: 183, value: "67", color: "black" },
    { id: 184, value: "89", color: "black" },
    { id: 185, value: "48", color: "black" },
    { id: 186, value: "60", color: "black" },
    { id: 187, value: "65", color: "black" },
    { id: 188, value: "68", color: "black" },
    { id: 189, value: "87", color: "black" },
  ],
  [
    { id: 190, value: "49", color: "black" },
    { id: 240, value: "**", color: "black" },
    { id: 191, value: "19", color: "black" },
    { id: 192, value: "87", color: "black" },
    { id: 193, value: "99", color: "black" },
    { id: 194, value: "78", color: "black" },
    { id: 195, value: "64", color: "black" },
  ],
  [
    { id: 196, value: "65", color: "black" },
    { id: 197, value: "64", color: "black" },
    { id: 198, value: "56", color: "black" },
    { id: 199, value: "87", color: "black" },
    { id: 200, value: "91", color: "black" },
    { id: 201, value: "62", color: "black" },
    { id: 202, value: "82", color: "black" },
  ],
  [
    { id: 203, value: "84", color: "black" },
    { id: 204, value: "61", color: "black" },
    { id: 205, value: "99", color: "black" },
    { id: 206, value: "55", color: "black" },
    { id: 207, value: "37", color: "black" },
    { id: 208, value: "32", color: "black" },
    { id: 209, value: "24", color: "black" },
  ],
  [
    { id: 210, value: "13", color: "black" },
    { id: 211, value: "12", color: "black" },
    { id: 212, value: "20", color: "black" },
    { id: 213, value: "99", color: "black" },
    { id: 214, value: "53", color: "black" },
    { id: 215, value: "**", color: "black" },
    { id: 216, value: "**", color: "black" },
  ],
];

// 7.30

export const Seven = [
  [
    { id: 1, value: "28", color: "black" },
    { id: 2, value: "44", color: "black" },
    { id: 3, value: "12", color: "black" },
    { id: 4, value: "26", color: "black" },
    { id: 5, value: "46", color: "black" },
    { id: 6, value: "22", color: "black" },
    { id: 7, value: "35", color: "black" },
  ],
  [
    { id: 8, value: "75", color: "black" },
    { id: 9, value: "30", color: "black" },
    { id: 10, value: "46", color: "black" },
    { id: 11, value: "92", color: "black" },
    { id: 12, value: "14", color: "black" },
    { id: 13, value: "02", color: "black" },
    { id: 14, value: "08", color: "black" },
  ],
  [
    { id: 15, value: "14", color: "black" },
    { id: 16, value: "20", color: "black" },
    { id: 17, value: "51", color: "black" },
    { id: 18, value: "65", color: "black" },
    { id: 19, value: "10", color: "black" },
    { id: 20, value: "34", color: "black" },
    { id: 21, value: "56", color: "black" },
  ],
  [
    { id: 22, value: "99", color: "black" },
    { id: 23, value: "64", color: "black" },
    { id: 24, value: "90", color: "black" },
    { id: 25, value: "60", color: "black" },
    { id: 26, value: "52", color: "black" },
    { id: 27, value: "58", color: "black" },
    { id: 28, value: "52", color: "black" },
  ],
  [
    { id: 29, value: "20", color: "black" },
    { id: 30, value: "17", color: "black" },
    { id: 31, value: "90", color: "black" },
    { id: 32, value: "88", color: "black" },
    { id: 33, value: "49", color: "black" },
    { id: 34, value: "14", color: "black" },
    { id: 35, value: "30", color: "black" },
  ],
  [
    { id: 36, value: "99", color: "black" },
    { id: 37, value: "50", color: "black" },
    { id: 38, value: "50", color: "black" },
    { id: 39, value: "45", color: "black" },
    { id: 40, value: "45", color: "black" },
    { id: 41, value: "36", color: "black" },
    { id: 42, value: "72", color: "black" },
  ],
  [
    { id: 43, value: "49", color: "black" },
    { id: 44, value: "07", color: "black" },
    { id: 45, value: "10", color: "black" },
    { id: 46, value: "10", color: "black" },
    { id: 47, value: "94", color: "black" },
    { id: 48, value: "07", color: "black" },
    { id: 49, value: "57", color: "black" },
  ],
  [
    { id: 50, value: "18", color: "black" },
    { id: 51, value: "41", color: "black" },
    { id: 52, value: "42", color: "black" },
    { id: 53, value: "41", color: "black" },
    { id: 54, value: "42", color: "black" },
    { id: 55, value: "08", color: "black" },
    { id: 56, value: "30", color: "black" },
  ],
  [
    { id: 57, value: "68", color: "black" },
    { id: 58, value: "96", color: "black" },
    { id: 59, value: "14", color: "black" },
    { id: 60, value: "31", color: "black" },
    { id: 61, value: "43", color: "black" },
    { id: 62, value: "36", color: "black" },
    { id: 63, value: "77", color: "black" },
  ],
  [
    { id: 64, value: "66", color: "black" },
    { id: 65, value: "30", color: "black" },
    { id: 66, value: "38", color: "black" },
    { id: 67, value: "10", color: "black" },
    { id: 68, value: "23", color: "black" },
    { id: 69, value: "15", color: "black" },
    { id: 70, value: "13", color: "black" },
  ],
  [
    { id: 71, value: "48", color: "black" },
    { id: 72, value: "52", color: "black" },
    { id: 73, value: "67", color: "black" },
    { id: 74, value: "78", color: "black" },
    { id: 75, value: "25", color: "black" },
    { id: 76, value: "62", color: "black" },
    { id: 77, value: "59", color: "black" },
  ],
  [
    { id: 78, value: "31", color: "black" },
    { id: 79, value: "91", color: "black" },
    { id: 80, value: "08", color: "black" },
    { id: 81, value: "75", color: "black" },
    { id: 82, value: "97", color: "black" },
    { id: 83, value: "57", color: "black" },
    { id: 84, value: "73", color: "black" },
  ],
  [
    { id: 85, value: "22", color: "black" },
    { id: 86, value: "55", color: "black" },
    { id: 87, value: "53", color: "black" },
    { id: 88, value: "25", color: "black" },
    { id: 89, value: "20", color: "black" },
    { id: 90, value: "61", color: "black" },
    { id: 91, value: "12", color: "black" },
  ],
  [
    { id: 92, value: "02", color: "black" },
    { id: 93, value: "81", color: "black" },
    { id: 94, value: "94", color: "black" },
    { id: 95, value: "39", color: "black" },
    { id: 96, value: "15", color: "black" },
    { id: 97, value: "45", color: "black" },
    { id: 98, value: "27", color: "black" },
  ],
  [
    { id: 99, value: "24", color: "black" },
    { id: 100, value: "21", color: "black" },
    { id: 101, value: "89", color: "black" },
    { id: 102, value: "43", color: "black" },
    { id: 103, value: "18", color: "black" },
    { id: 104, value: "91", color: "black" },
    { id: 105, value: "89", color: "black" },
  ],
  [
    { id: 106, value: "46", color: "black" },
    { id: 107, value: "39", color: "black" },
    { id: 108, value: "29", color: "black" },
    { id: 109, value: "79", color: "black" },
    { id: 110, value: "46", color: "black" },
    { id: 111, value: "64", color: "black" },
    { id: 112, value: "40", color: "black" },
  ],
  [
    { id: 113, value: "74", color: "black" },
    { id: 114, value: "52", color: "black" },
    { id: 115, value: "29", color: "black" },
    { id: 116, value: "96", color: "black" },
    { id: 117, value: "85", color: "black" },
    { id: 118, value: "25", color: "black" },
    { id: 119, value: "91", color: "black" },
  ],
  [
    { id: 120, value: "96", color: "black" },
    { id: 121, value: "32", color: "black" },
    { id: 122, value: "09", color: "black" },
    { id: 123, value: "34", color: "black" },
    { id: 124, value: "89", color: "black" },
    { id: 125, value: "84", color: "black" },
    { id: 126, value: "81", color: "black" },
  ],
  [
    { id: 127, value: "56", color: "black" },
    { id: 128, value: "68", color: "black" },
    { id: 129, value: "93", color: "black" },
    { id: 130, value: "20", color: "black" },
    { id: 131, value: "62", color: "black" },
    { id: 132, value: "13", color: "black" },
    { id: 133, value: "82", color: "black" },
  ],
  [
    { id: 134, value: "19", color: "black" },
    { id: 135, value: "72", color: "black" },
    { id: 136, value: "03", color: "black" },
    { id: 137, value: "71", color: "black" },
    { id: 138, value: "73", color: "black" },
    { id: 139, value: "55", color: "black" },
    { id: 140, value: "57", color: "black" },
  ],
  [
    { id: 141, value: "20", color: "black" },
    { id: 142, value: "62", color: "black" },
    { id: 143, value: "27", color: "black" },
    { id: 144, value: "08", color: "black" },
    { id: 145, value: "62", color: "black" },
    { id: 146, value: "73", color: "black" },
    { id: 147, value: "54", color: "black" },
  ],
  [
    { id: 148, value: "07", color: "black" },
    { id: 149, value: "11", color: "black" },
    { id: 150, value: "56", color: "black" },
    { id: 151, value: "48", color: "black" },
    { id: 152, value: "34", color: "black" },
    { id: 153, value: "16", color: "black" },
    { id: 154, value: "99", color: "black" },
  ],
  [
    { id: 155, value: "49", color: "black" },
    { id: 156, value: "07", color: "black" },
    { id: 157, value: "59", color: "black" },
    { id: 158, value: "71", color: "black" },
    { id: 159, value: "73", color: "black" },
    { id: 160, value: "05", color: "black" },
    { id: 161, value: "98", color: "black" },
  ],
  [
    { id: 162, value: "58", color: "black" },
    { id: 163, value: "96", color: "black" },
    { id: 164, value: "30", color: "black" },
    { id: 165, value: "13", color: "black" },
    { id: 166, value: "76", color: "black" },
    { id: 167, value: "51", color: "black" },
    { id: 168, value: "01", color: "black" },
  ],
  [
    { id: 169, value: "52", color: "black" },
    { id: 170, value: "48", color: "black" },
    { id: 171, value: "47", color: "black" },
    { id: 172, value: "56", color: "black" },
    { id: 173, value: "91", color: "black" },
    { id: 174, value: "37", color: "black" },
    { id: 175, value: "11", color: "black" },
  ],
  [
    { id: 176, value: "38", color: "black" },
    { id: 177, value: "**", color: "black" },
    { id: 178, value: "89", color: "black" },
    { id: 179, value: "76", color: "black" },
    { id: 180, value: "10", color: "black" },
    { id: 181, value: "31", color: "black" },
    { id: 182, value: "23", color: "black" },
  ],
  [
    { id: 183, value: "94", color: "black" },
    { id: 184, value: "75", color: "black" },
    { id: 185, value: "85", color: "black" },
    { id: 186, value: "93", color: "black" },
    { id: 187, value: "60", color: "black" },
    { id: 188, value: "**", color: "black" },
    { id: 189, value: "97", color: "black" },
  ],
  [
    { id: 190, value: "42", color: "black" },
    { id: 191, value: "88", color: "black" },
    { id: 192, value: "68", color: "black" },
    { id: 193, value: "47", color: "black" },
    { id: 194, value: "77", color: "black" },
    { id: 195, value: "70", color: "black" },
    { id: 196, value: "54", color: "black" },
  ],
  [
    { id: 197, value: "16", color: "black" },
    { id: 198, value: "11", color: "black" },
    { id: 199, value: "29", color: "black" },
    { id: 200, value: "91", color: "black" },
    { id: 201, value: "88", color: "black" },
    { id: 202, value: "**", color: "black" },
    { id: 203, value: "**", color: "black" },
  ],
];

// 8.30

export const Eight = [
  [
    { id: 1, value: "90", color: "black" },
    { id: 2, value: "36", color: "black" },
    { id: 3, value: "48", color: "black" },
    { id: 4, value: "32", color: "black" },
    { id: 5, value: "17", color: "black" },
    { id: 6, value: "17", color: "black" },
    { id: 7, value: "48", color: "black" },
  ],
  [
    { id: 8, value: "46", color: "black" },
    { id: 9, value: "79", color: "black" },
    { id: 10, value: "30", color: "black" },
    { id: 11, value: "17", color: "black" },
    { id: 12, value: "89", color: "black" },
    { id: 13, value: "18", color: "black" },
    { id: 14, value: "12", color: "black" },
  ],
  [
    { id: 15, value: "46", color: "black" },
    { id: 16, value: "29", color: "black" },
    { id: 17, value: "95", color: "black" },
    { id: 18, value: "46", color: "black" },
    { id: 19, value: "79", color: "black" },
    { id: 20, value: "20", color: "black" },
    { id: 21, value: "12", color: "black" },
  ],
  [
    { id: 22, value: "50", color: "black" },
    { id: 23, value: "28", color: "black" },
    { id: 24, value: "16", color: "black" },
    { id: 25, value: "34", color: "black" },
    { id: 26, value: "40", color: "black" },
    { id: 27, value: "16", color: "black" },
    { id: 28, value: "72", color: "black" },
  ],
  [
    { id: 29, value: "36", color: "black" },
    { id: 30, value: "14", color: "black" },
    { id: 31, value: "06", color: "black" },
    { id: 32, value: "45", color: "black" },
    { id: 33, value: "01", color: "black" },
    { id: 34, value: "77", color: "black" },
    { id: 35, value: "33", color: "black" },
  ],
  [
    { id: 36, value: "82", color: "black" },
    { id: 37, value: "38", color: "black" },
    { id: 38, value: "01", color: "black" },
    { id: 39, value: "26", color: "black" },
    { id: 40, value: "36", color: "black" },
    { id: 41, value: "26", color: "black" },
    { id: 42, value: "10", color: "black" },
  ],
  [
    { id: 43, value: "35", color: "black" },
    { id: 44, value: "14", color: "black" },
    { id: 45, value: "19", color: "black" },
    { id: 46, value: "26", color: "black" },
    { id: 47, value: "36", color: "black" },
    { id: 48, value: "20", color: "black" },
    { id: 49, value: "55", color: "black" },
  ],
  [
    { id: 50, value: "13", color: "black" },
    { id: 51, value: "14", color: "black" },
    { id: 52, value: "47", color: "black" },
    { id: 53, value: "64", color: "black" },
    { id: 54, value: "60", color: "black" },
    { id: 55, value: "56", color: "black" },
    { id: 56, value: "03", color: "black" },
  ],
  [
    { id: 57, value: "94", color: "black" },
    { id: 58, value: "26", color: "black" },
    { id: 59, value: "13", color: "black" },
    { id: 60, value: "28", color: "black" },
    { id: 61, value: "36", color: "black" },
    { id: 62, value: "54", color: "black" },
    { id: 63, value: "96", color: "black" },
  ],
  [
    { id: 64, value: "86", color: "black" },
    { id: 65, value: "86", color: "black" },
    { id: 66, value: "69", color: "black" },
    { id: 67, value: "01", color: "black" },
    { id: 68, value: "34", color: "black" },
    { id: 69, value: "65", color: "black" },
    { id: 70, value: "85", color: "black" },
  ],
  [
    { id: 71, value: "11", color: "black" },
    { id: 72, value: "62", color: "black" },
    { id: 73, value: "04", color: "black" },
    { id: 74, value: "62", color: "black" },
    { id: 75, value: "15", color: "black" },
    { id: 76, value: "56", color: "black" },
    { id: 77, value: "03", color: "black" },
  ],
  [
    { id: 78, value: "79", color: "black" },
    { id: 79, value: "31", color: "black" },
    { id: 80, value: "92", color: "black" },
    { id: 81, value: "12", color: "black" },
    { id: 82, value: "68", color: "black" },
    { id: 83, value: "84", color: "black" },
    { id: 84, value: "44", color: "black" },
  ],
  [
    { id: 85, value: "50", color: "black" },
    { id: 86, value: "75", color: "black" },
    { id: 87, value: "25", color: "black" },
    { id: 88, value: "45", color: "black" },
    { id: 89, value: "24", color: "black" },
    { id: 90, value: "03", color: "black" },
    { id: 91, value: "45", color: "black" },
  ],
  [
    { id: 92, value: "37", color: "black" },
    { id: 93, value: "63", color: "black" },
    { id: 94, value: "10", color: "black" },
    { id: 95, value: "74", color: "black" },
    { id: 96, value: "92", color: "black" },
    { id: 97, value: "72", color: "black" },
    { id: 98, value: "30", color: "black" },
  ],
  [
    { id: 99, value: "16", color: "black" },
    { id: 100, value: "60", color: "black" },
    { id: 101, value: "56", color: "black" },
    { id: 102, value: "04", color: "black" },
    { id: 103, value: "72", color: "black" },
    { id: 104, value: "92", color: "black" },
    { id: 105, value: "07", color: "black" },
  ],
  [
    { id: 106, value: "86", color: "black" },
    { id: 107, value: "35", color: "black" },
    { id: 108, value: "73", color: "black" },
    { id: 109, value: "44", color: "black" },
    { id: 110, value: "54", color: "black" },
    { id: 111, value: "07", color: "black" },
    { id: 112, value: "74", color: "black" },
  ],
  [
    { id: 113, value: "50", color: "black" },
    { id: 114, value: "96", color: "black" },
    { id: 115, value: "23", color: "black" },
    { id: 116, value: "38", color: "black" },
    { id: 117, value: "30", color: "black" },
    { id: 118, value: "83", color: "black" },
    { id: 119, value: "86", color: "black" },
  ],
  [
    { id: 120, value: "31", color: "black" },
    { id: 121, value: "65", color: "black" },
    { id: 122, value: "60", color: "black" },
    { id: 123, value: "38", color: "black" },
    { id: 124, value: "53", color: "black" },
    { id: 125, value: "82", color: "black" },
    { id: 126, value: "01", color: "black" },
  ],
  [
    { id: 127, value: "52", color: "black" },
    { id: 128, value: "92", color: "black" },
    { id: 129, value: "69", color: "black" },
    { id: 130, value: "05", color: "black" },
    { id: 131, value: "90", color: "black" },
    { id: 132, value: "57", color: "black" },
    { id: 133, value: "33", color: "black" },
  ],
  [
    { id: 134, value: "13", color: "black" },
    { id: 135, value: "75", color: "black" },
    { id: 136, value: "73", color: "black" },
    { id: 137, value: "28", color: "black" },
    { id: 138, value: "63", color: "black" },
    { id: 139, value: "80", color: "black" },
    { id: 140, value: "05", color: "black" },
  ],
  [
    { id: 141, value: "83", color: "black" },
    { id: 142, value: "96", color: "black" },
    { id: 143, value: "20", color: "black" },
    { id: 144, value: "69", color: "black" },
    { id: 145, value: "83", color: "black" },
    { id: 146, value: "94", color: "black" },
    { id: 147, value: "37", color: "black" },
  ],
  [
    { id: 148, value: "46", color: "black" },
    { id: 149, value: "07", color: "black" },
    { id: 150, value: "83", color: "black" },
    { id: 151, value: "95", color: "black" },
    { id: 152, value: "74", color: "black" },
    { id: 153, value: "10", color: "black" },
    { id: 154, value: "48", color: "black" },
  ],
  [
    { id: 155, value: "36", color: "black" },
    { id: 156, value: "87", color: "black" },
    { id: 157, value: "25", color: "black" },
    { id: 158, value: "97", color: "black" },
    { id: 159, value: "52", color: "black" },
    { id: 160, value: "83", color: "black" },
    { id: 161, value: "08", color: "black" },
  ],
  [
    { id: 162, value: "62", color: "black" },
    { id: 163, value: "82", color: "black" },
    { id: 164, value: "12", color: "black" },
    { id: 165, value: "23", color: "black" },
    { id: 166, value: "97", color: "black" },
    { id: 167, value: "25", color: "black" },
    { id: 168, value: "39", color: "black" },
  ],
  [
    { id: 169, value: "59", color: "black" },
    { id: 170, value: "21", color: "black" },
    { id: 171, value: "17", color: "black" },
    { id: 172, value: "52", color: "black" },
    { id: 173, value: "24", color: "black" },
    { id: 174, value: "79", color: "black" },
    { id: 175, value: "21", color: "black" },
  ],
  [
    { id: 176, value: "52", color: "black" },
    { id: 177, value: "63", color: "black" },
    { id: 178, value: "75", color: "black" },
    { id: 179, value: "98", color: "black" },
    { id: 180, value: "56", color: "black" },
    { id: 181, value: "26", color: "black" },
    { id: 182, value: "75", color: "black" },
  ],
  [
    { id: 183, value: "71", color: "black" },
    { id: 184, value: "**", color: "black" },
    { id: 185, value: "58", color: "black" },
    { id: 186, value: "41", color: "black" },
    { id: 187, value: "22", color: "black" },
    { id: 188, value: "54", color: "black" },
    { id: 189, value: "42", color: "black" },
  ],
  [
    { id: 190, value: "54", color: "black" },
    { id: 191, value: "83", color: "black" },
    { id: 192, value: "94", color: "black" },
    { id: 193, value: "76", color: "black" },
    { id: 194, value: "52", color: "black" },
    { id: 195, value: "74", color: "black" },
    { id: 196, value: "24", color: "black" },
  ],
  [
    { id: 197, value: "36", color: "black" },
    { id: 198, value: "70", color: "black" },
    { id: 199, value: "55", color: "black" },
    { id: 200, value: "17", color: "black" },
    { id: 201, value: "47", color: "black" },
    { id: 202, value: "29", color: "black" },
    { id: 203, value: "63", color: "black" },
  ],
  [
    { id: 204, value: "32", color: "black" },
    { id: 205, value: "14", color: "black" },
    { id: 206, value: "12", color: "black" },
    { id: 207, value: "23", color: "black" },
    { id: 208, value: "27", color: "black" },
    { id: 209, value: "**", color: "black" },
    { id: 210, value: "**", color: "black" },
  ],
];

// 9.30
export const Nine = [
  [
    { id: 1, value: "37", color: "black" },
    { id: 2, value: "54", color: "black" },
    { id: 3, value: "13", color: "black" },
    { id: 4, value: "26", color: "black" },
    { id: 5, value: "44", color: "black" },
    { id: 6, value: "29", color: "black" },
    { id: 7, value: "39", color: "black" },
  ],
  [
    { id: 8, value: "20", color: "black" },
    { id: 9, value: "16", color: "black" },
    { id: 10, value: "86", color: "black" },
    { id: 11, value: "48", color: "black" },
    { id: 12, value: "46", color: "black" },
    { id: 13, value: "22", color: "black" },
    { id: 14, value: "17", color: "black" },
  ],
  [
    { id: 15, value: "51", color: "black" },
    { id: 16, value: "80", color: "black" },
    { id: 17, value: "17", color: "black" },
    { id: 18, value: "76", color: "black" },
    { id: 19, value: "97", color: "black" },
    { id: 20, value: "50", color: "black" },
    { id: 21, value: "85", color: "black" },
  ],
  [
    { id: 22, value: "65", color: "black" },
    { id: 23, value: "33", color: "black" },
    { id: 24, value: "44", color: "black" },
    { id: 25, value: "31", color: "black" },
    { id: 26, value: "65", color: "black" },
    { id: 27, value: "60", color: "black" },
    { id: 28, value: "45", color: "black" },
  ],
  [
    { id: 29, value: "76", color: "black" },
    { id: 30, value: "71", color: "black" },
    { id: 31, value: "65", color: "black" },
    { id: 32, value: "73", color: "black" },
    { id: 33, value: "55", color: "black" },
    { id: 34, value: "01", color: "black" },
    { id: 35, value: "21", color: "black" },
  ],
  [
    { id: 36, value: "26", color: "black" },
    { id: 37, value: "57", color: "black" },
    { id: 38, value: "99", color: "black" },
    { id: 39, value: "36", color: "black" },
    { id: 40, value: "64", color: "black" },
    { id: 41, value: "45", color: "black" },
    { id: 42, value: "14", color: "black" },
  ],
  [
    { id: 43, value: "42", color: "black" },
    { id: 44, value: "99", color: "black" },
    { id: 45, value: "08", color: "black" },
    { id: 46, value: "82", color: "black" },
    { id: 47, value: "26", color: "black" },
    { id: 48, value: "92", color: "black" },
    { id: 49, value: "39", color: "black" },
  ],
  [
    { id: 50, value: "72", color: "black" },
    { id: 51, value: "98", color: "black" },
    { id: 52, value: "02", color: "black" },
    { id: 53, value: "36", color: "black" },
    { id: 54, value: "86", color: "black" },
    { id: 55, value: "92", color: "black" },
    { id: 56, value: "43", color: "black" },
  ],
  [
    { id: 57, value: "30", color: "black" },
    { id: 58, value: "64", color: "black" },
    { id: 59, value: "24", color: "black" },
    { id: 60, value: "79", color: "black" },
    { id: 61, value: "55", color: "black" },
    { id: 62, value: "96", color: "black" },
    { id: 63, value: "94", color: "black" },
  ],
  [
    { id: 64, value: "27", color: "black" },
    { id: 65, value: "93", color: "black" },
    { id: 66, value: "45", color: "black" },
    { id: 67, value: "81", color: "black" },
    { id: 68, value: "62", color: "black" },
    { id: 69, value: "19", color: "black" },
    { id: 70, value: "22", color: "black" },
  ],
  [
    { id: 71, value: "87", color: "black" },
    { id: 72, value: "45", color: "black" },
    { id: 73, value: "36", color: "black" },
    { id: 74, value: "85", color: "black" },
    { id: 75, value: "07", color: "black" },
    { id: 76, value: "04", color: "black" },
    { id: 77, value: "24", color: "black" },
  ],
  [
    { id: 78, value: "69", color: "black" },
    { id: 79, value: "86", color: "black" },
    { id: 80, value: "44", color: "black" },
    { id: 81, value: "65", color: "black" },
    { id: 82, value: "74", color: "black" },
    { id: 83, value: "14", color: "black" },
    { id: 84, value: "14", color: "black" },
  ],
  [
    { id: 85, value: "86", color: "black" },
    { id: 86, value: "97", color: "black" },
    { id: 87, value: "44", color: "black" },
    { id: 88, value: "09", color: "black" },
    { id: 89, value: "48", color: "black" },
    { id: 90, value: "48", color: "black" },
    { id: 91, value: "93", color: "black" },
  ],
  [
    { id: 92, value: "38", color: "black" },
    { id: 93, value: "24", color: "black" },
    { id: 94, value: "49", color: "black" },
    { id: 95, value: "51", color: "black" },
    { id: 96, value: "80", color: "black" },
    { id: 97, value: "79", color: "black" },
    { id: 98, value: "52", color: "black" },
  ],
  [
    { id: 99, value: "58", color: "black" },
    { id: 100, value: "95", color: "black" },
    { id: 101, value: "04", color: "black" },
    { id: 102, value: "65", color: "black" },
    { id: 103, value: "23", color: "black" },
    { id: 104, value: "64", color: "black" },
    { id: 105, value: "28", color: "black" },
  ],
  [
    { id: 106, value: "41", color: "black" },
    { id: 107, value: "60", color: "black" },
    { id: 108, value: "12", color: "black" },
    { id: 109, value: "03", color: "black" },
    { id: 110, value: "51", color: "black" },
    { id: 111, value: "90", color: "black" },
    { id: 112, value: "03", color: "black" },
  ],
  [
    { id: 113, value: "40", color: "black" },
    { id: 114, value: "49", color: "black" },
    { id: 115, value: "76", color: "black" },
    { id: 116, value: "95", color: "black" },
    { id: 117, value: "35", color: "black" },
    { id: 118, value: "38", color: "black" },
    { id: 119, value: "93", color: "black" },
  ],
  [
    { id: 120, value: "26", color: "black" },
    { id: 121, value: "26", color: "black" },
    { id: 122, value: "80", color: "black" },
    { id: 123, value: "12", color: "black" },
    { id: 124, value: "22", color: "black" },
    { id: 125, value: "97", color: "black" },
    { id: 126, value: "06", color: "black" },
  ],
  [
    { id: 127, value: "62", color: "black" },
    { id: 128, value: "81", color: "black" },
    { id: 129, value: "98", color: "black" },
    { id: 130, value: "95", color: "black" },
    { id: 131, value: "14", color: "black" },
    { id: 132, value: "44", color: "black" },
    { id: 133, value: "17", color: "black" },
  ],
  [
    { id: 134, value: "87", color: "black" },
    { id: 135, value: "14", color: "black" },
    { id: 136, value: "37", color: "black" },
    { id: 137, value: "24", color: "black" },
    { id: 138, value: "86", color: "black" },
    { id: 139, value: "61", color: "black" },
    { id: 140, value: "40", color: "black" },
  ],
  [
    { id: 141, value: "71", color: "black" },
    { id: 142, value: "82", color: "black" },
    { id: 143, value: "73", color: "black" },
    { id: 144, value: "02", color: "black" },
    { id: 145, value: "36", color: "black" },
    { id: 146, value: "11", color: "black" },
    { id: 147, value: "49", color: "black" },
  ],
  [
    { id: 148, value: "67", color: "black" },
    { id: 149, value: "89", color: "black" },
    { id: 150, value: "36", color: "black" },
    { id: 151, value: "66", color: "black" },
    { id: 152, value: "04", color: "black" },
    { id: 153, value: "55", color: "black" },
    { id: 154, value: "34", color: "black" },
  ],
  [
    { id: 155, value: "96", color: "black" },
    { id: 156, value: "53", color: "black" },
    { id: 157, value: "37", color: "black" },
    { id: 158, value: "29", color: "black" },
    { id: 159, value: "87", color: "black" },
    { id: 160, value: "60", color: "black" },
    { id: 161, value: "21", color: "black" },
  ],
  [
    { id: 162, value: "42", color: "black" },
    { id: 163, value: "47", color: "black" },
    { id: 164, value: "39", color: "black" },
    { id: 165, value: "89", color: "black" },
    { id: 166, value: "46", color: "black" },
    { id: 167, value: "84", color: "black" },
    { id: 168, value: "24", color: "black" },
  ],
  [
    { id: 169, value: "55", color: "black" },
    { id: 170, value: "45", color: "black" },
    { id: 171, value: "78", color: "black" },
    { id: 172, value: "73", color: "black" },
    { id: 173, value: "65", color: "black" },
    { id: 174, value: "19", color: "black" },
    { id: 175, value: "80", color: "black" },
  ],
  [
    { id: 176, value: "93", color: "black" },
    { id: 177, value: "**", color: "black" },
    { id: 178, value: "65", color: "black" },
    { id: 179, value: "53", color: "black" },
    { id: 180, value: "55", color: "black" },
    { id: 181, value: "92", color: "black" },
    { id: 182, value: "67", color: "black" },
  ],
  [
    { id: 183, value: "36", color: "black" },
    { id: 184, value: "98", color: "black" },
    { id: 185, value: "72", color: "black" },
    { id: 186, value: "64", color: "black" },
    { id: 187, value: "77", color: "black" },
    { id: 188, value: "84", color: "black" },
    { id: 189, value: "66", color: "black" },
  ],
  [
    { id: 190, value: "92", color: "black" },
    { id: 191, value: "65", color: "black" },
    { id: 192, value: "10", color: "black" },
    { id: 193, value: "56", color: "black" },
    { id: 194, value: "32", color: "black" },
    { id: 195, value: "40", color: "black" },
    { id: 196, value: "14", color: "black" },
  ],
  [
    { id: 197, value: "12", color: "black" },
    { id: 198, value: "13", color: "black" },
    { id: 199, value: "18", color: "black" },
    { id: 200, value: "56", color: "black" },
    { id: 201, value: "66", color: "black" },
    { id: 202, value: "**", color: "black" },
    { id: 203, value: "**", color: "black" },
  ],
];

export const allJackpot = [
  {
    "01/01/2024": [88, 90, 20, 65, 78, 88, 36, 44, 28, 41, 25, 78],
  },
  {
    "02/01/2024": [12, "09", 59, 68, 78, 25, "01", "02", 36, 45, 80, 90],
  },
  {
    "03/01/2024": [45, "04", 48, 37, 32, 20, 28, 75, 24, 65, 35, 64],
  },
  {
    "04/01/2024": [23, 45, 35, 96, 12, 32, 42, 58, 12, 28, 30, 75],
  },
  {
    "05/01/2024": [22, 58, 11, 45, 56, 17, 62, 33, 42, 75, 41, "00"],
  },
  {
    "06/01/2024": [25, "05", 17, 86, 53, 12, 55, 25, 33, 12, 88, 99],
  },
  {
    "07/01/2024": [82, 19, 38, 46, 96, 78, 12, 48, 83, 45, 49, 17],
  },
  {
    "08/01/2024": [12, 49, 37, 99, 90, 45, 76, 40, 11, 45, 46, 28],
  },
  {
    "09/01/2024": [44, 84, 45, 33, 54, 42, 25, 78, 79, 77, 31, 20],
  },
  {
    "10/01/2024": [46, 51, 33, 30, 12, 13, 55, "09", 46, 33, 41, 77],
  },
  {
    "11/01/2024": [34, 55, 15, 17, 26, 26, 43, 41, 99, 33, 19, 39],
  },
  {
    "12/01/2024": [11, 33, 64, 89, 46, 44, 14, 80, 56, 25, 83, 20],
  },
  {
    "13/01/2024": [69, 16, 46, 18, 22, 29, 75, 54, 30, 25, 51, 68],
  },
  {
    "14/01/2024": [23, 63, 21, 12, 35, 39, 85, 56, 25, "08", 45, 25],
  },
  {
    "15/01/2024": [23, 12, 56, 46, 75, 20, 31, 96, 45, 79, 21, 83],
  },
  {
    "16/01/2024": [56, 28, 68, 29, 30, 16, "08", 33, 59, 96, 93, 34],
  },
  {
    "17/01/2024": [20, 44, "07", 95, 46, 86, 52, 22, 19, 78, 15, 79],
  },
  {
    "18/01/2024": [19, 27, "08", 46, 92, 48, 15, 45, 16, 74, 63, "03"],
  },
  {
    "19/01/2024": [13, 15, 30, 79, 14, 46, "01", 19, 10, 58, "09", 54],
  },
  {
    "20/01/2024": [32, 98, 45, 20, "02", 22, 49, 48, 18, 55, 16, 53],
  },
  {
    "21/01/2024": [23, 30, 65, 12, "08", 17, 79, 89, 20, 14, 49, 20],
  },
  {
    "22/01/2024": [62, 46, 26, 50, 14, 51, 16, 54, 96, 34, "08", 20],
  },
  {
    "23/01/2024": [54, 63, 27, 28, 20, 80, 73, 48, 60, "09", 16, 49],
  },
  {
    "24/01/2024": [62, 37, 71, 16, 51, 17, 42, 21, 32, 81, 34, 17],
  },
  {
    "25/01/2024": [17, "03", 16, 34, 65, 76, 27, 30, 72, 91, 55, 81],
  },
  {
    "26/01/2024": [20, 76, 89, 40, 10, 97, "03", 38, 17, "01", "06", 52],
  },
  {
    "27/01/2024": ["06", 91, 79, 16, 34, 50, 28, 42, 61, 60, 87, 12],
  },
  {
    "28/01/2024": [10, 37, 64, 72, 56, 85, 29, 12, 50, 47, "02", 99],
  },
  {
    "29/01/2024": ["03", 10, 20, 36, 99, 65, 32, 76, "01", 12, 87, 48],
  },
  {
    "30/01/2024": [26, 49, 91, 14, 64, 33, 83, 51, 43, "03", 79, 32],
  },
  {
    "31/01/2024": [34, 12, 89, "06", 90, 44, 11, 69, 73, 99, 21, 28],
  },
  {
    "01/02/2024": [33, 27, 81, 45, 60, 31, 11, 15, "01", 55, 10, 38],
  },
  {
    "02/02/2024": [20, 30, 41, "01", 52, 65, "07", 19, 73, 64, 22, 79],
  },
  {
    "03/02/2024": ["03", 32, 46, 77, 58, 60, 12, 10, 28, 39, 31, 99],
  },
  {
    "04/02/2024": ["01", "03", 21, 33, 52, 45, 18, 82, 62, 31, 38, 89],
  },
  {
    "05/02/2024": [53, 94, 11, 82, 20, 76, 41, "07", 57, 34, 90, 62],
  },
  {
    "06/02/2024": ["02", 54, 41, 38, 17, 71, 28, 65, 60, 89, 18, 92],
  },
  {
    "07/02/2024": [25, 41, 94, "01", 90, 65, 49, "08", 99, 94, 82, 30],
  },
  {
    "08/02/2024": [97, "05", 10, 26, 88, 73, 61, 39, 49, 79, 14, 55],
  },
  {
    "09/02/2024": ["04", 10, 28, 36, 49, 55, 94, 60, 20, 14, 67, 79],
  },
  {
    "10/02/2024": [46, "08", 67, 26, 14, "01", 99, 85, 72, 30, 87, 58],
  },
  {
    "11/02/2024": [94, 48, 14, 10, 30, 21, 69, 11, 58, 67, 66, 92],
  },
  {
    "12/02/2024": ["07", 49, 10, 35, 99, 26, 72, 19, 58, 69, 81, 37],
  },
  {
    "13/02/2024": [46, 19, "09", 14, 50, 57, 11, 28, 32, 38, 99, 91],
  },
  {
    "14/02/2024": [17, 40, 11, 19, 50, 99, 84, 75, 33, 62, "01", 13],
  },
  {
    "15/02/2024": [30, 86, 11, 26, 45, 36, "07", 52, 68, 60, 93, 98],
  },
  {
    "16/02/2024": ["02", 24, 13, 36, 45, 64, 98, 55, 71, 83, 44, "04"],
  },
  {
    "17/02/2024": [11, 22, 29, 20, 36, 45, 11, 98, 86, 51, 64, "06"],
  },
  {
    "18/02/2024": [29, 96, 49, 55, 72, 14, "07", 38, 63, 81, 20, 52],
  },
  {
    "19/02/2024": ["08", 59, 50, 13, 49, 42, 99, 86, 68, 21, 37, 74],
  },
  {
    "20/02/2024": [56, 32, 25, 14, "07", 99, 41, 62, 70, 87, 37, 50],
  },
  {
    "21/02/2024": [29, 94, 36, 47, 10, "08", 67, 51, 72, 83, 16, 28],
  },
  {
    "22/02/2024": [53, 92, "06", 64, 10, 82, 74, 21, 43, "06", 36, 11],
  },

  {
    "23/02/2024": [38, "07", 87, 60, 94, 26, 15, 99, 41, 52, 84, 30],
  },
  {
    "24/02/2024": [17, "01", 95, 56, "07", 92, 88, 24, 11, 33, "04", 35],
  },
  {
    "25/02/2024": [25, 71, 16, "03", 57, 39, 40, 62, 99, 26, 75, "**"],
  },
  {
    "26/02/2024": [46, "02", 26, 94, 18, 72, "05", 91, 33, 55, 75, 66],
  },

  {
    "27/02/2024": ["03", 76, 94, 26, 41, 98, 62, 36, 33, 55, 81, "08"],
  },
  {
    "28/02/2024": [36, 83, 95, 13, 42, "02", 71, 35, 21, 30, 62, 55],
  },
  {
    "29/02/2024": [25, 92, "02", 28, 41, 36, 71, 54, 31, 62, 80, 95],
  },
  {
    "01/03/2024": [90, "01", 54, 36, 42, 86, 13, 30, 21, 65, 27, 79],
  },
  {
    "02/03/2024": [13, 87, 76, 54, "08", 92, 29, 30, 11, 65, 48, 50],
  },
  {
    "03/03/2024": [15, 21, "02", 96, 30, 43, 54, 62, 81, 27, 78, 99],
  },
  {
    "04/03/2024": [18, "02", 26, 86, 68, 30, 11, 98, 27, 98, 82, 69],
  },
  {
    "05/03/2024": ["02", 54, 20, 86, 96, 64, 11, 22, 36, 45, "06", 99],
  },
  {
    "06/03/2024": [99, "08", 81, 69, 14, 24, 22, 88, 31, 47, 50, "02"],
  },
  {
    "07/03/2024": [25, 40, 19, "01", 31, 79, 53, 60, 88, 96, 13, 91],
  },
  {
    "08/03/2024": ["01", 12, 27, 34, 43, 55, 76, 63, 31, 88, "09", 91],
  },
  {
    "09/03/2024": [40, 80, 83, 65, 36, 96, 52, 77, 51, 92, "03", 22],
  },
  {
    "10/03/2024": [13, "06", 78, 85, 77, 94, 66, "01", 16, 27, 33, 48],
  },
  {
    "11/03/2024": [89, "02", 21, 11, 66, 27, 30, 48, 84, 71, 13, 61],
  },
  {
    "12/03/2024": [10, 21, 44, 62, 30, 93, 68, 61, 55, 11, "02", 19],
  },
  {
    "13/03/2024": [99, 33, 20, "04", 38, 45, 82, 51, 66, 90, 72, 57],
  },
  {
    "14/03/2024": [93, 59, 53, 62, 10, 81, 77, 89, 33, 25, 70, "01"],
  },
  {
    "15/03/2024": [97, "01", 80, 15, 23, 62, 48, 51, 94, 36, 59, 73],
  },
  {
    "16/03/2024": ["04", 50, "09", 56, 15, 19, 30, 62, 67, 11, 47, 81],
  },
  {
    "17/03/2024": [96, 99, 31, "03", 13, 22, 16, 50, 75, 47, 74, 65],
  },
  {
    "18/03/2024": [92, 18, 31, 79, 48, 87, 65, 83, 41, 73, 49, 98],
  },
  {
    "19/03/2024": ["02", 80, 93, 31, 52, 45, 66, 78, 88, 11, 39, 99],
  },
  {
    "20/03/2024": [24, 40, 79, 92, 67, 36, 11, 50, 97, 85, 61, 54],
  },
  {
    "21/03/2024": ["03", 35, 69, 12, 78, 85, 90, 27, 61, 44, 53, 38],
  },
  {
    "22/03/2024": [93, 51, 80, 68, 25, "07", 48, 39, 88, 99, 17, 62],
  },
  {
    "23/03/2024": ["02", 28, "07", 84, 62, "04", 78, 36, 90, 73, 91, 42],
  },
  {
    "24/03/2024": [75, 18, 39, 44, 59, 24, 90, 13, 67, 23, "05", 79],
  },
  {
    "25/03/2024": ["01", 47, 73, 50, 31, 69, 26, 82, 54, 70, 64, 35],
  },
  {
    "26/03/2024": [19, 52, "02", 75, 91, 86, 37, 74, 42, 58, 96, 28],
  },
  {
    "27/03/2024": [29, 70, 85, 25, "08", 44, 96, 68, 77, 82, 78, 45],
  },
  {
    "28/03/2024": [31, 91, "01", 45, 75, 65, 31, 24, 27, "07", 96, 42],
  },
  {
    "29/03/2024": [64, 15, 56, 24, 97, 74, 43, "03", 80, 53, 10, 64],
  },
  {
    "30/03/2024": [13, 72, 40, "03", 57, 14, 89, 61, 27, 81, 99, 53],
  },
  {
    "31/03/2024": ["07", 36, 31, 45, 73, 14, 25, 56, 18, 59, 54, 94],
  },
  {
    "01/04/2024": [61, 53, 95, 37, 22, 86, 78, 49, 11, 51, 38, 40],
  },

  {
    "02/04/2024": [25, 73, 47, 63, 55, 97, "07", 97, 81, 18, 49, 22],
  },
  {
    "03/04/2024": [64, "03", 74, 10, 53, 44, 29, 58, 69, 17, 89, 70],
  },
  {
    "04/04/2024": [86, 90, 53, 74, 25, "09", 37, 45, 10, 64, 80, 27],
  },
  {
    "05/04/2024": [63, 14, 37, 92, 20, 48, 52, 99, 20, 14, 69, 50],
  },
  {
    "06/04/2024": [93, 22, 77, 72, 61, 48, 40, 94, 16, 36, 31, 84],
  },
  {
    "07/04/2024": [58, 80, "04", 30, 12, 93, 78, 29, "05", 44, 94, 19],
  },
  {
    "08/04/2024": [61, 24, 43, 16, "02", 38, 84, 14, 55, 97, 19, "08"],
  },
  {
    "09/04/2024": [43, 35, 98, 60, 81, 24, 32, 96, 79, 87, 45, 18],
  },
  {
    "10/04/2024": [72, 18, 88, 56, 94, 49, 63, 14, 42, 22, 75, 69],
  },
  {
    "11/04/2024": ["04", 67, 72, "04", 39, 51, 83, 48, 26, 54, 36, 62],
  },
  {
    "12/04/2024": [46, 97, 34, 72, 15, 80, "02", 95, 52, 98, 70, 89],
  },
  {
    "13/04/2024": [56, 81, 17, 92, 45, 79, 66, 13, 69, 17, "01", 47],
  },
  {
    "14/04/2024": [42, 63, 94, "07", 27, 52, 47, 24, 90, "09", 30, 69],
  },
  {
    "15/04/2024": [27, 99, 34, 86, 24, 58, 39, 13, 77, 92, 89, 96],
  },
  {
    "16/04/2024": ["08", 48, 32, 35, 21, 95, 25, 61, 38, 78, 54, 57],
  },
  {
    "17/04/2024": [55, 28, 20, 73, 89, "04", "07", 93, 83, 61, 98, 49],
  },
  {
    "18/04/2024": [59, 98, 26, 44, 43, 65, 89, 34, 75, 39, 20, 83],
  },
  {
    "19/04/2024": [98, 83, 36, 54, 18, 23, 17, 40, 31, "05", 50, 48],
  },
  {
    "20/04/2024": [16, 67, 79, "07", 91, 64, 97, 51, 18, 80, 76, 47],
  },
  {
    "21/04/2024": [19, "03", 12, 74, 89, 28, 15, 61, 72, 32, 37, "08"],
  },
  {
    "22/04/2024": [92, 57, 27, 50, 46, 41, 39, 96, 70, 79, 68, 82],
  },
  {
    "23/04/2024": [85, 41, 21, 96, 39, 60, 25, "02", 74, 37, 14, 78],
  },
  {
    "24/04/2024": ["06", 53, 70, 23, 29, 12, 98, 41, 84, 86, 35, "09"],
  },
  {
    "25/04/2024": [94, 33, 13, 38, 79, "03", 63, 11, 81, 52, 26, 42],
  },
  {
    "26/04/2024": [67, 24, 80, 30, 46, 51, 93, 97, 86, 62, 16, "01"],
  },
  {
    "27/04/2024": [43, 91, 76, 83, 64, 90, 49, 15, 12, 79, "07", 94],
  },
  {
    "28/04/2024": [28, 14, 67, 86, 40, "03", 79, 33, 12, 60, 71, 36],
  },
  {
    "29/04/2024": [27, 12, 72, 31, 74, 40, 44, 56, 87, 94, 15, "08"],
  },
  {
    "30/04/2024": ["09", 44, 92, 65, 52, 49, 69, 20, 37, 10, 16, 81],
  },
  {
    "01/05/2024": [55, "02", 54, 60, 29, 76, 98, 37, 19, 26, 47, 36],
  },
  {
    "02/05/2024": [76, 52, 25, 38, 96, 95, "05", 59, 40, 68, 67, "04"],
  },
  {
    "03/05/2024": ["06", 57, 82, 53, 85, 35, 17, 69, 67, 13, 14, 70],
  },
  {
    "04/05/2024": [17, "06", 96, 82, 25, 38, 85, 69, 13, 46, 30, 57],
  },
  {
    "05/05/2024": [84, 62, 36, "01", 91, 93, 15, 78, 59, 41, 43, 73],
  },
  {
    "06/05/2024": [49, 72, 50, 52, 96, 26, 18, 80, 41, 88, 23, 34],
  },
  {
    "07/05/2024": [63, 94, 71, 92, 32, 26, 63, 50, 33, 85, "03", 42],
  },
  {
    "08/05/2024": [16, "08", 48, 69, "09", 80, 16, 28, 70, 10, 53, 37],
  },
  {
    "09/05/2024": [68, 21, 58, "05", 34, 12, 93, 29, 55, 42, 36, 62],
  },
  {
    "10/05/2024": ["06", 77, 56, 90, 89, 22, 29, 91, 35, 64, 51, 24],
  },
  {
    "11/05/2024": [30, 21, 61, 57, 84, 97, 31, "07", 56, "02", 74, 75],
  },
  {
    "12/05/2024": [70, 94, 50, 33, 81, "06", 97, 59, 31, 26, 19, 22],
  },
  {
    "04/05/2024": [17, "06", 96, 82, 25, 38, 85, 69, 13, 46, 30, 57],
  },
  {
    "05/05/2024": [84, 62, 36, "01", 91, 93, 15, 78, 59, 41, 43, 73],
  },
  {
    "06/05/2024": [49, 72, 50, 52, 96, 26, 18, 80, 41, 88, 23, 34],
  },
  {
    "07/05/2024": [63, 94, 71, 92, 32, 26, 63, 50, 33, 85, "03", 42],
  },
  {
    "08/05/2024": [16, "08", 48, 69, "09", 80, 16, 28, 70, 10, 53, 37],
  },
  {
    "09/05/2024": [68, 21, 58, "05", 34, 12, 93, 29, 55, 42, 36, 62],
  },
  {
    "10/05/2024": ["06", 77, 56, 90, 89, 22, 29, 91, 35, 64, 51, 24],
  },
  {
    "11/05/2024": [30, 21, 61, 57, 84, 97, 31, "07", 56, "02", 74, 75],
  },
  {
    "12/05/2024": [70, 94, 50, 33, 81, "06", 97, 59, 31, 26, 19, 22],
  },
  {
    "13/05/2024": [20, "01", 43, 13, 56, 62, 83, 39, 86, 90, 38, 11],
  },
  {
    "14/05/2024": ["08", 11, 85, 75, 68, 81, 37, 92, 95, 18, 23, 99],
  },
  {
    "15/05/2024": [41, 19, 60, 73, 93, 98, 21, 52, 18, 87, 30, 76],
  },
  {
    "16/05/2024": [36, 75, 58, 28, 20, 95, "04", 94, 45, 97, 12, 64],
  },
  {
    "17/05/2024": [71, 16, 40, 63, 62, 14, 89, "05", "03", 46, 74, 27],
  },
  {
    "18/05/2024": [93, 11, 88, 80, 13, 44, 66, "07", 48, 23, "01", 25],
  },
  {
    "19/05/2024": [49, 97, 68, "05", 82, 17, 84, 54, 26, 20, 41, 65],
  },
  {
    "20/05/2024": ["08", 90, 51, 83, 19, 87, 37, 74, 67, 66, 34, "04"],
  },
  {
    "21/05/2024": [58, 16, 25, 96, 72, 14, 12, "04", 31, 81, 30, 65],
  },
  {
    "22/05/2024": [50, 30, 88, 20, "03", 37, 42, 43, 93, 13, "01", 76],
  },
  {
    "23/05/2024": [38, 85, 17, 69, 71, 24, 18, 79, 51, 53, 15, 23],
  },
  {
    "24/05/2024": [29, 90, 49, 83, 73, 86, 64, 82, 52, 48, "08", 58],
  },
  {
    "25/05/2024": [89, 31, 19, 94, 55, 61, 27, "09", 26, 96, 78, 39],
  },
  {
    "26/05/2024": ["02", 10, 92, 37, 57, 40, 35, 21, 88, "08", 65, 69],
  },
  {
    "27/05/2024": [16, 99, "05", 46, 20, 71, 68, 51, 91, 68, 70, 36],
  },
  {
    "28/05/2024": [94, 29, 59, "07", 62, 82, 45, 32, 75, 14, 93, 24],
  },
  {
    "29/05/2024": [35, 98, 58, 83, 27, 73, 19, 76, 30, 21, 43, "01"],
  },
  {
    "30/05/2024": [71, 59, 12, 95, "08", "02", 34, 28, 31, 15, 21, 84],
  },
  {
    "31/05/2024": [22, 98, 58, 74, 62, 36, 63, 50, "05", 24, 90, "06"],
  },
  {
    "01/06/2024": [96, 18, 50, 10, 73, 11, 64, 55, "02", 13, 66, 52],
  },
  {
    "02/06/2024": ["03", 60, 72, 48, 54, 49, 20, 11, 80, 87, 63, 57],
  },
  {
    "03/06/2024": [90, 75, 42, 36, "07", 67, 91, 59, 69, 76, 24, 26],
  },
  {
    "04/06/2024": [34, "01", 98, 87, 11, 89, 49, 28, 71, 51, 68, 40],
  },
  {
    "05/06/2024": [10, 41, 32, 25, 56, 36, 53, 76, 97, 92, 23, 79],
  },
  {
    "06/06/2024": ["05", 15, 37, 97, 48, 66, 13, 61, 53, 81, 58, 94],
  },
  {
    "07/06/2024": [60, 24, 70, 52, 34, "04", 95, 72, 10, 84, 83, 38],
  },
  {
    "08/06/2024": [27, "06", 65, 83, 16, 55, 56, 91, 35, 28, 59, 67],
  },
  {
    "09/06/2024": [37, 20, 19, "08", 99, 34, 89, 61, 52, 28, 50, 13],
  },
  {
    "10/06/2024": [23, 11, 84, 62, 49, 96, 85, 76, 35, 91, 33, 21],
  },
  {
    "11/06/2024": [26, 66, 48, 82, "07", 53, 98, 63, 12, "03", 58, 39],
  },
  {
    "12/06/2024": [20, 60, 93, 12, 59, 37, 83, 88, 96, 14, "03", 42],
  },
  {
    "13/06/2024": [39, 66, "01", 23, 71, 29, 51, 41, 43, 86, 46, 27],
  },
  {
    "14/06/2024": ["09", 55, 27, 97, 73, 87, 65, 83, 64, 49, 61, 57],
  },
  {
    "15/06/2024": [79, 22, 96, 25, "05", 60, 51, "02", 28, 99, 72, 37],
  },
  {
    "16/06/2024": ["08", 43, 81, 39, 98, 21, 62, 29, 80, 70, 74, 32],
  },
  {
    "17/06/2024": [15, 93, 30, 59, 58, 42, 94, 78, 29, 14, 26, 87],
  },
  {
    "18/06/2024": [38, 75, 42, 21, 96, 47, "04", 60, 31, 86, 92, 61],
  },
  {
    "19/06/2024": [56, 83, 72, 17, 30, 39, 67, 21, 68, 78, 71, 30],
  },
  {
    "20/06/2024": [23, 48, 12, 52, 13, 89, 92, 26, 84, 30, 93, 64],
  },
  {
    "21/06/2024": [37, 10, 50, 24, 76, 46, 61, 20, 83, "09", 15, 91],
  },
  {
    "22/06/2024": [75, 97, 31, 79, 51, 84, 59, 49, 18, 45, "03", 28],
  },
  {
    "23/06/2024": ["04", 69, 67, 21, "01", 24, 53, 89, 90, 10, 29, 57],
  },
  {
    "24/06/2024": [94, 15, 58, 79, 27, 26, 60, 18, "06", 62, 35, 86],
  },
  {
    "25/06/2024": [98, 17, 76, 24, 34, 69, "08", 82, 64, 81, "07", 32],
  },
  {
    "26/06/2024": [15, 72, 21, 96, 54, 93, 37, 65, 89, 16, 63, 73],
  },
  {
    "27/06/2024": [11, 79, 30, 85, 45, "02", 48, 25, 94, "06", 58, 42],
  },
  {
    "28/06/2024": [98, "05", 54, 70, 13, 18, 85, 31, 17, 23, 36, 74],
  },
  {
    "29/06/2024": [71, 16, 22, 29, 80, 79, 76, "06", 26, 16, 96, 91],
  },
  {
    "30/06/2024": [26, 32, 62, 45, 93, 78, 25, 21, "01", 56, 34, 49],
  },
  {
    "01/07/2024": [59, 23, 78, 81, "03", 89, 37, 96, 38, 14, 12, 76],
  },
  {
    "02/07/2024": [93, 10, 87, 41, 53, 52, 74, 39, 76, "09", 57, 21],
  },
  {
    "03/07/2024": ["06", 34, 12, 89, 63, 47, 20, 40, 92, 13, 57, 90],
  },
  {
    "04/07/2024": [67, 45, 94, 39, 70, 54, 87, 82, 21, 81, 62, "07"],
  },
  {
    "05/07/2024": [46, 81, 42, 61, 30, 60, 21, "02", 41, 64, 24, 32],
  },
  {
    "06/07/2024": [35, 24, 11, 40, 57, 69, 74, 84, 68, "04", "03", 38],
  },
  {
    "07/07/2024": ["08", 83, "01", 44, 43, 61, 60, 85, 41, 35, 94, 31],
  },
  {
    "08/07/2024": [31, 36, 46, 14, "04", 58, "01", 67, 81, "06", 70, 85],
  },
  {
    "09/07/2024": [49, 32, 60, 16, 51, 34, 56, 11, 10, 79, 69, 27],
  },
  {
    "10/07/2024": [19, 64, 21, 46, 26, 90, 81, 85, 51, 20, "07", 43],
  },
  {
    "11/07/2024": [42, "02", 78, 98, 19, 72, 84, 35, 51, 40, 37, "06"],
  },
  {
    "12/07/2024": [44, 90, 62, 57, "03", 59, 45, 87, 38, 53, 67, 24],
  },
  {
    "13/07/2024": [84, 95, 42, 51, "01", 71, 50, 58, 72, 59, "06", 76],
  },
  {
    "14/07/2024": [79, 28, 13, 94, 65, 87, 96, 37, "09", 83, 20, 43],
  },
  {
    "15/07/2024": [93, 54, 61, 85, 53, 23, 19, 41, 17, 30, 47, 96],
  },
  {
    "16/07/2024": [15, "04", 35, 74, 91, 63, 46, 69, 27, 43, 10, 64],
  },
  {
    "17/07/2024": [60, 71, 49, 32, 27, "04", 92, 61, 56, 68, 45, 86],
  },
  {
    "18/07/2024": [59, 97, 21, 57, 15, 33, 73, 75, 69, "09", 45, 60],
  },
  {
    "19/07/2024": [68, 28, 80, 68, 93, 75, 44, "04", 73, 16, 95, 64],
  },
  {
    "20/07/2024": [95, 23, 71, 71, 36, 68, 27, 34, 29, 89, 76, 35],
  },
  {
    "21/07/2024": [98, 21, 66, 24, 38, 11, 37, "08", 76, 27, 49, 72],
  },
  {
    "22/07/2024": [83, 70, 92, 67, "06", 17, 31, 50, 10, 13, 29, 17],
  },
  {
    "23/07/2024": [57, 39, 13, 29, 86, 75, "06", "07", 19, 26, 87, 69],
  },
  {
    "24/07/2024": [61, 86, 84, 39, 60, 72, 63, 32, 20, "03", 39, 78],
  },
  {
    "25/07/2024": [15, 47, 54, 59, 46, "03", 56, 69, 21, 27, 96, 81],
  },
  {
    "26/07/2024": [68, 41, 23, 58, 87, 94, 34, "05", 32, 87, 28, 17],
  },
  {
    "27/07/2024": [12, 62, 69, 13, 59, 21, 11, 18, 57, "03", 68, 39],
  },
  {
    "28/07/2024": [34, 67, 44, 21, 60, 98, 93, 73, 26, 73, 41, "02"],
  },
  {
    "29/07/2024": [94, 26, 32, 78, 88, 45, 52, 40, 8, 73, 1, 17],
  },
  {
    "30/07/2024": [46, 0, 44, 77, 11, 55, 67, 11, 25, 39, 70, 87],
  },
  {
    "31/07/2024": [58, 92, 19, 26, 35, 74, 43, 67, 30, 17, 93, 5],
  },
  { "01/08/2024": [71, 27, "05", 12, 93, 64, 40, 83, 31, 19, 57, 22] },
  { "02/08/2024": [23, 79, 35, "06", 57, 12, 68, 46, 81, 30, 95, 55] },
  { "03/08/2024": ["09", 81, 57, 10, 42, 63, 54, 35, 77, 26, 49, 82] },
  { "04/08/2024": [71, 36, 12, 94, 65, 43, "07", 31, 75, 29, 11, 58] },
  {
    "05/08/2024": [27, 84, 56, 91, "02", 73, 48, 39, 11, 63, 75, 97],
  },
  {
    "06/08/2024": [78, 53, 90, 64, 31, 92, 27, 15, 88, 39, 42, 70],
  },
  {
    "07/08/2024": [45, 79, 23, "09", 67, 10, 81, 32, 51, 26, 95, 12],
  },
  {
    "08/08/2024": [74, 29, 56, "07", 82, 43, 19, 34, 60, 47, 31, 98],
  },
  {
    "09/08/2024": [46, 79, 30, 23, 91, 55, 17, 64, 82, 69, 72, "05"],
  },
  {
    "10/08/2024": [21, 54, 38, "07", 85, 71, 65, 11, 42, 51, 36, "09"],
  },
  {
    "11/08/2024": [79, 38, 15, 47, 63, 99, 26, 54, 81, 42, 29, "05"],
  },
  {
    "12/08/2024": [57, 14, 36, 92, 25, 70, 63, 48, "06", 85, 75, 30],
  },
  {
    "13/08/2024": [11, 86, 72, 55, 47, "05", 84, 25, 93, 60, 35, 42],
  },
  {
    "14/08/2024": [21, 85, 10, 73, 44, 32, 70, 92, 63, 19, 56, 37],
  },
  {
    "15/08/2024": [62, 39, 45, 13, 27, 74, 89, 13, "02", 96, 58, 40],
  },
  {
    "16/08/2024": [26, 62, 90, 19, "06", 49, 77, 67, 98, 43, 82, 62],
  },
  {
    "17/08/2024": [90, 44, 84, 14, 19, 64, 22, 14, 36, 28, 32, 61],
  },
  {
    "18/08/2024": [69, 75, 82, 71, 92, 30, 78, 37, 74, 34, 65, 93],
  },
  {
    "19/08/2024": [56, 32, 47, 43, 86, 90, 54, "05", 75, 60, 76, 93],
  },
  {
    "20/08/2024": [15, 11, 93, 10, 81, 38, 82, 56, 66, 13, 68, 69],
  },
  {
    "21/08/2024": [78, 17, "05", 31, 20, "04", 36, 81, "04", 30, 83, 74],
  },
  {
    "22/08/2024": [75, "09", 82, 89, 22, 84, 22, 49, 68, 22, 89, 28],
  },
  {
    "23/08/2024": [35, 89, 63, 47, 72, 24, 30, 98, 44, 71, 21, "06"],
  },
  {
    "24/08/2024": [21, 55, 79, 18, "02", 46, 37, 59, 13, 26, "00", 72],
  },
  {
    "25/08/2024": [51, 76, 34, 11, 69, 90, 23, 71, 37, 82, 44, "07"],
  },
  {
    "26/08/2024": [75, 27, 28, 59, 28, 10, 80, 95, 35, 27, 31, 67],
  },
  {
    "27/08/2024": [51, 63, 90, 18, 32, 25, 40, 35, 88, 72, 49, 93],
  },
  {
    "28/08/2024": [11, 25, 46, 68, 70, 35, 67, 99, 58, "02", 39, 93],
  },
  {
    "29/08/2024": [51, 35, 28, 44, 73, 60, "07", 11, 26, 50, 57, 45],
  },
  {
    "30/08/2024": [21, 67, 33, 78, 16, 25, "03", 12, 85, 57, "00", 19],
  },
  {
    "31/08/2024": [7, 69, 41, 92, 10, 71, 83, 25, 56, 39, 47, 57],
  },
  {
    "01/09/2024": [79, 13, 32, "04", 88, 20, 34, 19, 25, 60, 66, 39],
  },
  {
    "02/09/2024": [57, 15, "07", 22, 38, 74, 96, 66, 41, 17, 83, 25],
  },
  {
    "03/09/2024": [67, 91, 13, "02", 33, 84, 47, 79, 21, 72, 23, 56],
  },
  {
    "04/09/2024": [19, 82, 43, 75, 17, 99, "06", 50, 30, 72, 66, 20],
  },
  {
    "05/09/2024": [82, 73, 61, "00", 93, 27, 39, 11, 46, 99, "04", 64],
  },
  {
    "06/09/2024": [80, 10, "01", 63, 71, 39, 28, 77, 95, 46, 69, 51],
  },
  {
    "07/09/2024": [44, 68, 26, 92, 66, 86, 19, 53, 30, 69, 78, 62],
  },
  {
    "08/09/2024": [65, 22, 87, 56, 31, 70, 43, 94, 18, 89, 53, 48],
  },
  {
    "09/09/2024": [29, 58, 97, 42, 65, 12, "03", 36, 79, 40, 81, "09"],
  },
  {
    "10/09/2024": [37, 27, 94, 85, 10, 64, 45, 52, 76, "08", 92, 16],
  },
  {
    "11/09/2024": [75, 54, 67, 48, "05", 29, 35, 83, 59, 38, 11, 84],
  },
  {
    "12/09/2024": [14, 88, 96, 32, 73, 57, 49, 24, 61, "06", 34, 74],
  },
  {
    "13/09/2024": [44, 21, 55, 63, 82, 13, 98, "02", 39, 78, 60, 86],
  },
  {
    "14/09/2024": [62, 17, 99, "01", 93, 51, 25, 97, 93, 87, 36, 45],
  },
  {
    "15/09/2024": [31, 89, 41, 23, 79, 15, 66, 90, "07", 53, 42, 28],
  },
  {
    "16/09/2024": [83, "04", 37, 68, 91, 47, 26, 52, 75, 46, 19, 9],
  },
  {
    "17/09/2024": [27, 71, 12, 57, 35, "08", 94, 43, 84, 69, 54, 18],
  },
  {
    "18/09/2024": [16, 38, 49, 95, 92, 56, 78, 29, 63, 81, "01", 64],
  },
  {
    "19/09/2024": [65, "06", 25, 13, 33, "02", 86, 97, 58, 73, 45, 21],
  },
  {
    "20/09/2024": [32, 96, 74, 59, 48, 91, 83, 96, 41, "03", 61, 34],
  },
  {
    "21/09/2024": [85, 24, 80, 14, 39, 62, "04", 93, 99, 51, 46, 11],
  },
  {
    "22/09/2024": ["05", 72, 53, 98, 43, 25, 67, 30, 16, 23, 68, 57],
  },
  {
    "23/09/2024": [17, 69, 20, 31, 85, 18, 42, 71, "08", 52, "06", 94],
  },
  {
    "24/09/2024": [48, "02", 61, 45, 74, 36, 64, 22, 54, 91, 70, 82],
  },
  {
    "25/09/2024": [81, 73, 79, 56, 36, 12, 95, "01", 44, 63, 24, 32],
  },
  {
    "26/09/2024": [92, "05", 13, 84, 23, 70, 66, 57, 30, 25, 71, "03"],
  },
  {
    "27/09/2024": [27, 91, 34, 21, 88, 52, 16, 74, 62, 93, "04", 41],
  },
  {
    "28/09/2024": [14, 94, 82, 56, 99, 75, 31, "06", 18, 76, 65, 89],
  },
  {
    "29/09/2024": [42, 74, 13, 76, 48, 61, 40, 83, 95, "04", 49, 44],
  },
  {
    "30/09/2024": [51, 26, 78, "07", 86, 32, 69, 10, 83, 94, "05", 69],
  },
  // {
  //   "01/10/2024": [53, 85, 93, 43, 24, "07", 15, 36, 29, 67, 70, 17],
  // },
  // {
  //   "02/10/2024": [89, 41, 25, 71, 35, 60, 92, 13, "02", 54, 35, 95],
  // },
  // {
  //   "03/10/2024": [12, 67, 16, 63, 47, 42, 85, 51, 33, "03", 27, 79],
  // },
  // {
  //   "04/10/2024": [62, "01", 69, 48, 96, 81, 88, 86, 21, 74, 37, 13],
  // },
  // {
  //   "05/10/2024": [43, 56, 89, '04', 61, 72, 19, 34, 57, 93, 75, '02'],
  // },
  // {
  //   "06/10/2024": [24, 97, 34, 76, 40, 17, 58, 66, 55, 62, '08', 27],
  // },
  // {
  //   "07/10/2024": ['07', 42, 25, 45, 68, 54, 89, 92, 31, 73, 45, 16],
  // },
  // {
  //   "08/10/2024": [45, 27, 60, 23, 10, 96, 47, 50, 19, 79, '06', 38],
  // },
  // {
  //   "09/10/2024": [38, 86, 52, '09', 71, 25, 93, 12, 67, '02', 34, 74],
  // },
  // {
  //   "10/10/2024": [13, 95, 64, 82, '01', 56, 99, 75, 38, 29, 16, 42],
  // },
  // {
  //   "05/10/2024": [43, 56, 89, '04', 61, 72, 19, 34, 57, 93, 75, '02'],
  // },
  // {
  //   "06/10/2024": [24, 97, 34, 76, 40, 17, 58, 66, 55, 62, '08', 27],
  // },
  // {
  //   "07/10/2024": ['07', 42, 25, 45, 68, 54, 89, 92, 31, 73, 45, 16],
  // },
  // {
  //   "08/10/2024": [45, 27, 60, 23, 10, 96, 47, 50, 19, 79, '06', 38],
  // },
  // {
  //   "09/10/2024": [38, 86, 52, '09', 71, 25, 93, 12, 67, '02', 34, 74],
  // },
  // {
  //   "10/10/2024": [13, 95, 64, 82, '01', 56, 99, 75, 38, 29, 16, 42],
  // },
];
