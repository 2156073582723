
export const redJodi = [
  "11",
  "16",
  "61",
  "66",
  "22",
  "27",
  "77",
  "72",
  "38",
  "83",
  "88",
  "33",
  "50",
  "55",
  "00",
  "05",
  "49",
  "94",
  "99",
  "44",
];

export const redPana = [
  "000",
  "111",
  "222",
  "333",
  "444",
  "555",
  "666",
  "777",
  "888",
  "999",
];

