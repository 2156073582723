import React from "react";
import Chart_Container from "./Chart_Container";
const Shree_Jackpot = (props) => {
  return (
   
      <Chart_Container {...props}  responsive_Class={"col-xxl-12 col-xl-12  col-md-12 "}/>

  );
};

export default Shree_Jackpot;
