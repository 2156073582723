import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";


export const PagesIndex = {
Link,
useEffect,
useState,
useNavigate,
useLocation
}