export const One = {
  "01/01/2024 to 07/01/2024": [
    ["235", "0"],
    ["456", "5"],
    ["569", "0"],
    ["456", "5"],
    ["779", "3"],
    ["366", "5"],
    ["900", "9"],
  ],
  "08/01/2024 to 14/01/2024": [
    ["458", "7"],
    ["115", "7"],
    ["247", "3"],
    ["445", "3"],
    ["156", "2"],
    ["169", "6"],
    ["125", "8"],
  ],
  "15/01/2024 to 21/01/2024": [
    ["789", "4"],
    ["159", "5"],
    ["236", "1"],
    ["789", "4"],
    ["489", "1"],
    ["159", "5"],
    ["469", "9"],
  ],
  "22/01/2024 to 28/01/2024": [
    ["577", "9"],
    ["228", "2"],
    ["120", "3"],
    ["157", "3"],
    ["689", "3"],
    ["289", "9"],
    ["489", "1"],
  ],
  "29/01/2024 to 04/02/2024": [
    ["290", "1"],
    ["567", "8"],
    ["345", "2"],
    ["379", "9"],
    ["345", "2"],
    ["589", "2"],
    ["568", "9"],
  ],
  "05/02/2024 to 11/02/2024": [
    ["129", "2"],
    ["110", "2"],
    ["890", "7"],
    ["189", "8"],
    ["226", "0"],
    ["259", "6"],
    ["146", "1"],
  ],
  "12/02/2024 to 18/02/2024": [
    ["890", "7"],
    ["249", "5"],
    ["357", "5"],
    ["135", "9"],
    ["689", "3"],
    ["367", "6"],
    ["250", "7"],
  ],
  "19/02/2024 to 25/02/2024": [
    ["458", "7"],
    ["578", "0"],
    ["359", "7"],
    ["129", "2"],
    ["890", "7"],
    ["456", "5"],
    ["199", "9"],
  ],
  "26/02/2024 to 03/03/2024": [
    ["147", "2"],
    ["148", "3"],
    ["569", "0"],
    ["140", "5"],
    ["236", "1"],
    ["238", "3"],
    ["260", "8"],
  ],
  "04/03/2024 to 10/03/2024": [
    ["145", "0"],
    ["248", "4"],
    ["169", "6"],
    ["180", "9"],
    ["359", "7"],
    ["467", "7"],
    ["199", "9"],
  ],
  "11/03/2024 to 17/03/2024": [
    ["800", "8"],
    ["146", "1"],
    ["179", "7"],
    ["126", "9"],
    ["890", "7"],
    ["125", "8"],
    ["117", "9"],
  ],
  "18/03/2024 to 24/03/2024": [
    ["589", "2"],
    ["569", "0"],
    ["247", "3"],
    ["477", "8"],
    ["568", "9"],
    ["568", "9"],
    ["258", "5"],
  ],
  "25/03/2024 to 31/03/2024": [
    ["367", "6"],
    ["288", "8"],
    ["448", "6"],
    ["346", "3"],
    ["347", "4"],
    ["359", "7"],
    ["679", "2"],
  ],
  "04/01/2024 to 04/07/2024": [
    ["559", "9"],
    ["149", "4"],
    ["134", "8"],
    ["349", "6"],
    ["369", "8"],
    ["134", "8"],
    ["447", "5"],
  ],
  "08/04/2024 to 14/04/2024": [
    ["228", "2"],
    ["269", "7"],
    ["168", "5"],
    ["260", "8"],
    ["225", "9"],
    ["223", "7"],
    ["678", "1"],
  ],
  "15/04/2024 to 21/04/2024": [
    ["334", "0"],
    ["237", "2"],
    ["259", "6"],
    ["225", "9"],
    ["356", "4"],
    ["550", "0"],
    ["458", "7"],
  ],
  "22/04/2024 to 28/04/2024": [
    ["247", "3"],
    ["168", "5"],
    ["245", "1"],
    ["370", "0"],
    ["455", "4"],
    ["168", "5"],
    ["150", "6"],
  ],
  "29/04/2024 to 05/05/2024": [
    ["258", "5"],
    ["467", "7"],
    ["778", "2"],
    ["356", "4"],
    ["366", "5"],
    ["247", "3"],
    ["260", "8"],
  ],
  "06/05/2024 to 12/05/2024": [
    ["350", "8"],
    ["137", "1"],
    ["139", "3"],
    ["458", "7"],
    ["250", "7"],
    ["689", "3"],
    ["450", "9"],
  ],
  "13/05/2024 to 19/05/2024": [
    ["157", "3"],
    ["356", "4"],
    ["346", "3"],
    ["680", "4"],
    ["250", "7"],
    ["226", "0"],
    ["150", "6"],
  ],
  "20/05/2024 to 26/05/2024": [
    ["300", "3"],
    ["680", "4"],
    ["270", "9"],
    ["349", "6"],
    ["579", "1"],
    ["270", "9"],
    ["470", "1"],
  ],
  "27/05/2024 to 02/06/2024": [
    ["223", "7"],
    ["479", "0"],
    ["358", "6"],
    ["149", "4"],
    ["290", "1"],
    ["246", "2"],
    ["358", "6"],
  ],
  "03/06/2024 to 09/06/2024": [
    ["113", "5"],
    ["440", "8"],
    ["170", "8"],
    ["238", "3"],
    ["116", "8"],
    ["124", "7"],
    ["689", "3"],
  ],
  "10/06/2024 to 16/06/2024": [
    ["579", "1"],
    ["160", "7"],
    ["339", "5"],
    ["169", "6"],
    ["890", "7"],
    ["689", "3"],
    ["147", "2"],
  ],
  "17/06/2024 to 23/06/2024": [
    ["158", "4"],
    ["228", "2"],
    ["135", "9"],
    ["129", "2"],
    ["490", "3"],
    ["780", "5"],
    ["380", "1"],
  ],
  "24/06/2024 to 30/06/2024": [
    ["679", "2"],
    ["237", "2"],
    ["790", "6"],
    ["599", "3"],
    ["380", "1"],
    ["169", "6"],
    ["168", "5"],
  ],
  "01/07/2024 to 07/07/2024": [
    ["225", "9"],
    ["278", "7"],
    ["350", "8"],
    ["580", "3"],
    ["249", "5"],
    ["558", "8"],
    ["470", "1"],
  ],
  "08/07/2024 to 14/07/2024": [
    ["780", "5"],
    ["233", "8"],
    ["259", "6"],
    ["133", "7"],
    ["356", "4"],
    ["290", "1"],
    ["223", "7"],
  ],
  "15/07/2024 to 21/07/2024": [
    ["134", "8"],
    ["367", "6"],
    ["266", "4"],
    ["236", "1"],
    ["355", "3"],
    ["258", "5"],
    ["779", "3"],
  ],
  "22/07/2024 to 28/07/2024": [
    ["567", "8"],
    ["126", "9"],
    ["268", "6"],
    ["678", "1"],
    ["350", "8"],
    ["267", "5"],
    ["345", "2"],
  ],
  "29/07/2024 to 04/08/2024": [
    ["260", "8"],
    ["347", "4"],
    ["149", "4"],
    ["580", "3"],
    ["567", "8"],
    ["667", "9"],
    ["277", "6"],
  ],
  "05/08/2024 to 11/08/2024": [
    ["346", "3"],
    ["556", "6"],
    ["380", "1"],
    ["140", "5"],
    ["255", "2"],
    ["256", "3"],
    ["478", "9"],
  ],
  "12/08/2024 to 18/08/2024": [
    ["145", "0"],
    ["378", "8"],
    ["450", "9"],
    ["460", "0"],
    ["139", "3"],
    ["358", "6"],
    ["199", "9"],
  ],
  "19/08/2024 to 25/08/2024": [
    ["245", "1"],
    ["450", "9"],
    ["230", "5"],
    ["145", "0"],
    ["345", "2"],
    ["149", "4"],
    ["790", "6"],
  ],
  "26/08/2024 to 01/09/2024": [
    ["145", "0"],
    ["579", "1"],
    ["368", "7"],
    ["479", "0"],
    ["139", "3"],
    ["570", "2"],
    ["127", "0"],
  ],
  "02/09/2024 to 08/09/2024": [
    ["166", "3"],
    ["360", "9"],
    ["370", "0"],
    ["480", "2"],
    ["460", "0"],
    ["160", "7"],
    ["230", "5"],
  ],
  "09/09/2024 to 15/09/2024": [
    ["699", "4"],
    ["459", "8"],
    ["790", "6"],
    ["179", "7"],
    ["369", "8"],
    ["458", "7"],
    ["149", "4"],
  ],
  "16/09/2024 to 22/09/2024": [
    ["459", "8"],
    ["390", "2"],
    ["247", "3"],
    ["248", "4"],
    ["589", "2"],
    ["145", "0"],
    ["137", "1"],
  ],
  "23/09/2024 to 29/09/2024": [
    ["257", "4"],
    ["446", "4"],
    ["449", "7"],
    ["346", "3"],
    ["367", "6"],
    ["778", "2"],
    ["119", "1"],
  ],
  "30/09/2024 to 06/10/2024": [
    ["890", "7"],
    ["457", "6"],
    ["190", "0"],
    ["580", "3"],
    ["480", "2"],
    ["488", "0"],
    ["470", "1"],
  ],
};

export const Two = {
  "01/01/2024 to 07/01/2024": [
    ["569", "0"],
    ["124", "7"],
    ["555", "5"],
    ["444", "2"],
    ["357", "5"],
    ["236", "1"],
    ["300", "3"],
  ],
  "08/01/2024 to 14/01/2024": [
    ["358", "6"],
    ["333", "9"],
    ["368", "7"],
    ["228", "2"],
    ["666", "8"],
    ["159", "5"],
    ["369", "8"],
  ],
  "15/01/2024 to 21/01/2024": [
    ["458", "7"],
    ["125", "8"],
    ["890", "7"],
    ["125", "8"],
    ["169", "6"],
    ["357", "5"],
    ["189", "8"],
  ],
  "22/01/2024 to 28/01/2024": [
    ["248", "4"],
    ["137", "1"],
    ["380", "1"],
    ["247", "3"],
    ["120", "3"],
    ["260", "8"],
    ["158", "4"],
  ],
  "29/01/2024 to 04/02/2024": [
    ["167", "4"],
    ["246", "2"],
    ["258", "5"],
    ["260", "8"],
    ["357", "5"],
    ["235", "0"],
    ["457", "6"],
  ],
  "05/02/2024 to 11/02/2024": [
    ["346", "3"],
    ["890", "7"],
    ["590", "4"],
    ["689", "3"],
    ["129", "2"],
    ["570", "2"],
    ["356", "4"],
  ],
  "12/02/2024 to 18/02/2024": [
    ["156", "2"],
    ["450", "9"],
    ["245", "1"],
    ["100", "1"],
    ["246", "2"],
    ["457", "6"],
    ["248", "4"],
  ],
  "19/02/2024 to 25/02/2024": [
    ["369", "8"],
    ["256", "3"],
    ["349", "6"],
    ["790", "6"],
    ["110", "2"],
    ["157", "3"],
    ["245", "1"],
  ],
  "26/02/2024 to 03/03/2024": [
    ["256", "3"],
    ["358", "6"],
    ["135", "9"],
    ["390", "2"],
    ["369", "8"],
    ["589", "2"],
    ["146", "1"],
  ],
  "04/03/2024 to 10/03/2024": [
    ["136", "0"],
    ["245", "1"],
    ["400", "4"],
    ["368", "7"],
    ["560", "1"],
    ["900", "9"],
    ["359", "7"],
  ],
  "11/03/2024 to 17/03/2024": [
    ["149", "4"],
    ["169", "6"],
    ["568", "9"],
    ["120", "3"],
    ["369", "8"],
    ["150", "6"],
    ["159", "5"],
  ],
  "18/03/2024 to 24/03/2024": [
    ["348", "5"],
    ["140", "5"],
    ["569", "0"],
    ["380", "1"],
    ["359", "7"],
    ["235", "0"],
    ["179", "7"],
  ],
  "25/03/2024 to 31/03/2024": [
    ["230", "5"],
    ["459", "8"],
    ["569", "0"],
    ["457", "6"],
    ["578", "0"],
    ["789", "4"],
    ["113", "5"],
  ],
  "01/04/2024 to 07/04/2024": [
    ["490", "3"],
    ["580", "3"],
    ["238", "3"],
    ["690", "5"],
    ["235", "0"],
    ["460", "0"],
    ["148", "3"],
  ],
  "08/04/2024 to 14/04/2024": [
    ["680", "4"],
    ["480", "2"],
    ["123", "6"],
    ["356", "4"],
    ["679", "2"],
    ["340", "7"],
    ["245", "1"],
  ],
  "15/04/2024 to 21/04/2024": [
    ["124", "7"],
    ["789", "4"],
    ["378", "8"],
    ["567", "8"],
    ["357", "5"],
    ["470", "1"],
    ["389", "0"],
  ],
  "22/04/2024 to 28/04/2024": [
    ["779", "3"],
    ["257", "4"],
    ["790", "6"],
    ["568", "9"],
    ["256", "3"],
    ["348", "5"],
    ["236", "1"],
  ],
  "29/04/2024 to 05/05/2024": [
    ["128", "1"],
    ["270", "9"],
    ["567", "8"],
    ["234", "9"],
    ["680", "4"],
    ["337", "3"],
    ["147", "2"],
  ],
  "06/05/2024 to 12/05/2024": [
    ["356", "4"],
    ["279", "8"],
    ["590", "4"],
    ["179", "7"],
    ["125", "8"],
    ["459", "8"],
    ["224", "8"],
  ],
  "13/05/2024 to 19/05/2024": [
    ["569", "0"],
    ["268", "6"],
    ["225", "9"],
    ["278", "7"],
    ["579", "1"],
    ["159", "5"],
    ["358", "6"],
  ],
  "20/05/2024 to 26/05/2024": [
    ["279", "8"],
    ["480", "2"],
    ["456", "5"],
    ["479", "0"],
    ["345", "2"],
    ["119", "1"],
    ["347", "4"],
  ],
  "27/05/2024 to 02/06/2024": [
    ["159", "5"],
    ["367", "6"],
    ["247", "3"],
    ["550", "0"],
    ["468", "8"],
    ["169", "6"],
    ["145", "0"],
  ],
  "03/06/2024 to 09/06/2024": [
    ["456", "5"],
    ["149", "4"],
    ["490", "3"],
    ["357", "5"],
    ["299", "0"],
    ["249", "5"],
    ["127", "0"],
  ],
  "10/06/2024 to 16/06/2024": [
    ["225", "9"],
    ["389", "0"],
    ["445", "3"],
    ["590", "4"],
    ["577", "9"],
    ["580", "3"],
    ["380", "1"],
  ],
  "17/06/2024 to 23/06/2024": [
    ["699", "4"],
    ["166", "3"],
    ["669", "1"],
    ["178", "6"],
    ["380", "1"],
    ["116", "8"],
    ["230", "5"],
  ],
  "24/06/2024 to 30/06/2024": [
    ["233", "8"],
    ["889", "5"],
    ["468", "8"],
    ["127", "0"],
    ["239", "4"],
    ["156", "2"],
    ["480", "2"],
  ],
  "01/07/2024 to 07/07/2024": [
    ["140", "5"],
    ["167", "4"],
    ["117", "9"],
    ["389", "0"],
    ["145", "0"],
    ["168", "5"],
    ["680", "4"],
  ],
  "08/07/2024 to 14/07/2024": [
    ["458", "7"],
    ["159", "5"],
    ["678", "1"],
    ["457", "6"],
    ["488", "0"],
    ["356", "4"],
    ["467", "7"],
  ],
  "15/07/2024 to 21/07/2024": [
    ["470", "1"],
    ["160", "7"],
    ["469", "9"],
    ["179", "7"],
    ["578", "0"],
    ["349", "6"],
    ["188", "7"],
  ],

  "29/07/2024 to 04/08/2024": [
    ["458", "7"],
    ["267", "5"],
    ["569", "0"],
    ["238", "3"],
    ["345", "2"],
    ["133", "7"],
    ["127", "0"],
  ],
  "05/08/2024 to 11/08/2024": [
    ["459", "8"],
    ["168", "5"],
    ["489", "1"],
    ["349", "6"],
    ["449", "7"],
    ["347", "4"],
    ["570", "2"],
  ],
  "12/08/2024 to 18/08/2024": [
    ["355", "3"],
    ["255", "2"],
    ["344", "1"],
    ["300", "3"],
    ["350", "8"],
    ["270", "9"],
    ["228", "2"],
  ],
  "19/08/2024 to 25/08/2024": [
    ["157", "3"],
    ["349", "6"],
    ["124", "7"],
    ["570", "2"],
    ["489", "1"],
    ["125", "8"],
    ["248", "4"],
  ],
  "26/08/2024 to 01/09/2024": [
    ["235", "0"],
    ["257", "4"],
    ["680", "4"],
    ["278", "7"],
    ["669", "1"],
    ["129", "2"],
    ["449", "7"],
  ],
  "02/09/2024 to 08/09/2024": [
    ["256", "3"],
    ["119", "1"],
    ["189", "8"],
    ["379", "9"],
    ["356", "4"],
    ["117", "9"],
    ["268", "6"],
  ],
  "09/09/2024 to 15/09/2024": [
    ["135", "9"],
    ["678", "1"],
    ["578", "0"],
    ["556", "6"],
    ["490", "3"],
    ["224", "8"],
    ["234", "9"],
  ],
  "16/09/2024 to 22/09/2024": [
    ["358", "6"],
    ["599", "3"],
    ["199", "9"],
    ["347", "4"],
    ["900", "9"],
    ["139", "3"],
    ["277", "6"],
  ],
  "23/09/2024 to 29/09/2024": [
    ["357", "5"],
    ["166", "3"],
    ["369", "8"],
    ["479", "0"],
    ["566", "7"],
    ["114", "6"],
    ["144", "9"],
  ],
  "30/09/2024 to 06/10/2024": [
    ["169", "6"],
    ["245", "1"],
    ["458", "7"],
    ["128", "1"],
    ["300", "3"],
    ["127", "0"],
    ["880", "6"],
  ],
};

export const Three = {
  "01/01/2024 to 07/01/2024": [
    ["778", "2"],
    ["568", "9"],
    ["569", "0"],
    ["777", "1"],
    ["159", "5"],
    ["569", "0"],
    ["330", "6"],
  ],
  "08/01/2024 to 14/01/2024": [
    ["147", "2"],
    ["249", "5"],
    ["566", "7"],
    ["999", "7"],
    ["128", "1"],
    ["369", "8"],
    ["115", "7"],
  ],
  "15/01/2024 to 21/01/2024": [
    ["147", "2"],
    ["489", "1"],
    ["479", "0"],
    ["156", "2"],
    ["250", "7"],
    ["446", "4"],
    ["257", "4"],
  ],
  "22/01/2024 to 28/01/2024": [
    ["149", "4"],
    ["380", "1"],
    ["125", "8"],
    ["250", "7"],
    ["789", "4"],
    ["370", "0"],
    ["447", "5"],
  ],
  "29/01/2024 to 04/02/2024": [
    ["289", "9"],
    ["190", "0"],
    ["190", "0"],
    ["389", "0"],
    ["446", "4"],
    ["345", "2"],
    ["260", "8"],
  ],
  "05/02/2024 to 11/02/2024": [
    ["390", "2"],
    ["157", "3"],
    ["138", "2"],
    ["137", "1"],
    ["240", "6"],
    ["290", "1"],
    ["179", "7"],
  ],
  "12/02/2024 to 18/02/2024": [
    ["190", "0"],
    ["678", "1"],
    ["150", "6"],
    ["358", "6"],
    ["478", "9"],
    ["369", "8"],
    ["180", "9"],
  ],
  "19/02/2024 to 25/02/2024": [
    ["239", "4"],
    ["156", "2"],
    ["144", "9"],
    ["456", "5"],
    ["239", "4"],
    ["345", "2"],
    ["123", "6"],
  ],
  "26/02/2024 to 03/03/2024": [
    ["700", "7"],
    ["357", "5"],
    ["258", "5"],
    ["125", "8"],
    ["789", "4"],
    ["990", "8"],
    ["147", "2"],
  ],
  "04/03/2024 to 10/03/2024": [
    ["160", "7"],
    ["299", "0"],
    ["489", "1"],
    ["499", "2"],
    ["140", "5"],
    ["120", "3"],
    ["235", "0"],
  ],
  "11/03/2024 to 17/03/2024": [
    ["550", "0"],
    ["190", "0"],
    ["239", "4"],
    ["267", "5"],
    ["126", "9"],
    ["149", "4"],
    ["267", "5"],
  ],
  "18/03/2024 to 24/03/2024": [
    ["450", "9"],
    ["345", "2"],
    ["470", "1"],
    ["789", "4"],
    ["889", "5"],
    ["790", "6"],
    ["457", "6"],
  ],
  "25/03/2024 to 31/03/2024": [
    ["560", "1"],
    ["679", "2"],
    ["450", "9"],
    ["290", "1"],
    ["127", "0"],
    ["347", "4"],
    ["668", "0"],
  ],
  "01/04/2024 to 07/04/2024": [
    ["267", "5"],
    ["678", "1"],
    ["569", "0"],
    ["479", "0"],
    ["169", "6"],
    ["268", "6"],
    ["480", "2"],
  ],
  "08/04/2024 to 14/04/2024": [
    ["135", "9"],
    ["126", "9"],
    ["145", "0"],
    ["457", "6"],
    ["599", "3"],
    ["230", "5"],
    ["690", "5"],
  ],
  "15/04/2024 to 21/04/2024": [
    ["450", "9"],
    ["139", "3"],
    ["279", "8"],
    ["489", "1"],
    ["890", "7"],
    ["235", "0"],
    ["140", "5"],
  ],
  "22/04/2024 to 28/04/2024": [
    ["168", "5"],
    ["350", "8"],
    ["178", "6"],
    ["377", "7"],
    ["145", "0"],
    ["123", "6"],
    ["119", "1"],
  ],
  "29/04/2024 to 05/05/2024": [
    ["124", "7"],
    ["478", "9"],
    ["669", "1"],
    ["135", "9"],
    ["256", "3"],
    ["234", "9"],
    ["360", "9"],
  ],
  "06/05/2024 to 12/05/2024": [
    ["125", "8"],
    ["468", "8"],
    ["344", "1"],
    ["445", "3"],
    ["289", "9"],
    ["460", "0"],
    ["550", "0"],
  ],
  "13/05/2024 to 19/05/2024": [
    ["233", "8"],
    ["480", "2"],
    ["140", "5"],
    ["120", "3"],
    ["379", "9"],
    ["124", "7"],
    ["360", "9"],
  ],
  "20/05/2024 to 26/05/2024": [
    ["348", "5"],
    ["233", "8"],
    ["689", "3"],
    ["140", "5"],
    ["236", "1"],
    ["346", "3"],
    ["300", "3"],
  ],
  "27/05/2024 to 02/06/2024": [
    ["146", "1"],
    ["249", "5"],
    ["449", "7"],
    ["335", "1"],
    ["359", "7"],
    ["560", "1"],
    ["580", "3"],
  ],
  "03/06/2024 to 09/06/2024": [
    ["189", "8"],
    ["369", "8"],
    ["388", "9"],
    ["140", "5"],
    ["168", "5"],
    ["569", "0"],
    ["246", "2"],
  ],
  "10/06/2024 to 16/06/2024": [
    ["178", "6"],
    ["450", "9"],
    ["135", "9"],
    ["448", "6"],
    ["267", "5"],
    ["470", "1"],
    ["112", "4"],
  ],
  "17/06/2024 to 23/06/2024": [
    ["230", "5"],
    ["460", "0"],
    ["568", "9"],
    ["588", "1"],
    ["267", "5"],
    ["358", "6"],
    ["235", "0"],
  ],
  "24/06/2024 to 30/06/2024": [
    ["590", "4"],
    ["277", "6"],
    ["380", "1"],
    ["478", "9"],
    ["379", "9"],
    ["150", "6"],
    ["148", "3"],
  ],
  "01/07/2024 to 07/07/2024": [
    ["277", "6"],
    ["556", "6"],
    ["569", "0"],
    ["170", "8"],
    ["348", "5"],
    ["258", "5"],
    ["357", "5"],
  ],
  "08/07/2024 to 14/07/2024": [
    ["117", "9"],
    ["445", "3"],
    ["134", "8"],
    ["360", "9"],
    ["349", "6"],
    ["110", "2"],
    ["578", "0"],
  ],
  "15/07/2024 to 21/07/2024": [
    ["389", "0"],
    ["246", "2"],
    ["347", "4"],
    ["690", "5"],
    ["990", "8"],
    ["457", "6"],
    ["477", "8"],
  ],
  "22/07/2024 to 28/07/2024": [
    ["230", "5"],
    ["224", "8"],
    ["589", "2"],
    ["478", "9"],
    ["248", "4"],
    ["245", "1"],
    ["489", "1"],
  ],
  "29/07/2024 to 04/08/2024": [
    ["579", "1"],
    ["660", "2"],
    ["477", "8"],
    ["479", "0"],
    ["119", "1"],
    ["359", "7"],
    ["178", "6"],
  ],
  "05/08/2024 to 11/08/2024": [
    ["148", "3"],
    ["358", "6"],
    ["122", "5"],
    ["557", "7"],
    ["240", "6"],
    ["600", "6"],
    ["156", "2"],
  ],
  "12/08/2024 to 18/08/2024": [
    ["137", "1"],
    ["135", "9"],
    ["480", "2"],
    ["469", "9"],
    ["670", "3"],
    ["578", "0"],
    ["155", "1"],
  ],
  "10/06/2024 to 16/06/2024": [
    ["178", "6"],
    ["450", "9"],
    ["135", "9"],
    ["448", "6"],
    ["267", "5"],
    ["470", "1"],
    ["112", "4"],
  ],
  "17/06/2024 to 23/06/2024": [
    ["230", "5"],
    ["460", "0"],
    ["568", "9"],
    ["588", "1"],
    ["267", "5"],
    ["358", "6"],
    ["235", "0"],
  ],
  "24/06/2024 to 30/06/2024": [
    ["590", "4"],
    ["277", "6"],
    ["380", "1"],
    ["478", "9"],
    ["379", "9"],
    ["150", "6"],
    ["148", "3"],
  ],
  "01/07/2024 to 07/07/2024": [
    ["277", "6"],
    ["556", "6"],
    ["569", "0"],
    ["170", "8"],
    ["348", "5"],
    ["258", "5"],
    ["357", "5"],
  ],
  "08/07/2024 to 14/07/2024": [
    ["117", "9"],
    ["445", "3"],
    ["134", "8"],
    ["360", "9"],
    ["349", "6"],
    ["110", "2"],
    ["578", "0"],
  ],
  "15/07/2024 to 21/07/2024": [
    ["389", "0"],
    ["246", "2"],
    ["347", "4"],
    ["690", "5"],
    ["990", "8"],
    ["457", "6"],
    ["477", "8"],
  ],
  "22/07/2024 to 28/07/2024": [
    ["230", "5"],
    ["224", "8"],
    ["589", "2"],
    ["478", "9"],
    ["248", "4"],
    ["245", "1"],
    ["489", "1"],
  ],
  "29/07/2024 to 04/08/2024": [
    ["579", "1"],
    ["660", "2"],
    ["477", "8"],
    ["479", "0"],
    ["119", "1"],
    ["359", "7"],
    ["178", "6"],
  ],
  "05/08/2024 to 11/08/2024": [
    ["148", "3"],
    ["358", "6"],
    ["122", "5"],
    ["557", "7"],
    ["240", "6"],
    ["600", "6"],
    ["156", "2"],
  ],
  "12/08/2024 to 18/08/2024": [
    ["137", "1"],
    ["135", "9"],
    ["480", "2"],
    ["469", "9"],
    ["670", "3"],
    ["578", "0"],
    ["155", "1"],
  ],
  "09/16/2024 to 09/22/2024": [
    ["589", "2"],
    ["257", "4"],
    ["147", "2"],
    ["128", "1"],
    ["167", "4"],
    ["249", "5"],
    ["338", "4"],
  ],
  "09/23/2024 to 09/29/2024": [
    ["780", "5"],
    ["237", "2"],
    ["157", "3"],
    ["289", "9"],
    ["455", "4"],
    ["147", "2"],
    ["478", "9"],
  ],
  "09/30/2024 to 10/06/2024": [
    ["389", "0"],
    ["149", "4"],
    ["559", "9"],
    ["136", "0"],
    ["156", "2"],
    ["670", "3"],
    ["150", "6"],
  ],
};

export const Four = {
  "01/01/2024 to 07/01/2024": [
    ["125", "8"],
    ["569", "0"],
    ["235", "0"],
    ["127", "0"],
    ["148", "3"],
    ["777", "1"],
    ["458", "7"],
  ],
  "08/01/2024 to 14/01/2024": [
    ["149", "4"],
    ["389", "0"],
    ["117", "9"],
    ["145", "0"],
    ["456", "5"],
    ["368", "7"],
    ["456", "5"],
  ],
  "15/01/2024 to 21/01/2024": [
    ["569", "0"],
    ["359", "7"],
    ["249", "5"],
    ["569", "0"],
    ["458", "7"],
    ["150", "6"],
    ["458", "7"],
  ],
  "22/01/2024 to 28/01/2024": [
    ["349", "6"],
    ["568", "9"],
    ["570", "2"],
    ["123", "6"],
    ["128", "1"],
    ["345", "2"],
    ["247", "3"],
  ],
  "29/01/2024 to 04/02/2024": [
    ["668", "0"],
    ["257", "4"],
    ["259", "6"],
    ["156", "2"],
    ["360", "9"],
    ["170", "8"],
    ["789", "4"],
  ],
  "05/02/2024 to 11/02/2024": [
    ["134", "8"],
    ["234", "9"],
    ["346", "3"],
    ["246", "2"],
    ["125", "8"],
    ["125", "8"],
    ["147", "2"],
  ],
  "12/02/2024 to 18/02/2024": [
    ["130", "4"],
    ["100", "1"],
    ["258", "5"],
    ["567", "8"],
    ["990", "8"],
    ["156", "2"],
    ["200", "2"],
  ],
  "02/19/2024 to 02/25/2024": [
    ["560", "1"],
    ["356", "4"],
    ["357", "5"],
    ["136", "0"],
    ["680", "4"],
    ["356", "4"],
    ["149", "4"],
  ],
  "02/26/2024 to 03/03/2024": [
    ["338", "4"],
    ["480", "2"],
    ["477", "8"],
    ["700", "7"],
    ["457", "6"],
    ["234", "9"],
    ["230", "5"],
  ],
  "03/04/2024 to 03/10/2024": [
    ["567", "8"],
    ["468", "8"],
    ["110", "2"],
    ["130", "4"],
    ["689", "3"],
    ["290", "1"],
    ["447", "5"],
  ],
  "03/11/2024 to 03/17/2024": [
    ["180", "9"],
    ["400", "4"],
    ["136", "0"],
    ["138", "2"],
    ["679", "2"],
    ["235", "0"],
    ["347", "4"],
  ],
  "03/18/2024 to 03/24/2024": [
    ["770", "4"],
    ["258", "5"],
    ["345", "2"],
    ["578", "0"],
    ["370", "0"],
    ["557", "7"],
    ["237", "2"],
  ],
  "03/25/2024 to 03/31/2024": [
    ["117", "9"],
    ["349", "6"],
    ["178", "6"],
    ["480", "2"],
    ["457", "6"],
    ["169", "6"],
    ["248", "4"],
  ],
  "04/01/2024 to 04/07/2024": [
    ["699", "4"],
    ["237", "2"],
    ["123", "6"],
    ["238", "3"],
    ["690", "5"],
    ["580", "3"],
    ["277", "6"],
  ],
  "04/08/2024 to 04/14/2024": [
    ["359", "7"],
    ["578", "0"],
    ["567", "8"],
    ["578", "0"],
    ["457", "6"],
    ["558", "8"],
    ["179", "7"],
  ],
  "04/15/2024 to 04/21/2024": [
    ["357", "5"],
    ["233", "8"],
    ["478", "9"],
    ["260", "8"],
    ["457", "6"],
    ["456", "5"],
    ["237", "2"],
  ],
  "04/22/2024 to 04/28/2024": [
    ["560", "1"],
    ["579", "1"],
    ["500", "5"],
    ["367", "6"],
    ["360", "9"],
    ["789", "4"],
    ["470", "1"],
  ],
  "04/29/2024 to 05/05/2024": [
    ["569", "0"],
    ["260", "8"],
    ["248", "4"],
    ["469", "9"],
    ["790", "6"],
    ["169", "6"],
    ["257", "4"],
  ],
  "05/06/2024 to 05/12/2024": [
    ["229", "3"],
    ["890", "7"],
    ["170", "8"],
    ["390", "2"],
    ["770", "4"],
    ["480", "2"],
    ["590", "4"],
  ],
  "05/13/2024 to 05/19/2024": [
    ["566", "7"],
    ["378", "8"],
    ["469", "9"],
    ["157", "3"],
    ["239", "4"],
    ["389", "0"],
    ["489", "1"],
  ],
  "05/20/2024 to 05/26/2024": [
    ["267", "5"],
    ["129", "2"],
    ["128", "1"],
    ["338", "4"],
    ["247", "3"],
    ["233", "8"],
    ["679", "2"],
  ],
  "05/27/2024 to 06/02/2024": [
    ["578", "0"],
    ["115", "7"],
    ["189", "8"],
    ["246", "2"],
    ["148", "3"],
    ["234", "9"],
    ["166", "3"],
  ],
  "06/03/2024 to 06/09/2024": [
    ["880", "6"],
    ["377", "7"],
    ["580", "3"],
    ["300", "3"],
    ["150", "6"],
    ["350", "8"],
    ["489", "1"],
  ],
  "06/10/2024 to 06/16/2024": [
    ["257", "4"],
    ["558", "8"],
    ["358", "6"],
    ["235", "0"],
    ["336", "2"],
    ["788", "3"],
    ["579", "1"],
  ],
  "06/17/2024 to 06/23/2024": [
    ["688", "2"],
    ["690", "5"],
    ["368", "7"],
    ["120", "3"],
    ["159", "5"],
    ["280", "0"],
    ["137", "1"],
  ],
  "06/24/2024 to 06/30/2024": [
    ["114", "6"],
    ["456", "5"],
    ["778", "2"],
    ["280", "0"],
    ["267", "5"],
    ["370", "0"],
    ["220", "4"],
  ],
  "07/01/2024 to 07/07/2024": [
    ["457", "6"],
    ["148", "3"],
    ["130", "4"],
    ["126", "9"],
    ["570", "2"],
    ["336", "2"],
    ["245", "1"],
  ],
  "07/08/2024 to 07/14/2024": [
    ["124", "7"],
    ["268", "6"],
    ["148", "3"],
    ["560", "1"],
    ["244", "0"],
    ["560", "1"],
    ["170", "8"],
  ],
  "07/15/2024 to 07/21/2024": [
    ["225", "9"],
    ["380", "1"],
    ["145", "0"],
    ["457", "6"],
    ["289", "9"],
    ["580", "3"],
    ["390", "2"],
  ],
  "07/22/2024 to 07/28/2024": [
    ["148", "3"],
    ["246", "2"],
    ["156", "2"],
    ["250", "7"],
    ["124", "7"],
    ["447", "5"],
    ["170", "8"],
  ],
  "07/29/2024 to 08/04/2024": [
    ["237", "2"],
    ["117", "9"],
    ["114", "6"],
    ["179", "7"],
    ["277", "6"],
    ["290", "1"],
    ["157", "3"],
  ],
  "08/05/2024 to 08/11/2024": [
    ["268", "6"],
    ["669", "1"],
    ["129", "2"],
    ["238", "3"],
    ["178", "6"],
    ["235", "0"],
    ["460", "0"],
  ],
  "08/12/2024 to 08/18/2024": [
    ["245", "1"],
    ["880", "6"],
    ["266", "4"],
    ["223", "7"],
    ["166", "3"],
    ["458", "7"],
    ["136", "0"],
  ],
  "08/19/2024 to 08/25/2024": [
    ["470", "1"],
    ["113", "5"],
    ["670", "3"],
    ["660", "2"],
    ["138", "2"],
    ["145", "0"],
    ["780", "5"],
  ],
  "08/26/2024 to 09/01/2024": [
    ["470", "1"],
    ["147", "2"],
    ["357", "5"],
    ["134", "8"],
    ["125", "8"],
    ["235", "0"],
    ["369", "8"],
  ],
  "09/02/2024 to 09/08/2024": [
    ["445", "3"],
    ["800", "8"],
    ["116", "8"],
    ["350", "8"],
    ["168", "5"],
    ["356", "4"],
    ["124", "7"],
  ],
  "09/09/2024 to 09/15/2024": [
    ["345", "2"],
    ["559", "9"],
    ["188", "7"],
    ["335", "1"],
    ["259", "6"],
    ["560", "1"],
    ["458", "7"],
  ],
  "09/16/2024 to 09/22/2024": [
    ["169", "6"],
    ["789", "4"],
    ["558", "8"],
    ["135", "9"],
    ["568", "9"],
    ["380", "1"],
    ["138", "2"],
  ],
  "09/23/2024 to 09/29/2024": [
    ["467", "7"],
    ["480", "2"],
    ["450", "9"],
    ["380", "1"],
    ["134", "8"],
    ["344", "1"],
    ["600", "6"],
  ],
  "09/30/2024 to 10/06/2024": [
    ["130", "4"],
    ["470", "1"],
    ["199", "9"],
    ["269", "7"],
    ["450", "9"],
    ["335", "1"],
    ["469", "9"],
  ],
};
export const Five = {
  "01/01/2024 to 07/01/2024": [
    ["123", "6"],
    ["258", "5"],
    ["333", "9"],
    ["569", "0"],
    ["359", "7"],
    ["235", "0"],
    ["789", "4"],
  ],
  "08/01/2024 to 14/01/2024": [
    ["100", "1"],
    ["778", "2"],
    ["338", "4"],
    ["446", "4"],
    ["159", "5"],
    ["567", "8"],
    ["890", "7"],
  ],
  "15/01/2024 to 21/01/2024": [
    ["890", "7"],
    ["256", "3"],
    ["128", "1"],
    ["890", "7"],
    ["125", "8"],
    ["489", "1"],
    ["169", "6"],
  ],
  "22/01/2024 to 28/01/2024": [
    ["580", "3"],
    ["238", "3"],
    ["127", "0"],
    ["249", "5"],
    ["578", "0"],
    ["120", "3"],
    ["589", "2"],
  ],
  "29/01/2024 to 04/02/2024": [
    ["456", "5"],
    ["570", "2"],
    ["478", "9"],
    ["135", "9"],
    ["389", "0"],
    ["567", "8"],
    ["160", "7"],
  ],
  "05/02/2024 to 11/02/2024": [
    ["789", "4"],
    ["356", "4"],
    ["489", "1"],
    ["356", "4"],
    ["245", "1"],
    ["379", "9"],
    ["390", "2"],
  ],
  "12/02/2024 to 18/02/2024": [
    ["258", "5"],
    ["156", "2"],
    ["345", "2"],
    ["288", "8"],
    ["578", "0"],
    ["356", "4"],
    ["346", "3"],
  ],
  "19/02/2024 to 25/02/2024": [
    ["345", "2"],
    ["178", "6"],
    ["356", "4"],
    ["400", "4"],
    ["126", "9"],
    ["348", "5"],
    ["600", "6"],
  ],
  "26/02/2024 to 03/03/2024": [
    ["456", "5"],
    ["257", "4"],
    ["245", "1"],
    ["160", "7"],
    ["123", "6"],
    ["457", "6"],
    ["139", "3"],
  ],
  "04/03/2024 to 10/03/2024": [
    ["356", "4"],
    ["190", "0"],
    ["168", "5"],
    ["459", "8"],
    ["290", "1"],
    ["129", "2"],
    ["290", "1"],
  ],
  "03/03/2024 to 03/03/2024": [
    ["290", "1"],
    ["390", "2"],
    ["459", "8"],
    ["124", "7"],
    ["133", "7"],
    ["356", "4"],
    ["890", "7"],
  ],
  "18/03/2024 to 24/03/2024": [
    ["678", "1"],
    ["155", "1"],
    ["130", "4"],
    ["340", "7"],
    ["468", "8"],
    ["148", "3"],
    ["579", "1"],
  ],
  "25/03/2024 to 31/03/2024": [
    ["380", "1"],
    ["299", "0"],
    ["345", "2"],
    ["478", "9"],
    ["279", "8"],
    ["236", "1"],
    ["277", "6"],
  ],
  "01/04/2024 to 07/04/2024": [
    ["369", "8"],
    ["469", "9"],
    ["347", "4"],
    ["357", "5"],
    ["278", "7"],
    ["146", "1"],
    ["450", "9"],
  ],
  "08/04/2024 to 14/04/2024": [
    ["124", "7"],
    ["469", "9"],
    ["455", "4"],
    ["159", "5"],
    ["138", "2"],
    ["469", "9"],
    ["334", "0"],
  ],
  "15/04/2024 to 21/04/2024": [
    ["120", "3"],
    ["346", "3"],
    ["235", "0"],
    ["146", "1"],
    ["136", "0"],
    ["388", "9"],
    ["158", "4"],
  ],
  "22/04/2024 to 28/04/2024": [
    ["449", "7"],
    ["347", "4"],
    ["388", "9"],
    ["589", "2"],
    ["168", "5"],
    ["224", "8"],
    ["240", "6"],
  ],
  "29/04/2024 to 05/05/2024": [
    ["278", "7"],
    ["168", "5"],
    ["347", "4"],
    ["336", "2"],
    ["577", "9"],
    ["237", "2"],
    ["228", "2"],
  ],
  "06/05/2024 to 12/05/2024": [
    ["679", "2"],
    ["258", "5"],
    ["240", "6"],
    ["268", "6"],
    ["356", "4"],
    ["290", "1"],
    ["136", "0"],
  ],
  "13/05/2024 to 19/05/2024": [
    ["350", "8"],
    ["119", "1"],
    ["268", "6"],
    ["339", "5"],
    ["135", "9"],
    ["345", "2"],
    ["280", "0"],
  ],
  "20/05/2024 to 26/05/2024": [
    ["590", "4"],
    ["358", "6"],
    ["249", "5"],
    ["245", "1"],
    ["789", "4"],
    ["690", "5"],
    ["334", "0"],
  ],
  "27/05/2024 to 02/06/2024": [
    ["490", "3"],
    ["480", "2"],
    ["234", "9"],
    ["150", "6"],
    ["269", "7"],
    ["770", "4"],
    ["460", "0"],
  ],
  "03/06/2024 to 09/06/2024": [
    ["345", "2"],
    ["155", "1"],
    ["144", "9"],
    ["446", "4"],
    ["235", "0"],
    ["225", "9"],
    ["239", "4"],
  ],
  "10/06/2024 to 16/06/2024": [
    ["348", "5"],
    ["335", "1"],
    ["260", "8"],
    ["399", "1"],
    ["237", "2"],
    ["230", "5"],
    ["149", "4"],
  ],
  "17/06/2024 to 23/06/2024": [
    ["557", "7"],
    ["448", "6"],
    ["169", "6"],
    ["144", "9"],
    ["126", "9"],
    ["449", "7"],
    ["340", "7"],
  ],
  "24/06/2024 to 30/06/2024": [
    ["145", "0"],
    ["112", "4"],
    ["568", "9"],
    ["355", "3"],
    ["680", "4"],
    ["114", "6"],
    ["780", "5"],
  ],
  "01/07/2024 to 07/07/2024": [
    ["690", "5"],
    ["668", "0"],
    ["349", "6"],
    ["289", "9"],
    ["378", "8"],
    ["457", "6"],
    ["160", "7"],
  ],
  "08/07/2024 to 14/07/2024": [
    ["239", "4"],
    ["356", "4"],
    ["240", "6"],
    ["279", "8"],
    ["790", "6"],
    ["200", "2"],
    ["348", "5"],
  ],
  "15/07/2024 to 21/07/2024": [
    ["167", "4"],
    ["566", "7"],
    ["378", "8"],
    ["125", "8"],
    ["389", "0"],
    ["133", "7"],
    ["469", "9"],
  ],
  "22/07/2024 to 28/07/2024": [
    ["480", "2"],
    ["135", "9"],
    ["790", "6"],
    ["334", "0"],
    ["225", "9"],
    ["159", "5"],
    ["680", "4"],
  ],
  "29/07/2024 to 04/08/2024": [
    ["677", "0"],
    ["470", "1"],
    ["289", "9"],
    ["560", "1"],
    ["134", "8"],
    ["167", "4"],
    ["167", "4"],
  ],
  "05/08/2024 to 11/08/2024": [
    ["159", "5"],
    ["180", "9"],
    ["558", "8"],
    ["469", "9"],
    ["800", "8"],
    ["790", "6"],
    ["278", "7"],
  ],
  "12/08/2024 to 18/08/2024": [
    ["455", "4"],
    ["334", "0"],
    ["560", "1"],
    ["160", "7"],
    ["220", "4"],
    ["389", "0"],
    ["169", "6"],
  ],
  "19/08/2024 to 25/08/2024": [
    ["589", "2"],
    ["380", "1"],
    ["139", "3"],
    ["445", "3"],
    ["389", "0"],
    ["157", "3"],
    ["348", "5"],
  ],
  "26/08/2024 to 01/09/2024": [
    ["677", "0"],
    ["448", "6"],
    ["180", "9"],
    ["369", "8"],
    ["270", "9"],
    ["447", "5"],
    ["117", "9"],
  ],
  "02/09/2024 to 08/09/2024": [
    ["469", "9"],
    ["289", "9"],
    ["239", "4"],
    ["259", "6"],
    ["257", "4"],
    ["335", "1"],
    ["478", "9"],
  ],
  "09/09/2024 to 15/09/2024": [
    ["189", "8"],
    ["167", "4"],
    ["229", "3"],
    ["570", "2"],
    ["368", "7"],
    ["226", "0"],
    ["227", "1"],
  ],
  "16/09/2024 to 22/09/2024": [
    ["246", "2"],
    ["166", "3"],
    ["456", "5"],
    ["237", "2"],
    ["478", "9"],
    ["469", "9"],
    ["190", "0"],
  ],
  "23/09/2024 to 29/09/2024": [
    ["167", "4"],
    ["113", "5"],
    ["358", "6"],
    ["190", "0"],
    ["459", "8"],
    ["468", "8"],
    ["467", "7"],
  ],
  "30/09/2024 to 06/10/2024": [
    ["268", "6"],
    ["128", "1"],
    ["357", "5"],
    ["479", "0"],
    ["699", "4"],
    ["389", "0"],
    ["250", "7"],
  ],
};

export const Six = {
  "01/01/2024 to 07/01/2024": [
    ["456", "5"],
    ["357", "5"],
    ["899", "6"],
    ["256", "3"],
    ["569", "0"],
    ["569", "0"],
    ["999", "7"],
  ],
  "08/01/2024 to 14/01/2024": [
    ["255", "2"],
    ["140", "5"],
    ["555", "5"],
    ["558", "8"],
    ["127", "0"],
    ["470", "1"],
    ["789", "4"],
  ],
  "15/01/2024 to 21/01/2024": [
    ["458", "7"],
    ["690", "5"],
    ["359", "7"],
    ["489", "1"],
    ["348", "5"],
    ["358", "6"],
    ["360", "9"],
  ],
  "22/01/2024 to 28/01/2024": [
    ["356", "4"],
    ["368", "7"],
    ["260", "8"],
    ["350", "8"],
    ["278", "7"],
    ["789", "4"],
    ["124", "7"],
  ],
  "29/01/2024 to 04/02/2024": [
    ["789", "4"],
    ["339", "5"],
    ["148", "3"],
    ["123", "6"],
    ["140", "5"],
    ["390", "2"],
    ["200", "2"],
  ],
  "05/02/2024 to 11/02/2024": [
    ["134", "8"],
    ["230", "5"],
    ["150", "6"],
    ["237", "2"],
    ["580", "3"],
    ["127", "0"],
    ["230", "5"],
  ],
  "12/02/2024 to 18/02/2024": [
    ["128", "1"],
    ["158", "4"],
    ["289", "9"],
    ["348", "5"],
    ["258", "5"],
    ["368", "7"],
    ["290", "1"],
  ],
  "19/02/2024 to 25/02/2024": [
    ["256", "3"],
    ["245", "1"],
    ["390", "2"],
    ["125", "8"],
    ["270", "9"],
    ["900", "9"],
    ["110", "2"],
  ],
  "26/02/2024 to 03/03/2024": [
    ["245", "1"],
    ["147", "2"],
    ["579", "1"],
    ["145", "0"],
    ["900", "9"],
    ["126", "9"],
    ["240", "6"],
  ],
  "03/04/2024 to 10/04/2024": [
    ["110", "2"],
    ["289", "9"],
    ["689", "3"],
    ["280", "0"],
    ["289", "9"],
    ["359", "7"],
    ["129", "2"],
  ],
  "11/03/2024 to 17/03/2024": [
    ["360", "9"],
    ["249", "5"],
    ["890", "7"],
    ["235", "0"],
    ["127", "0"],
    ["290", "1"],
    ["900", "9"],
  ],
  "18/03/2024 to 24/03/2024": [
    ["360", "9"],
    ["120", "3"],
    ["889", "5"],
    ["238", "3"],
    ["690", "5"],
    ["690", "5"],
    ["338", "4"],
  ],
  "25/03/2024 to 31/03/2024": [
    ["558", "8"],
    ["478", "9"],
    ["234", "9"],
    ["258", "5"],
    ["179", "7"],
    ["128", "1"],
    ["156", "2"],
  ],
  "01/04/2024 to 07/04/2024": [
    ["279", "8"],
    ["126", "9"],
    ["489", "1"],
    ["178", "6"],
    ["346", "3"],
    ["278", "7"],
    ["138", "2"],
  ],
  "08/04/2024 to 14/04/2024": [
    ["258", "5"],
    ["690", "5"],
    ["679", "2"],
    ["480", "2"],
    ["379", "9"],
    ["347", "4"],
    ["139", "3"],
  ],
  "15/04/2024 to 21/04/2024": [
    ["367", "6"],
    ["560", "1"],
    ["690", "5"],
    ["889", "5"],
    ["358", "6"],
    ["670", "3"],
    ["789", "4"],
  ],
  "22/04/2024 to 28/04/2024": [
    ["230", "5"],
    ["668", "0"],
    ["357", "5"],
    ["336", "2"],
    ["247", "3"],
    ["279", "8"],
    ["250", "7"],
  ],
  "29/04/2024 to 05/05/2024": [
    ["349", "6"],
    ["157", "3"],
    ["234", "9"],
    ["678", "1"],
    ["246", "2"],
    ["459", "8"],
    ["130", "4"],
  ],
  "06/05/2024 to 12/05/2024": [
    ["340", "7"],
    ["780", "5"],
    ["456", "5"],
    ["480", "2"],
    ["149", "4"],
    ["129", "2"],
    ["129", "2"],
  ],
  "13/05/2024 to 19/05/2024": [
    ["459", "8"],
    ["150", "6"],
    ["220", "4"],
    ["223", "7"],
    ["347", "4"],
    ["460", "0"],
    ["334", "0"],
  ],
  "20/05/2024 to 26/05/2024": [
    ["344", "1"],
    ["259", "6"],
    ["660", "2"],
    ["126", "9"],
    ["479", "0"],
    ["589", "2"],
    ["346", "3"],
  ],
  "27/05/2024 to 02/06/2024": [
    ["450", "9"],
    ["135", "9"],
    ["236", "1"],
    ["389", "0"],
    ["134", "8"],
    ["129", "2"],
    ["233", "8"],
  ],
  "03/06/2024 to 09/06/2024": [
    ["780", "5"],
    ["560", "1"],
    ["469", "9"],
    ["590", "4"],
    ["127", "0"],
    ["138", "2"],
    ["128", "1"],
  ],
  "10/06/2024 to 16/06/2024": [
    ["189", "8"],
    ["150", "6"],
    ["379", "9"],
    ["580", "3"],
    ["330", "6"],
    ["100", "1"],
    ["240", "6"],
  ],
  "17/06/2024 to 23/06/2024": [
    ["233", "8"],
    ["256", "3"],
    ["236", "1"],
    ["779", "3"],
    ["458", "7"],
    ["370", "0"],
    ["346", "3"],
  ],
  "24/06/2024 to 30/06/2024": [
    ["589", "2"],
    ["378", "8"],
    ["479", "0"],
    ["223", "7"],
    ["139", "3"],
    ["179", "7"],
    ["357", "5"],
  ],
  "01/07/2024 to 07/07/2024": [
    ["279", "8"],
    ["446", "4"],
    ["679", "2"],
    ["390", "2"],
    ["227", "1"],
    ["129", "2"],
    ["258", "5"],
  ],
  "08/07/2024 to 14/07/2024": [
    ["157", "3"],
    ["378", "8"],
    ["580", "3"],
    ["230", "5"],
    ["166", "3"],
    ["579", "1"],
    ["147", "2"],
  ],
  "15/07/2024 to 21/07/2024": [
    ["447", "5"],
    ["224", "8"],
    ["157", "3"],
    ["136", "0"],
    ["137", "1"],
    ["234", "9"],
    ["378", "8"],
  ],
  "22/07/2024 to 28/07/2024": [
    ["150", "6"],
    ["337", "3"],
    ["489", "1"],
    ["169", "6"],
    ["112", "4"],
    ["147", "2"],
    ["257", "4"],
  ],
  "29/07/2024 to 04/08/2024": [
    ["240", "6"],
    ["368", "7"],
    ["344", "1"],
    ["679", "2"],
    ["226", "0"],
    ["670", "3"],
    ["224", "8"],
  ],
  "05/08/2024 to 11/08/2024": [
    ["357", "5"],
    ["445", "3"],
    ["246", "2"],
    ["330", "6"],
    ["167", "4"],
    ["400", "4"],
    ["140", "5"],
  ],
  "12/08/2024 to 18/08/2024": [
    ["366", "5"],
    ["130", "4"],
    ["136", "0"],
    ["148", "3"],
    ["333", "9"],
    ["558", "8"],
    ["599", "3"],
  ],
  "19/08/2024 to 25/08/2024": [
    ["158", "4"],
    ["256", "3"],
    ["477", "8"],
    ["480", "2"],
    ["569", "0"],
    ["358", "6"],
    ["160", "7"],
  ],
  "26/08/2024 to 01/09/2024": [
    ["335", "1"],
    ["118", "0"],
    ["356", "4"],
    ["248", "4"],
    ["568", "9"],
    ["227", "1"],
    ["126", "9"],
  ],
  "02/09/2024 to 08/09/2024": [
    ["789", "4"],
    ["366", "5"],
    ["337", "3"],
    ["140", "5"],
    ["789", "4"],
    ["130", "4"],
    ["334", "0"],
  ],
  "09/09/2024 to 15/09/2024": [
    ["588", "1"],
    ["226", "0"],
    ["269", "7"],
    ["137", "1"],
    ["780", "5"],
    ["247", "3"],
    ["690", "5"],
  ],
  "16/09/2024 to 22/09/2024": [
    ["129", "2"],
    ["580", "3"],
    ["158", "4"],
    ["379", "9"],
    ["345", "2"],
    ["790", "6"],
    ["460", "0"],
  ],
  "23/09/2024 to 29/09/2024": [
    ["360", "9"],
    ["590", "4"],
    ["247", "3"],
    ["237", "2"],
    ["556", "6"],
    ["236", "1"],
    ["900", "9"],
  ],
  "30/09/2024 to 06/10/2024": [
    ["180", "9"],
    ["780", "5"],
    ["788", "3"],
    ["569", "0"],
    ["589", "2"],
    ["380", "1"],
    ["478", "9"],
  ],
};

export const Seven = {
  "01/01/2024 to 07/01/2024": [
    ["999", "7"],
    ["568", "9"],
    ["900", "9"],
    ["899", "6"],
    ["699", "4"],
    ["555", "5"],
    ["789", "4"],
  ],
  "08/01/2024 to 14/01/2024": [
    ["355", "3"],
    ["880", "6"],
    ["128", "1"],
    ["115", "7"],
    ["459", "8"],
    ["690", "5"],
    ["250", "7"],
  ],
  "15/01/2024 to 21/01/2024": [
    ["156", "2"],
    ["569", "0"],
    ["689", "3"],
    ["359", "7"],
    ["189", "8"],
    ["157", "3"],
    ["190", "0"],
  ],
  "22/01/2024 to 28/01/2024": [
    ["140", "5"],
    ["369", "8"],
    ["256", "3"],
    ["128", "1"],
    ["189", "8"],
    ["145", "0"],
    ["129", "2"],
  ],
  "29/01/2024 to 04/02/2024": [
    ["346", "3"],
    ["670", "3"],
    ["257", "4"],
    ["578", "0"],
    ["167", "4"],
    ["600", "6"],
    ["346", "3"],
  ],
  "05/02/2024 to 11/02/2024": [
    ["457", "6"],
    ["468", "8"],
    ["347", "4"],
    ["150", "6"],
    ["356", "4"],
    ["670", "3"],
    ["129", "2"],
  ],
  "12/02/2024 to 18/02/2024": [
    ["349", "6"],
    ["458", "7"],
    ["445", "3"],
    ["122", "5"],
    ["457", "6"],
    ["120", "3"],
    ["140", "5"],
  ],
  "19/02/2024 to 25/02/2024": [
    ["150", "6"],
    ["146", "1"],
    ["139", "3"],
    ["137", "1"],
    ["237", "2"],
    ["780", "5"],
    ["120", "3"],
  ],
  "26/02/2024 to 03/03/2024": [
    ["145", "0"],
    ["550", "0"],
    ["200", "2"],
    ["337", "3"],
    ["135", "9"],
    ["124", "7"],
    ["347", "4"],
  ],
  "04/03/2024 to 10/03/2024": [
    ["369", "8"],
    ["480", "2"],
    ["246", "2"],
    ["128", "1"],
    ["256", "3"],
    ["340", "7"],
    ["257", "4"],
  ],
  "11/03/2024 to 17/03/2024": [
    ["557", "7"],
    ["145", "0"],
    ["780", "5"],
    ["567", "8"],
    ["157", "3"],
    ["566", "7"],
    ["480", "2"],
  ],
  "18/03/2024 to 24/03/2024": [
    ["569", "0"],
    ["257", "4"],
    ["490", "3"],
    ["237", "2"],
    ["558", "8"],
    ["349", "6"],
    ["180", "9"],
  ],
  "25/03/2024 to 31/03/2024": [
    ["560", "1"],
    ["150", "6"],
    ["336", "2"],
    ["379", "9"],
    ["678", "1"],
    ["689", "3"],
    ["360", "9"],
  ],
  "01/04/2024 to 07/04/2024": [
    ["140", "5"],
    ["347", "4"],
    ["349", "6"],
    ["227", "1"],
    ["247", "3"],
    ["560", "1"],
    ["467", "7"],
  ],
  "08/04/2024 to 14/04/2024": [
    ["356", "4"],
    ["589", "2"],
    ["235", "0"],
    ["258", "5"],
    ["990", "8"],
    ["568", "9"],
    ["257", "4"],
  ],
  "15/04/2024 to 21/04/2024": [
    ["248", "4"],
    ["468", "8"],
    ["278", "7"],
    ["569", "0"],
    ["300", "3"],
    ["379", "9"],
    ["480", "2"],
  ],
  "22/04/2024 to 28/04/2024": [
    ["589", "2"],
    ["479", "0"],
    ["478", "9"],
    ["236", "1"],
    ["389", "0"],
    ["115", "7"],
    ["556", "6"],
  ],
  "29/04/2024 to 05/05/2024": [
    ["568", "9"],
    ["389", "0"],
    ["378", "8"],
    ["138", "2"],
    ["139", "3"],
    ["189", "8"],
    ["478", "9"],
  ],
  "05/06/2024 to 11/06/2024": [
    ["237", "2"],
    ["690", "5"],
    ["468", "8"],
    ["588", "1"],
    ["115", "7"],
    ["227", "1"],
    ["560", "1"],
  ],
  "12/06/2024 to 18/06/2024": [
    ["389", "0"],
    ["389", "0"],
    ["680", "4"],
    ["489", "1"],
    ["289", "9"],
    ["456", "5"],
    ["112", "4"],
  ],
  "19/06/2024 to 25/06/2024": [
    ["245", "1"],
    ["124", "7"],
    ["257", "4"],
    ["477", "8"],
    ["780", "5"],
    ["157", "3"],
    ["560", "1"],
  ],
  "26/06/2024 to 02/07/2024": [
    ["330", "6"],
    ["239", "4"],
    ["468", "8"],
    ["225", "9"],
    ["370", "0"],
    ["369", "8"],
    ["136", "0"],
  ],
  "03/07/2024 to 09/07/2024": [
    ["356", "4"],
    ["178", "6"],
    ["890", "7"],
    ["258", "5"],
    ["248", "4"],
    ["667", "9"],
    ["346", "3"],
  ],
  "10/07/2024 to 16/07/2024": [
    ["390", "2"],
    ["378", "8"],
    ["490", "3"],
    ["256", "3"],
    ["246", "2"],
    ["349", "6"],
    ["389", "0"],
  ],
  "17/07/2024 to 23/07/2024": [
    ["130", "4"],
    ["588", "1"],
    ["235", "0"],
    ["490", "3"],
    ["589", "2"],
    ["356", "4"],
    ["460", "0"],
  ],
  "24/07/2024 to 30/07/2024": [
    ["377", "7"],
    ["168", "5"],
    ["569", "0"],
    ["450", "9"],
    ["567", "8"],
    ["590", "4"],
    ["244", "0"],
  ],
  "31/07/2024 to 06/08/2024": [
    ["469", "9"],
    ["370", "0"],
    ["680", "4"],
    ["224", "8"],
    ["357", "5"],
    ["550", "0"],
    ["137", "1"],
  ],
  "07/08/2024 to 13/08/2024": [
    ["467", "7"],
    ["578", "0"],
    ["234", "9"],
    ["149", "4"],
    ["339", "5"],
    ["470", "1"],
    ["270", "9"],
  ],
  "14/08/2024 to 20/08/2024": [
    ["158", "4"],
    ["128", "1"],
    ["346", "3"],
    ["460", "0"],
    ["227", "1"],
    ["368", "7"],
    ["359", "7"],
  ],
  "21/08/2024 to 27/08/2024": [
    ["139", "3"],
    ["236", "1"],
    ["238", "3"],
    ["160", "7"],
    ["289", "9"],
    ["336", "2"],
    ["358", "6"],
  ],
  "28/08/2024 to 03/09/2024": [
    ["140", "5"],
    ["250", "7"],
    ["177", "5"],
    ["380", "1"],
    ["790", "6"],
    ["228", "2"],
    ["499", "2"],
  ],
  "04/09/2024 to 10/09/2024": [
    ["447", "5"],
    ["290", "1"],
    ["480", "2"],
    ["778", "2"],
    ["134", "8"],
    ["120", "3"],
    ["468", "8"],
  ],
  "08/12/2024 to 08/18/2024": [
    ["168", "5"],
    ["277", "6"],
    ["390", "2"],
    ["347", "4"],
    ["170", "8"],
    ["140", "5"],
    ["290", "1"],
  ],
  "19/08/2024 to 25/08/2024": [
    ["680", "4"],
    ["699", "4"],
    ["277", "6"],
    ["119", "1"],
    ["460", "0"],
    ["370", "0"],
    ["137", "1"],
  ],
  "26/08/2024 to 01/09/2024": [
    ["577", "9"],
    ["570", "2"],
    ["269", "7"],
    ["127", "0"],
    ["268", "6"],
    ["137", "1"],
    ["267", "5"],
  ],
  "02/09/2024 to 08/09/2024": [
    ["149", "4"],
    ["236", "1"],
    ["450", "9"],
    ["249", "5"],
    ["338", "4"],
    ["390", "2"],
    ["140", "5"],
  ],
  "09/09/2024 to 15/09/2024": [
    ["479", "0"],
    ["480", "2"],
    ["149", "4"],
    ["380", "1"],
    ["348", "5"],
    ["450", "9"],
    ["117", "9"],
  ],
  "16/09/2024 to 22/09/2024": [
    ["235", "0"],
    ["127", "0"],
    ["349", "6"],
    ["600", "6"],
    ["590", "4"],
    ["456", "5"],
    ["589", "2"],
  ],
  "23/09/2024 to 29/09/2024": [
    ["680", "4"],
    ["155", "1"],
    ["567", "8"],
    ["145", "0"],
    ["250", "7"],
    ["225", "9"],
    ["349", "6"],
  ],
  "30/09/2024 to 06/10/2024": [
    ["167", "4"],
    ["340", "7"],
    ["670", "3"],
    ["166", "3"],
    ["578", "0"],
    ["557", "7"],
    ["117", "9"],
  ],
};
export const Eight = {
  "01/01/2024 to 01/07/2024": [
    ["458", "7"],
    ["699", "4"],
    ["990", "8"],
    ["999", "7"],
    ["356", "4"],
    ["477", "8"],
    ["555", "5"],
  ],
  "08/01/2024 to 14/01/2024": [
    ["899", "6"],
    ["220", "4"],
    ["888", "4"],
    ["889", "5"],
    ["699", "4"],
    ["123", "6"],
    ["359", "7"],
  ],
  "15/01/2024 to 21/01/2024": [
    ["479", "0"],
    ["358", "6"],
    ["129", "2"],
    ["250", "7"],
    ["789", "4"],
    ["258", "5"],
    ["157", "3"],
  ],
  "22/01/2024 to 28/01/2024": [
    ["269", "7"],
    ["689", "3"],
    ["689", "3"],
    ["490", "3"],
    ["469", "9"],
    ["589", "2"],
    ["346", "3"],
  ],
  "29/01/2024 to 04/02/2024": [
    ["258", "5"],
    ["456", "5"],
    ["358", "6"],
    ["140", "5"],
    ["790", "6"],
    ["568", "9"],
    ["356", "4"],
  ],

  "05/02/2024 to 11/02/2024": [
    ["180", "9"],
    ["358", "6"],
    ["140", "5"],
    ["144", "9"],
    ["258", "5"],
    ["140", "5"],
    ["690", "5"],
  ],
  "12/02/2024 to 18/02/2024": [
    ["289", "9"],
    ["340", "7"],
    ["468", "8"],
    ["356", "4"],
    ["467", "7"],
    ["147", "2"],
    ["367", "6"],
  ],
  "19/02/2024 to 25/02/2024": [
    ["568", "9"],
    ["467", "7"],
    ["459", "8"],
    ["689", "3"],
    ["900", "9"],
    ["344", "1"],
    ["245", "1"],
  ],
  "26/02/2024 to 03/03/2024": [
    ["459", "8"],
    ["468", "8"],
    ["167", "4"],
    ["280", "0"],
    ["230", "5"],
    ["145", "0"],
    ["480", "2"],
  ],
  "04/03/2024 to 10/03/2024": [
    ["129", "2"],
    ["148", "3"],
    ["288", "8"],
    ["249", "5"],
    ["159", "5"],
    ["257", "4"],
    ["123", "6"],
  ],
  "11/03/2024 to 17/03/2024": [
    ["780", "5"],
    ["588", "1"],
    ["148", "3"],
    ["130", "4"],
    ["788", "3"],
    ["690", "5"],
    ["140", "5"],
  ],
  "18/03/2024 to 24/03/2024": [
    ["458", "7"],
    ["220", "4"],
    ["100", "1"],
    ["489", "1"],
    ["670", "3"],
    ["237", "2"],
    ["277", "6"],
  ],
  "25/03/2024 to 31/03/2024": [
    ["123", "6"],
    ["467", "7"],
    ["890", "7"],
    ["567", "8"],
    ["236", "1"],
    ["168", "5"],
    ["689", "3"],
  ],
  "01/04/2024 to 07/04/2024": [
    ["245", "1"],
    ["224", "8"],
    ["260", "8"],
    ["346", "3"],
    ["558", "8"],
    ["225", "9"],
    ["256", "3"],
  ],
  "04/08/2024 to 04/14/2024": [
    ["567", "8"],
    ["367", "6"],
    ["356", "4"],
    ["670", "3"],
    ["247", "3"],
    ["570", "2"],
    ["168", "5"],
  ],
  "15/04/2024 to 21/04/2024": [
    ["457", "6"],
    ["349", "6"],
    ["359", "7"],
    ["460", "0"],
    ["669", "1"],
    ["468", "8"],
    ["137", "1"],
  ],
  "22/04/2024 to 28/04/2024": [
    ["790", "6"],
    ["268", "6"],
    ["560", "1"],
    ["468", "8"],
    ["456", "5"],
    ["158", "4"],
    ["680", "4"],
  ],
  "29/04/2024 to 05/05/2024": [
    ["458", "7"],
    ["677", "0"],
    ["340", "7"],
    ["226", "0"],
    ["379", "9"],
    ["340", "7"],
    ["267", "5"],
  ],
  "06/05/2024 to 12/05/2024": [
    ["110", "2"],
    ["259", "6"],
    ["457", "6"],
    ["579", "1"],
    ["249", "5"],
    ["155", "1"],
    ["127", "0"],
  ],
  "13/05/2024 to 19/05/2024": [
    ["268", "6"],
    ["468", "8"],
    ["126", "9"],
    ["460", "0"],
    ["125", "8"],
    ["230", "5"],
    ["347", "4"],
  ],
  "20/05/2024 to 26/05/2024": [
    ["158", "4"],
    ["299", "0"],
    ["148", "3"],
    ["238", "3"],
    ["237", "2"],
    ["125", "8"],
    ["590", "4"],
  ],
  "27/05/2024 to 02/06/2024": [
    ["258", "5"],
    ["116", "8"],
    ["380", "1"],
    ["790", "6"],
    ["458", "7"],
    ["123", "6"],
    ["167", "4"],
  ],
  "03/06/2024 to 09/06/2024": [
    ["480", "2"],
    ["336", "2"],
    ["556", "6"],
    ["347", "4"],
    ["336", "2"],
    ["459", "8"],
    ["126", "9"],
  ],
  "10/06/2024 to 16/06/2024": [
    ["238", "3"],
    ["367", "6"],
    ["236", "1"],
    ["134", "8"],
    ["350", "8"],
    ["128", "1"],
    ["159", "5"],
  ],
  "17/06/2024 to 23/06/2024": [
    ["124", "7"],
    ["136", "0"],
    ["137", "1"],
    ["156", "2"],
    ["389", "0"],
    ["179", "7"],
    ["238", "3"],
  ],
  "24/06/2024 to 30/06/2024": [
    ["140", "5"],
    ["347", "4"],
    ["138", "2"],
    ["167", "4"],
    ["689", "3"],
    ["125", "8"],
    ["467", "7"],
  ],
  "01/07/2024 to 07/07/2024": [
    ["566", "7"],
    ["166", "3"],
    ["169", "6"],
    ["458", "7"],
    ["579", "1"],
    ["570", "2"],
    ["140", "5"],
  ],
  "08/07/2024 to 14/07/2024": [
    ["669", "1"],
    ["367", "6"],
    ["459", "8"],
    ["368", "7"],
    ["136", "0"],
    ["499", "2"],
    ["456", "5"],
  ],
  "15/07/2024 to 21/07/2024": [
    ["235", "0"],
    ["445", "3"],
    ["789", "4"],
    ["279", "8"],
    ["447", "5"],
    ["578", "0"],
    ["500", "5"],
  ],
  "22/07/2024 to 28/07/2024": [
    ["459", "8"],
    ["258", "5"],
    ["270", "9"],
    ["228", "2"],
    ["580", "3"],
    ["677", "0"],
    ["158", "4"],
  ],
  "29/07/2024 to 04/08/2024": [
    ["556", "6"],
    ["145", "0"],
    ["189", "8"],
    ["467", "7"],
    ["349", "6"],
    ["147", "2"],
    ["118", "0"],
  ],
  "05/08/2024 to 11/08/2024": [
    ["236", "1"],
    ["233", "8"],
    ["890", "7"],
    ["550", "0"],
    ["259", "6"],
    ["166", "3"],
    ["690", "5"],
  ],
  "12/08/2024 to 18/08/2024": [
    ["190", "0"],
    ["379", "9"],
    ["235", "0"],
    ["890", "7"],
    ["244", "0"],
    ["457", "6"],
    ["126", "9"],
  ],
  "19/08/2024 to 25/08/2024": [
    ["147", "2"],
    ["390", "2"],
    ["226", "0"],
    ["556", "6"],
    ["156", "2"],
    ["390", "2"],
    ["267", "5"],
  ],
  "26/08/2024 to 01/09/2024": [
    ["336", "2"],
    ["444", "2"],
    ["366", "5"],
    ["460", "0"],
    ["900", "9"],
    ["559", "9"],
    ["468", "8"],
  ],
  "02/09/2024 to 08/09/2024": [
    ["580", "3"],
    ["379", "9"],
    ["160", "7"],
    ["457", "6"],
    ["480", "2"],
    ["600", "6"],
    ["449", "7"],
  ],
  "09/09/2024 to 15/09/2024": [
    ["567", "8"],
    ["159", "5"],
    ["336", "2"],
    ["300", "3"],
    ["178", "6"],
    ["379", "9"],
    ["150", "6"],
  ],
  "16/09/2024 to 22/09/2024": [
    ["156", "2"],
    ["689", "3"],
    ["148", "3"],
    ["227", "1"],
    ["279", "8"],
    ["117", "9"],
    ["150", "6"],
  ],
  "23/09/2024 to 29/09/2024": [
    ["135", "9"],
    ["245", "1"],
    ["670", "3"],
    ["248", "4"],
    ["388", "9"],
    ["150", "6"],
    ["380", "1"],
  ],
  "30/09/2024 to 06/10/2024": [
    ["999", "7"],
    ["489", "1"],
    ["146", "1"],
    ["370", "0"],
    ["445", "3"],
    ["137", "1"],
    ["168", "5"],
  ],
};

export const Nine = {
  "01/01/2024 to 07/01/2024": [
    ["125", "8"],
    ["999", "7"],
    ["222", "6"],
    ["222", "6"],
    ["223", "7"],
    ["777", "1"],
    ["230", "5"],
  ],
  "08/01/2024 to 14/01/2024": [
    ["800", "8"],
    ["668", "0"],
    ["258", "5"],
    ["236", "1"],
    ["359", "7"],
    ["569", "0"],
    ["255", "2"],
  ],
  "15/01/2024 to 21/01/2024": [
    ["590", "4"],
    ["123", "6"],
    ["230", "5"],
    ["159", "5"],
    ["348", "5"],
    ["280", "0"],
    ["890", "7"],
  ],

  "22/01/2024 to 28/01/2024": [
    ["489", "1"],
    ["246", "2"],
    ["148", "3"],
    ["890", "7"],
    ["367", "6"],
    ["446", "4"],
    ["450", "9"],
  ],
  "29/01/2024 to 04/02/2024": [
    ["569", "0"],
    ["149", "4"],
    ["278", "7"],
    ["246", "2"],
    ["289", "9"],
    ["356", "4"],
    ["139", "3"],
  ],
  "05/02/2024 to 11/02/2024": [
    ["467", "7"],
    ["355", "3"],
    ["235", "0"],
    ["890", "7"],
    ["110", "2"],
    ["467", "7"],
    ["136", "0"],
  ],
  "12/02/2024 to 18/02/2024": [
    ["134", "8"],
    ["145", "0"],
    ["458", "7"],
    ["234", "9"],
    ["346", "3"],
    ["290", "1"],
    ["330", "6"],
  ],
  "19/02/2024 to 25/02/2024": [
    ["145", "0"],
    ["577", "9"],
    ["467", "7"],
    ["457", "6"],
    ["356", "4"],
    ["123", "6"],
    ["157", "3"],
  ],
  "26/02/2024 to 03/03/2024": [
    ["125", "8"],
    ["259", "6"],
    ["457", "6"],
    ["134", "8"],
    ["370", "0"],
    ["129", "2"],
    ["125", "8"],
  ],
  "04/03/2024 to 10/03/2024": [
    ["150", "6"],
    ["120", "3"],
    ["145", "0"],
    ["137", "1"],
    ["180", "9"],
    ["126", "9"],
    ["180", "9"],
  ],
  "11/03/2024 to 17/03/2024": [
    ["160", "7"],
    ["199", "9"],
    ["128", "1"],
    ["568", "9"],
    ["680", "4"],
    ["135", "9"],
    ["389", "0"],
  ],
  "18/03/2024 to 24/03/2024": [
    ["489", "1"],
    ["389", "0"],
    ["246", "2"],
    ["348", "5"],
    ["799", "5"],
    ["368", "7"],
    ["235", "0"],
  ],
  "25/03/2024 to 31/03/2024": [
    ["457", "6"],
    ["799", "5"],
    ["369", "8"],
    ["234", "9"],
    ["790", "6"],
    ["237", "2"],
    ["357", "5"],
  ],
  "01/04/2024 to 07/04/2024": [
    ["119", "1"],
    ["680", "4"],
    ["129", "2"],
    ["149", "4"],
    ["367", "6"],
    ["890", "7"],
    ["379", "9"],
  ],
  "08/04/2024 to 14/04/2024": [
    ["780", "5"],
    ["458", "7"],
    ["234", "9"],
    ["257", "4"],
    ["578", "0"],
    ["277", "6"],
    ["479", "0"],
  ],
  "15/04/2024 to 21/04/2024": [
    ["115", "7"],
    ["234", "9"],
    ["490", "3"],
    ["557", "7"],
    ["159", "5"],
    ["245", "1"],
    ["245", "1"],
  ],
  "22/04/2024 to 28/04/2024": [
    ["129", "2"],
    ["780", "5"],
    ["140", "5"],
    ["457", "6"],
    ["360", "9"],
    ["359", "7"],
    ["480", "2"],
  ],
  "29/04/2024 to 05/05/2024": [
    ["346", "3"],
    ["780", "5"],
    ["116", "8"],
    ["146", "1"],
    ["257", "4"],
    ["470", "1"],
    ["889", "5"],
  ],
  "06/05/2024 to 12/05/2024": [
    ["245", "1"],
    ["370", "0"],
    ["359", "7"],
    ["490", "3"],
    ["113", "5"],
    ["120", "3"],
    ["125", "8"],
  ],

  "13/05/2024 to 19/05/2024": [
    ["114", "6"],
    ["370", "0"],
    ["139", "3"],
    ["134", "8"],
    ["178", "6"],
    ["458", "7"],
    ["688", "2"],
  ],
  "20/05/2024 to 26/05/2024": [
    ["488", "0"],
    ["113", "5"],
    ["568", "9"],
    ["578", "0"],
    ["340", "7"],
    ["246", "2"],
    ["369", "8"],
  ],
  "27/05/2024 to 02/06/2024": [
    ["358", "6"],
    ["270", "9"],
    ["122", "5"],
    ["469", "9"],
    ["589", "2"],
    ["390", "2"],
    ["367", "6"],
  ],
  "03/06/2024 to 09/06/2024": [
    ["120", "3"],
    ["137", "1"],
    ["678", "1"],
    ["779", "3"],
    ["568", "9"],
    ["288", "8"],
    ["280", "0"],
  ],
  "10/06/2024 to 16/06/2024": [
    ["679", "2"],
    ["770", "4"],
    ["589", "2"],
    ["289", "9"],
    ["889", "5"],
    ["190", "0"],
    ["250", "7"],
  ],
  "17/06/2024 to 23/06/2024": [
    ["390", "2"],
    ["239", "4"],
    ["889", "5"],
    ["569", "0"],
    ["450", "9"],
    ["249", "5"],
    ["279", "8"],
  ],
  "24/06/2024 to 30/06/2024": [
    ["367", "6"],
    ["236", "1"],
    ["268", "6"],
    ["233", "8"],
    ["346", "3"],
    ["130", "4"],
    ["345", "2"],
  ],
  "01/07/2024 to 07/07/2024": [
    ["226", "0"],
    ["257", "4"],
    ["467", "7"],
    ["136", "0"],
    ["160", "7"],
    ["279", "8"],
    ["120", "3"],
  ],
  "08/07/2024 to 14/07/2024": [
    ["478", "9"],
    ["256", "3"],
    ["159", "5"],
    ["237", "2"],
    ["257", "4"],
    ["116", "8"],
    ["269", "7"],
  ],
  "15/07/2024 to 21/07/2024": [
    ["168", "5"],
    ["112", "4"],
    ["156", "2"],
    ["347", "4"],
    ["140", "5"],
    ["240", "6"],
    ["270", "9"],
  ],
  "22/07/2024 to 28/07/2024": [
    ["368", "7"],
    ["350", "8"],
    ["168", "5"],
    ["379", "9"],
    ["448", "6"],
    ["110", "2"],
    ["355", "3"],
  ],
  "29/07/2024 to 04/08/2024": [
    ["137", "1"],
    ["234", "9"],
    ["229", "3"],
    ["235", "0"],
    ["579", "1"],
    ["490", "3"],
    ["380", "1"],
  ],
  "05/08/2024 to 11/08/2024": [
    ["160", "7"],
    ["134", "8"],
    ["169", "6"],
    ["126", "9"],
    ["789", "4"],
    ["677", "0"],
    ["359", "7"],
  ],
  "12/08/2024 to 18/08/2024": [
    ["599", "3"],
    ["790", "6"],
    ["179", "7"],
    ["359", "7"],
    ["260", "8"],
    ["360", "9"],
    ["240", "6"],
  ],
  "19/08/2024 to 25/08/2024": [
    ["290", "1"],
    ["246", "2"],
    ["159", "5"],
    ["111", "3"],
    ["468", "8"],
    ["169", "6"],
    ["135", "9"],
  ],
  "26/08/2024 to 01/09/2024": [
    ["238", "3"],
    ["337", "3"],
    ["267", "5"],
    ["128", "1"],
    ["790", "6"],
    ["145", "0"],
    ["567", "8"],
  ],
  "02/09/2024 to 08/09/2024": [
    ["335", "1"],
    ["225", "9"],
    ["678", "1"],
    ["330", "6"],
    ["289", "9"],
    ["178", "6"],
    ["255", "2"],
  ],
  "09/09/2024 to 15/09/2024": [
    ["680", "4"],
    ["157", "3"],
    ["679", "2"],
    ["560", "1"],
    ["237", "2"],
    ["267", "5"],
    ["144", "9"],
  ],
  "16/09/2024 to 22/09/2024": [
    ["344", "1"],
    ["239", "4"],
    ["448", "6"],
    ["268", "6"],
    ["127", "0"],
    ["290", "1"],
    ["259", "6"],
  ],
  "23/09/2024 to 29/09/2024": [
    ["490", "3"],
    ["368", "7"],
    ["126", "9"],
    ["127", "0"],
    ["269", "7"],
    ["678", "1"],
    ["240", "6"],
  ],
  "30/09/2024 to 06/10/2024": [
    ["390", "2"],
    ["460", "0"],
    ["116", "8"],
    ["236", "1"],
    ["379", "9"],
    ["255", "2"],
    ["578", "0"],
  ],
};

export const Ten = {
  "01/01/2024 to 01/07/2024": [
    ["123", "6"],
    ["888", "4"],
    ["778", "2"],
    ["222", "6"],
    ["147", "2"],
    ["123", "6"],
    ["125", "8"],
  ],
  "01/08/2024 to 01/14/2024": [
    ["456", "5"],
    ["555", "5"],
    ["127", "0"],
    ["0", "0"],
    ["458", "7"],
    ["115", "7"],
    ["460", "0"],
  ],
  "15/01/2024 to 21/01/2024": [
    ["359", "7"],
    ["456", "5"],
    ["460", "0"],
    ["123", "6"],
    ["458", "7"],
    ["123", "6"],
    ["357", "5"],
  ],
  "22/01/2024 to 28/01/2024": [
    ["456", "5"],
    ["249", "5"],
    ["358", "6"],
    ["290", "1"],
    ["130", "4"],
    ["129", "2"],
    ["235", "0"],
  ],
  "29/01/2024 to 04/02/2024": [
    ["120", "3"],
    ["289", "9"],
    ["348", "5"],
    ["167", "4"],
    ["156", "2"],
    ["123", "6"],
    ["370", "0"],
  ],
  "05/02/2024 to 11/02/2024": [
    ["179", "7"],
    ["245", "1"],
    ["369", "8"],
    ["459", "8"],
    ["890", "7"],
    ["160", "7"],
    ["389", "0"],
  ],
  "12/02/2024 to 18/02/2024": [
    ["670", "3"],
    ["349", "6"],
    ["560", "1"],
    ["337", "3"],
    ["100", "1"],
    ["145", "0"],
    ["145", "0"],
  ],
  "19/02/2024 to 25/02/2024": [
    ["348", "5"],
    ["357", "5"],
    ["155", "1"],
    ["145", "0"],
    ["370", "0"],
    ["190", "0"],
    ["460", "0"],
  ],
  "26/02/2024 to 03/03/2024": [
    ["289", "9"],
    ["467", "7"],
    ["247", "3"],
    ["347", "4"],
    ["140", "5"],
    ["347", "4"],
    ["377", "7"],
  ],
  "04/03/2024 to 10/03/2024": [
    ["358", "6"],
    ["220", "4"],
    ["299", "0"],
    ["589", "2"],
    ["389", "0"],
    ["356", "4"],
    ["467", "7"],
  ],
  "11/03/2024 to 17/03/2024": [
    ["140", "5"],
    ["149", "4"],
    ["448", "6"],
    ["566", "7"],
    ["200", "2"],
    ["345", "2"],
    ["128", "1"],
  ],
  "18/03/2024 to 24/03/2024": [
    ["136", "0"],
    ["269", "7"],
    ["114", "6"],
    ["115", "7"],
    ["490", "3"],
    ["489", "1"],
    ["158", "4"],
  ],
  "25/03/2024 to 31/03/2024": [
    ["269", "7"],
    ["129", "2"],
    ["127", "0"],
    ["169", "6"],
    ["449", "7"],
    ["238", "3"],
    ["235", "0"],
  ],
  "01/04/2024 to 07/04/2024": [
    ["149", "4"],
    ["236", "1"],
    ["568", "9"],
    ["789", "4"],
    ["568", "9"],
    ["356", "4"],
    ["290", "1"],
  ],
  "08/04/2024 to 14/04/2024": [
    ["450", "9"],
    ["118", "0"],
    ["249", "5"],
    ["567", "8"],
    ["349", "6"],
    ["588", "1"],
    ["379", "9"],
  ],
  "15/04/2024 to 21/04/2024": [
    ["229", "3"],
    ["778", "2"],
    ["114", "6"],
    ["338", "4"],
    ["235", "0"],
    ["189", "8"],
    ["690", "5"],
  ],
  "22/04/2024 to 28/04/2024": [
    ["379", "9"],
    ["490", "3"],
    ["460", "0"],
    ["136", "0"],
    ["480", "2"],
    ["178", "6"],
    ["230", "5"],
  ],
  "29/04/2024 to 05/05/2024": [
    ["466", "6"],
    ["450", "9"],
    ["360", "9"],
    ["250", "7"],
    ["156", "2"],
    ["600", "6"],
    ["340", "7"],
  ],
  "06/05/2024 to 12/05/2024": [
    ["357", "5"],
    ["390", "2"],
    ["135", "9"],
    ["379", "9"],
    ["890", "7"],
    ["150", "6"],
    ["790", "6"],
  ],
  "13/05/2024 to 19/05/2024": [
    ["450", "9"],
    ["288", "8"],
    ["449", "7"],
    ["138", "2"],
    ["990", "8"],
    ["459", "8"],
    ["129", "2"],
  ],
  "20/05/2024 to 26/05/2024": [
    ["168", "5"],
    ["350", "8"],
    ["468", "8"],
    ["234", "9"],
    ["289", "9"],
    ["479", "0"],
    ["116", "8"],
  ],
  "27/05/2024 to 02/06/2024": [
    ["478", "9"],
    ["227", "1"],
    ["578", "0"],
    ["480", "2"],
    ["469", "9"],
    ["240", "6"],
    ["237", "2"],
  ],
  "03/06/2024 to 09/06/2024": [
    ["179", "7"],
    ["578", "0"],
    ["570", "2"],
    ["180", "9"],
    ["689", "3"],
    ["578", "0"],
    ["360", "9"],
  ],
  "10/06/2024 to 16/06/2024": [
    ["460", "0"],
    ["110", "2"],
    ["478", "9"],
    ["190", "0"],
    ["139", "3"],
    ["348", "5"],
    ["157", "3"],
  ],
  "17/06/2024 to 23/06/2024": [
    ["569", "0"],
    ["369", "8"],
    ["237", "2"],
    ["477", "8"],
    ["139", "3"],
    ["457", "6"],
    ["127", "0"],
  ],
  "24/06/2024 to 30/06/2024": [
    ["490", "3"],
    ["289", "9"],
    ["149", "4"],
    ["114", "6"],
    ["118", "0"],
    ["237", "2"],
    ["458", "7"],
  ],
  "01/07/2024 to 07/07/2024": [
    ["189", "8"],
    ["369", "8"],
    ["230", "5"],
    ["269", "7"],
    ["338", "4"],
    ["144", "9"],
    ["130", "4"],
  ],
  "08/07/2024 to 14/07/2024": [
    ["123", "6"],
    ["366", "5"],
    ["220", "4"],
    ["390", "2"],
    ["340", "7"],
    ["370", "0"],
    ["889", "5"],
  ],
  "15/07/2024 to 21/07/2024": [
    ["157", "3"],
    ["450", "9"],
    ["339", "5"],
    ["139", "3"],
    ["340", "7"],
    ["146", "1"],
    ["137", "1"],
  ],
  "22/07/2024 to 28/07/2024": [
    ["226", "0"],
    ["245", "1"],
    ["189", "8"],
    ["780", "5"],
    ["126", "9"],
    ["800", "8"],
    ["380", "1"],
  ],
  "29/07/2024 to 04/08/2024": [
    ["590", "4"],
    ["457", "6"],
    ["270", "9"],
    ["678", "1"],
    ["456", "5"],
    ["266", "4"],
    ["138", "2"],
  ],
  "05/08/2024 to 11/08/2024": [
    ["257", "4"],
    ["360", "9"],
    ["114", "6"],
    ["470", "1"],
    ["457", "6"],
    ["288", "8"],
    ["580", "3"],
  ],
  "12/08/2024 to 18/08/2024": [
    ["448", "6"],
    ["127", "0"],
    ["346", "3"],
    ["126", "9"],
    ["669", "1"],
    ["478", "9"],
    ["112", "4"],
  ],
  "19/08/2024 to 25/08/2024": [
    ["234", "9"],
    ["350", "8"],
    ["137", "1"],
    ["578", "0"],
    ["168", "5"],
    ["239", "4"],
    ["590", "4"],
  ],
  "26/08/2024 to 01/09/2024": [
    ["458", "7"],
    ["778", "2"],
    ["469", "9"],
    ["159", "5"],
    ["458", "7"],
    ["150", "6"],
    ["770", "4"],
  ],
  "02/09/2024 to 08/09/2024": [
    ["167", "4"],
    ["500", "5"],
    ["237", "2"],
    ["570", "2"],
    ["179", "7"],
    ["236", "1"],
    ["470", "1"],
  ],
  "09/09/2024 to 15/09/2024": [
    ["569", "0"],
    ["156", "2"],
    ["377", "7"],
    ["127", "0"],
    ["248", "4"],
    ["117", "9"],
    ["158", "4"],
  ],
  "16/09/2024 to 22/09/2024": [
    ["350", "8"],
    ["266", "4"],
    ["460", "0"],
    ["559", "9"],
    ["178", "6"],
    ["399", "1"],
    ["569", "0"],
  ],
  "23/09/2024 to 29/09/2024": [
    ["389", "0"],
    ["270", "9"],
    ["137", "1"],
    ["168", "5"],
    ["234", "9"],
    ["480", "2"],
    ["690", "5"],
  ],
  "30/09/2024 to 06/10/2024": [
    ["289", "9"],
    ["568", "9"],
    ["334", "0"],
    ["338", "4"],
    ["290", "1"],
    ["124", "7"],
    ["779", "3"],
  ],
};
export const Eleven = {
  "01/01/2024 to 07/01/2024": [
    ["789", "4"],
    ["457", "6"],
    ["445", "3"],
    ["666", "8"],
    ["288", "8"],
    ["489", "1"],
    ["458", "7"],
  ],
  "08/01/2024 to 14/01/2024": [
    ["125", "8"],
    ["699", "4"],
    ["236", "1"],
    ["558", "8"],
    ["123", "6"],
    ["238", "3"],
    ["129", "2"],
  ],
  "15/01/2024 to 21/01/2024": [
    ["380", "1"],
    ["789", "4"],
    ["123", "6"],
    ["268", "6"],
    ["149", "4"],
    ["478", "9"],
    ["147", "2"],
  ],
  "22/01/2024 to 28/01/2024": [
    ["125", "8"],
    ["349", "6"],
    ["450", "9"],
    ["456", "5"],
    ["138", "2"],
    ["457", "6"],
    ["679", "2"],
  ],
  "29/01/2024 to 04/02/2024": [
    ["134", "8"],
    ["123", "6"],
    ["189", "8"],
    ["456", "5"],
    ["270", "9"],
    ["234", "9"],
    ["347", "4"],
  ],
  "05/02/2024 to 11/02/2024": [
    ["245", "1"],
    ["578", "0"],
    ["148", "3"],
    ["569", "0"],
    ["190", "0"],
    ["569", "0"],
    ["569", "0"],
  ],
  "12/02/2024 to 18/02/2024": [
    ["345", "2"],
    ["123", "6"],
    ["145", "0"],
    ["245", "1"],
    ["356", "4"],
    ["550", "0"],
    ["459", "8"],
  ],
  "19/02/2024 to 25/02/2024": [
    ["122", "5"],
    ["100", "1"],
    ["145", "0"],
    ["489", "1"],
    ["245", "1"],
    ["257", "4"],
    ["256", "3"],
  ],
  "26/02/2024 to 03/03/2024": [
    ["470", "1"],
    ["379", "9"],
    ["356", "4"],
    ["489", "1"],
    ["359", "7"],
    ["140", "5"],
    ["370", "0"],
  ],
  "04/03/2024 to 10/03/2024": [
    ["340", "7"],
    ["160", "7"],
    ["170", "8"],
    ["220", "4"],
    ["257", "4"],
    ["258", "5"],
    ["258", "5"],
  ],
  "11/03/2024 to 17/03/2024": [
    ["247", "3"],
    ["247", "3"],
    ["246", "2"],
    ["660", "2"],
    ["280", "0"],
    ["133", "7"],
    ["677", "0"],
  ],
  "18/03/2024 to 24/03/2024": [
    ["239", "4"],
    ["350", "8"],
    ["450", "9"],
    ["590", "4"],
    ["259", "6"],
    ["345", "2"],
    ["360", "9"],
  ],
  "25/03/2024 to 31/03/2024": [
    ["389", "0"],
    ["160", "7"],
    ["220", "4"],
    ["470", "1"],
    ["590", "4"],
    ["466", "6"],
    ["348", "5"],
  ],
  "04/01/2024 to 04/07/2024": [
    ["778", "2"],
    ["589", "2"],
    ["689", "3"],
    ["145", "0"],
    ["480", "2"],
    ["267", "5"],
    ["358", "6"],
  ],
  "08/04/2024 to 14/04/2024": [
    ["589", "2"],
    ["280", "0"],
    ["890", "7"],
    ["359", "7"],
    ["167", "4"],
    ["118", "0"],
    ["256", "3"],
  ],
  "15/04/2024 to 21/04/2024": [
    ["390", "2"],
    ["159", "5"],
    ["145", "0"],
    ["130", "4"],
    ["237", "2"],
    ["350", "8"],
    ["330", "6"],
  ],
  "22/04/2024 to 28/04/2024": [
    ["590", "4"],
    ["124", "7"],
    ["699", "4"],
    ["270", "9"],
    ["779", "3"],
    ["570", "2"],
    ["229", "3"],
  ],
  "29/04/2024 to 05/05/2024": [
    ["170", "8"],
    ["129", "2"],
    ["140", "5"],
    ["129", "2"],
    ["556", "6"],
    ["137", "1"],
    ["136", "0"],
  ],
  "06/05/2024 to 12/05/2024": [
    ["567", "8"],
    ["489", "1"],
    ["460", "0"],
    ["258", "5"],
    ["367", "6"],
    ["346", "3"],
    ["130", "4"],
  ],
  "13/05/2024 to 19/05/2024": [
    ["780", "5"],
    ["145", "0"],
    ["128", "1"],
    ["169", "6"],
    ["160", "7"],
    ["110", "2"],
    ["235", "0"],
  ],
  "20/05/2024 to 26/05/2024": [
    ["338", "4"],
    ["268", "6"],
    ["389", "0"],
    ["147", "2"],
    ["368", "7"],
    ["160", "7"],
    ["178", "6"],
  ],
  "27/05/2024 to 02/06/2024": [
    ["226", "0"],
    ["240", "6"],
    ["166", "3"],
    ["125", "8"],
    ["248", "4"],
    ["117", "9"],
    ["470", "1"],
  ],
  "03/06/2024 to 09/06/2024": [
    ["279", "8"],
    ["159", "5"],
    ["134", "8"],
    ["245", "1"],
    ["366", "5"],
    ["344", "1"],
    ["125", "8"],
  ],
  "10/06/2024 to 16/06/2024": [
    ["188", "7"],
    ["167", "4"],
    ["588", "1"],
    ["160", "7"],
    ["799", "5"],
    ["127", "0"],
    ["499", "2"],
  ],
  "17/06/2024 to 23/06/2024": [
    ["169", "6"],
    ["458", "7"],
    ["338", "4"],
    ["136", "0"],
    ["455", "4"],
    ["346", "3"],
    ["270", "9"],
  ],
  "24/06/2024 to 30/06/2024": [
    ["235", "0"],
    ["135", "9"],
    ["190", "0"],
    ["679", "2"],
    ["130", "4"],
    ["147", "2"],
    ["260", "8"],
  ],
  "01/07/2024 to 07/07/2024": [
    ["560", "1"],
    ["589", "2"],
    ["346", "3"],
    ["245", "1"],
    ["157", "3"],
    ["330", "6"],
    ["400", "4"],
  ],

  "07/08/2024 to 07/14/2024": [
    ["229", "3"],
    ["679", "2"],
    ["350", "8"],
    ["248", "4"],
    ["144", "9"],
    ["233", "8"],
    ["137", "1"],
  ],
  "07/15/2024 to 07/21/2024": [
    ["250", "7"],
    ["569", "0"],
    ["390", "2"],
    ["468", "8"],
    ["126", "9"],
    ["280", "0"],
    ["159", "5"],
  ],
  "07/22/2024 to 07/28/2024": [
    ["179", "7"],
    ["488", "0"],
    ["223", "7"],
    ["568", "9"],
    ["237", "2"],
    ["280", "0"],
    ["559", "9"],
  ],
  "07/29/2024 to 08/04/2024": [
    ["359", "7"],
    ["159", "5"],
    ["134", "8"],
    ["366", "5"],
    ["233", "8"],
    ["120", "3"],
    ["500", "5"],
  ],
  "08/05/2024 to 08/11/2024": [
    ["370", "0"],
    ["250", "7"],
    ["269", "7"],
    ["156", "2"],
    ["239", "4"],
    ["190", "0"],
    ["169", "6"],
  ],
  "08/12/2024 to 08/18/2024": [
    ["280", "0"],
    ["689", "3"],
    ["237", "2"],
    ["356", "4"],
    ["368", "7"],
    ["224", "8"],
    ["469", "9"],
  ],
  "08/19/2024 to 08/25/2024": [
    ["456", "5"],
    ["889", "5"],
    ["235", "0"],
    ["799", "5"],
    ["277", "6"],
    ["367", "6"],
    ["679", "2"],
  ],
  "08/26/2024 to 09/01/2024": [
    ["148", "3"],
    ["899", "6"],
    ["259", "6"],
    ["334", "0"],
    ["245", "1"],
    ["237", "2"],
    ["689", "3"],
  ],
  "09/02/2024 to 09/08/2024": [
    ["339", "5"],
    ["466", "6"],
    ["670", "3"],
    ["226", "0"],
    ["367", "6"],
    ["128", "1"],
    ["568", "9"],
  ],
  "09/09/2024 to 09/15/2024": [
    ["457", "6"],
    ["278", "7"],
    ["280", "0"],
    ["337", "3"],
    ["146", "1"],
    ["270", "9"],
    ["300", "3"],
  ],
  "09/16/2024 to 09/22/2024": [
    ["225", "9"],
    ["122", "5"],
    ["155", "1"],
    ["289", "9"],
    ["255", "2"],
    ["226", "0"],
    ["116", "8"],
  ],
  "09/23/2024 to 09/29/2024": [
    ["246", "2"],
    ["256", "3"],
    ["233", "8"],
    ["360", "9"],
    ["334", "0"],
    ["235", "0"],
    ["140", "5"],
  ],
  "09/30/2024 to 10/06/2024": [
    ["358", "6"],
    ["679", "2"],
    ["256", "3"],
    ["567", "8"],
    ["577", "9"],
    ["130", "4"],
    ["570", "2"],
  ],
};
export const Twelve = {
  "01/01/2024 to 07/01/2024": [
    ["444", "2"],
    ["789", "4"],
    ["225", "9"],
    ["600", "6"],
    ["369", "8"],
    ["259", "6"],
    ["890", "7"],
  ],
  "08/01/2024 to 14/01/2024": [
    ["699", "4"],
    ["558", "8"],
    ["226", "0"],
    ["115", "7"],
    ["258", "5"],
    ["115", "7"],
    ["357", "5"],
  ],
  "15/01/2024 to 21/01/2024": [
    ["159", "5"],
    ["590", "4"],
    ["456", "5"],
    ["230", "5"],
    ["258", "5"],
    ["369", "8"],
    ["236", "1"],
  ],
  "22/01/2024 to 28/01/2024": [
    ["249", "5"],
    ["679", "2"],
    ["249", "5"],
    ["379", "9"],
    ["356", "4"],
    ["146", "1"],
    ["367", "6"],
  ],
  "29/01/2024 to 04/02/2024": [
    ["360", "9"],
    ["458", "7"],
    ["244", "0"],
    ["570", "2"],
    ["235", "0"],
    ["578", "0"],
    ["456", "5"],
  ],
  "05/02/2024 to 11/02/2024": [
    ["344", "1"],
    ["568", "9"],
    ["100", "1"],
    ["690", "5"],
    ["289", "9"],
    ["346", "3"],
    ["256", "3"],
  ],
  "12/02/2024 to 18/02/2024": [
    ["358", "6"],
    ["120", "3"],
    ["235", "0"],
    ["469", "9"],
    ["128", "1"],
    ["567", "8"],
    ["678", "1"],
  ],
  "19/02/2024 to 25/02/2024": [
    ["890", "7"],
    ["145", "0"],
    ["478", "9"],
    ["580", "3"],
    ["256", "3"],
    ["140", "5"],
    ["140", "5"],
  ],
  "26/02/2024 to 03/03/2024": [
    ["890", "7"],
    ["137", "1"],
    ["138", "2"],
    ["550", "0"],
    ["300", "3"],
    ["100", "1"],
    ["559", "9"],
  ],
  "04/03/2024 to 10/03/2024": [
    ["100", "1"],
    ["130", "4"],
    ["789", "4"],
    ["189", "8"],
    ["200", "2"],
    ["235", "0"],
    ["120", "3"],
  ],
  "11/03/2024 to 17/03/2024": [
    ["123", "6"],
    ["440", "8"],
    ["125", "8"],
    ["249", "5"],
    ["117", "9"],
    ["778", "2"],
    ["367", "6"],
  ],
  "18/03/2024 to 24/03/2024": [
    ["467", "7"],
    ["689", "3"],
    ["690", "5"],
    ["267", "5"],
    ["127", "0"],
    ["135", "9"],
    ["490", "3"],
  ],
  "25/03/2024 to 31/03/2024": [
    ["568", "9"],
    ["578", "0"],
    ["789", "4"],
    ["148", "3"],
    ["358", "6"],
    ["224", "8"],
    ["478", "9"],
  ],
  "01/04/2024 to 07/04/2024": [
    ["346", "3"],
    ["288", "8"],
    ["456", "5"],
    ["236", "1"],
    ["120", "3"],
    ["458", "7"],
    ["159", "5"],
  ],
  "08/04/2024 to 14/04/2024": [
    ["134", "8"],
    ["345", "2"],
    ["358", "6"],
    ["779", "3"],
    ["479", "0"],
    ["470", "1"],
    ["134", "8"],
  ],
  "15/04/2024 to 21/04/2024": [
    ["456", "5"],
    ["167", "4"],
    ["579", "1"],
    ["359", "7"],
    ["589", "2"],
    ["148", "3"],
    ["259", "6"],
  ],
  "22/04/2024 to 28/04/2024": [
    ["678", "1"],
    ["224", "8"],
    ["569", "0"],
    ["448", "6"],
    ["680", "4"],
    ["239", "4"],
    ["379", "9"],
  ],
  "29/04/2024 to 05/05/2024": [
    ["180", "9"],
    ["357", "5"],
    ["455", "4"],
    ["468", "8"],
    ["457", "6"],
    ["140", "5"],
    ["678", "1"],
  ],
  "06/05/2024 to 12/05/2024": [
    ["277", "6"],
    ["244", "0"],
    ["789", "4"],
    ["228", "2"],
    ["668", "0"],
    ["336", "2"],
    ["199", "9"],
  ],
  "13/05/2024 to 19/05/2024": [
    ["136", "0"],
    ["169", "6"],
    ["590", "4"],
    ["790", "6"],
    ["569", "0"],
    ["157", "3"],
    ["269", "7"],
  ],
  "20/05/2024 to 26/05/2024": [
    ["134", "8"],
    ["115", "7"],
    ["150", "6"],
    ["449", "7"],
    ["240", "6"],
    ["348", "5"],
    ["280", "0"],
  ],
  "27/05/2024 to 02/06/2024": [
    ["356", "4"],
    ["589", "2"],
    ["167", "4"],
    ["190", "0"],
    ["500", "5"],
    ["226", "0"],
    ["458", "7"],
  ],
  "03/06/2024 to 09/06/2024": [
    ["388", "9"],
    ["123", "6"],
    ["229", "3"],
    ["359", "7"],
    ["467", "7"],
    ["245", "1"],
    ["300", "3"],
  ],
  "10/06/2024 to 16/06/2024": [
    ["260", "8"],
    ["259", "6"],
    ["280", "0"],
    ["469", "9"],
    ["149", "4"],
    ["500", "5"],
    ["225", "9"],
  ],
  "17/06/2024 to 23/06/2024": [
    ["245", "1"],
    ["145", "0"],
    ["144", "9"],
    ["360", "9"],
    ["156", "2"],
    ["260", "8"],
    ["267", "5"],
  ],
  "24/06/2024 to 30/06/2024": [
    ["567", "8"],
    ["344", "1"],
    ["249", "5"],
    ["457", "6"],
    ["366", "5"],
    ["160", "7"],
    ["229", "3"],
  ],
  "01/07/2024 to 07/07/2024": [
    ["448", "6"],
    ["180", "9"],
    ["179", "7"],
    ["115", "7"],
    ["366", "5"],
    ["178", "6"],
    ["166", "3"],
  ],
  "08/07/2024 to 14/07/2024": [
    ["127", "0"],
    ["388", "9"],
    ["557", "7"],
    ["670", "3"],
    ["150", "6"],
    ["169", "6"],
    ["680", "4"],
  ],
  "15/07/2024 to 21/07/2024": [
    ["336", "2"],
    ["259", "6"],
    ["489", "1"],
    ["360", "9"],
    ["490", "3"],
    ["379", "9"],
    ["180", "9"],
  ],
  "22/07/2024 to 28/07/2024": [
    ["233", "8"],
    ["556", "6"],
    ["479", "0"],
    ["128", "1"],
    ["670", "3"],
    ["566", "7"],
    ["135", "9"],
  ],
  "29/07/2024 to 04/08/2024": [
    ["255", "2"],
    ["239", "4"],
    ["478", "9"],
    ["157", "3"],
    ["139", "3"],
    ["245", "1"],
    ["470", "1"],
  ],
  "05/08/2024 to 11/08/2024": [
    ["247", "3"],
    ["223", "7"],
    ["580", "3"],
    ["567", "8"],
    ["590", "4"],
    ["569", "0"],
    ["679", "2"],
  ],
  "12/08/2024 to 18/08/2024": [
    ["236", "1"],
    ["380", "1"],
    ["117", "9"],
    ["699", "4"],
    ["246", "2"],
    ["120", "3"],
    ["579", "1"],
  ],
  "19/08/2024 to 25/08/2024": [
    ["159", "5"],
    ["229", "3"],
    ["370", "0"],
    ["336", "2"],
    ["199", "9"],
    ["600", "6"],
    ["236", "1"],
  ],
  "26/08/2024 to 01/09/2024": [
    ["278", "7"],
    ["130", "4"],
    ["168", "5"],
    ["149", "4"],
    ["189", "8"],
    ["990", "8"],
    ["399", "1"],
  ],
  "02/09/2024 to 08/09/2024": [
    ["480", "2"],
    ["790", "6"],
    ["446", "4"],
    ["148", "3"],
    ["679", "2"],
    ["246", "2"],
    ["158", "4"],
  ],
  "09/09/2024 to 15/09/2024": [
    ["250", "7"],
    ["357", "5"],
    ["448", "6"],
    ["440", "8"],
    ["229", "3"],
    ["177", "5"],
    ["123", "6"],
  ],
  "16/09/2024 to 22/09/2024": [
    ["669", "1"],
    ["666", "8"],
    ["557", "7"],
    ["133", "7"],
    ["224", "8"],
    ["179", "7"],
    ["359", "7"],
  ],
  "23/09/2024 to 29/09/2024": [
    ["140", "5"],
    ["340", "7"],
    ["138", "2"],
    ["780", "5"],
    ["770", "4"],
    ["560", "1"],
    ["490", "3"],
  ],
  "30/09/2024 to 06/10/2024": [
    ["159", "5"],
    ["280", "0"],
    ["140", "5"],
    ["680", "4"],
    ["337", "3"],
    ["778", "2"],
    ["555", "5"],
  ],
};

export const AllSingleDayChart = {
  "01/01/2024": [
    ["123", "6"],
    ["569", "0"],
    ["123", "6"],
    ["999", "7"],
    ["789", "4"],
    ["125", "8"],
    ["456", "5"],
    ["458", "7"],
    ["125", "8"],
    ["444", "2"],
    ["235", "0"],
    ["778", "2"],
  ],
  "02/01/2024": [
    ["888", "4"],
    ["699", "4"],
    ["457", "6"],
    ["456", "5"],
    ["124", "7"],
    ["258", "5"],
    ["999", "7"],
    ["569", "0"],
    ["568", "9"],
    ["789", "4"],
    ["568", "9"],
    ["357", "5"],
  ],
  "03/01/2024": [
    ["225", "9"],
    ["555", "5"],
    ["900", "9"],
    ["222", "6"],
    ["235", "0"],
    ["333", "9"],
    ["990", "8"],
    ["778", "2"],
    ["445", "3"],
    ["569", "0"],
    ["569", "0"],
    ["899", "6"],
  ],
  "04/01/2024": [
    ["777", "1"],
    ["256", "3"],
    ["666", "8"],
    ["600", "6"],
    ["444", "2"],
    ["999", "7"],
    ["222", "6"],
    ["222", "6"],
    ["456", "5"],
    ["127", "0"],
    ["569", "0"],
    ["899", "6"],
  ],
  "05/01/2024": [
    ["369", "8"],
    ["159", "5"],
    ["569", "0"],
    ["699", "4"],
    ["148", "3"],
    ["359", "7"],
    ["223", "7"],
    ["147", "2"],
    ["288", "8"],
    ["779", "3"],
    ["357", "5"],
    ["356", "4"],
  ],
  "06/01/2024": [
    ["259", "6"],
    ["569", "0"],
    ["235", "0"],
    ["366", "5"],
    ["236", "1"],
    ["569", "0"],
    ["489", "1"],
    ["777", "1"],
    ["777", "1"],
    ["123", "6"],
    ["555", "5"],
    ["477", "8"],
  ],
  "07/01/2024": [
    ["300", "3"],
    ["330", "6"],
    ["555", "5"],
    ["458", "7"],
    ["458", "7"],
    ["789", "4"],
    ["999", "7"],
    ["789", "4"],
    ["230", "5"],
    ["125", "8"],
    ["890", "7"],
    ["900", "9"],
  ],
  "08/01/2024": [
    ["456", "5"],
    ["255", "2"],
    ["800", "8"],
    ["699", "4"],
    ["149", "4"],
    ["899", "6"],
    ["458", "7"],
    ["125", "8"],
    ["358", "6"],
    ["147", "2"],
    ["100", "1"],
    ["355", "3"],
  ],
  "09/01/2024": [
    ["389", "0"],
    ["880", "6"],
    ["668", "0"],
    ["778", "2"],
    ["699", "4"],
    ["555", "5"],
    ["333", "9"],
    ["140", "5"],
    ["220", "4"],
    ["558", "8"],
    ["115", "7"],
    ["249", "5"],
  ],
  "10/01/2024": [
    ["127", "0"],
    ["236", "1"],
    ["226", "0"],
    ["247", "3"],
    ["368", "7"],
    ["566", "7"],
    ["117", "9"],
    ["338", "4"],
    ["555", "5"],
    ["128", "1"],
    ["888", "4"],
    ["258", "5"],
  ],
  "11/01/2024": [
    ["0", "0"],
    ["558", "8"],
    ["115", "7"],
    ["445", "3"],
    ["228", "2"],
    ["999", "7"],
    ["145", "0"],
    ["446", "4"],
    ["558", "8"],
    ["115", "7"],
    ["889", "5"],
    ["236", "1"],
  ],
  "12/01/2024": [
    ["458", "7"],
    ["123", "6"],
    ["258", "5"],
    ["156", "2"],
    ["666", "8"],
    ["128", "1"],
    ["456", "5"],
    ["159", "5"],
    ["127", "0"],
    ["459", "8"],
    ["699", "4"],
    ["359", "7"],
  ],
  "13/01/2024": [
    ["115", "7"],
    ["238", "3"],
    ["115", "7"],
    ["169", "6"],
    ["159", "5"],
    ["369", "8"],
    ["368", "7"],
    ["567", "8"],
    ["470", "1"],
    ["690", "5"],
    ["123", "6"],
    ["569", "0"],
  ],
  "14/01/2024": [
    ["460", "0"],
    ["129", "2"],
    ["357", "5"],
    ["125", "8"],
    ["369", "8"],
    ["115", "7"],
    ["456", "5"],
    ["890", "7"],
    ["789", "4"],
    ["250", "7"],
    ["359", "7"],
    ["255", "2"],
  ],
  "15/01/2024": [
    ["359", "7"],
    ["380", "1"],
    ["159", "5"],
    ["789", "4"],
    ["458", "7"],
    ["147", "2"],
    ["569", "0"],
    ["890", "7"],
    ["458", "7"],
    ["156", "2"],
    ["479", "0"],
    ["590", "4"],
  ],
  "16/01/2024": [
    ["456", "5"],
    ["789", "4"],
    ["590", "4"],
    ["159", "5"],
    ["125", "8"],
    ["489", "1"],
    ["359", "7"],
    ["256", "3"],
    ["690", "5"],
    ["569", "0"],
    ["358", "6"],
    ["123", "6"],
  ],
  "17/01/2024": [
    ["460", "0"],
    ["123", "6"],
    ["456", "5"],
    ["236", "1"],
    ["890", "7"],
    ["479", "0"],
    ["249", "5"],
    ["128", "1"],
    ["359", "7"],
    ["689", "3"],
    ["129", "2"],
    ["230", "5"],
  ],
  "18/01/2024": [
    ["123", "6"],
    ["268", "6"],
    ["230", "5"],
    ["789", "4"],
    ["125", "8"],
    ["156", "2"],
    ["569", "0"],
    ["890", "7"],
    ["489", "1"],
    ["359", "7"],
    ["250", "7"],
    ["159", "5"],
  ],
  "19/01/2024": [
    ["458", "7"],
    ["149", "4"],
    ["258", "5"],
    ["489", "1"],
    ["169", "6"],
    ["250", "7"],
    ["458", "7"],
    ["125", "8"],
    ["348", "5"],
    ["189", "8"],
    ["789", "4"],
    ["348", "5"],
  ],
  "20/01/2024": [
    ["123", "6"],
    ["478", "9"],
    ["369", "8"],
    ["159", "5"],
    ["357", "5"],
    ["446", "4"],
    ["150", "6"],
    ["489", "1"],
    ["358", "6"],
    ["157", "3"],
    ["258", "5"],
    ["280", "0"],
  ],
  "21/01/2024": [
    ["357", "5"],
    ["147", "2"],
    ["236", "1"],
    ["469", "9"],
    ["189", "8"],
    ["257", "4"],
    ["458", "7"],
    ["169", "6"],
    ["360", "9"],
    ["190", "0"],
    ["157", "3"],
    ["890", "7"],
  ],
  "22/01/2024": [
    ["456", "5"],
    ["125", "8"],
    ["249", "5"],
    ["577", "9"],
    ["248", "4"],
    ["149", "4"],
    ["349", "6"],
    ["580", "3"],
    ["356", "4"],
    ["140", "5"],
    ["269", "7"],
    ["489", "1"],
  ],
  "23/01/2024": [
    ["249", "5"],
    ["349", "6"],
    ["679", "2"],
    ["228", "2"],
    ["137", "1"],
    ["380", "1"],
    ["568", "9"],
    ["238", "3"],
    ["368", "7"],
    ["369", "8"],
    ["689", "3"],
    ["246", "2"],
  ],
  "24/01/2024": [
    ["358", "6"],
    ["450", "9"],
    ["249", "5"],
    ["120", "3"],
    ["380", "1"],
    ["125", "8"],
    ["570", "2"],
    ["127", "0"],
    ["260", "8"],
    ["256", "3"],
    ["689", "3"],
    ["148", "3"],
  ],
  "25/01/2024": [
    ["290", "1"],
    ["456", "5"],
    ["379", "9"],
    ["157", "3"],
    ["247", "3"],
    ["250", "7"],
    ["123", "6"],
    ["249", "5"],
    ["350", "8"],
    ["128", "1"],
    ["490", "3"],
    ["890", "7"],
  ],
  "26/01/2024": [
    ["130", "4"],
    ["138", "2"],
    ["356", "4"],
    ["689", "3"],
    ["120", "3"],
    ["789", "4"],
    ["128", "1"],
    ["578", "0"],
    ["278", "7"],
    ["189", "8"],
    ["469", "9"],
    ["367", "6"],
  ],
  "27/01/2024": [
    ["129", "2"],
    ["457", "6"],
    ["146", "1"],
    ["289", "9"],
    ["260", "8"],
    ["370", "0"],
    ["345", "2"],
    ["120", "3"],
    ["789", "4"],
    ["145", "0"],
    ["589", "2"],
    ["446", "4"],
  ],
  "28/01/2024": [
    ["235", "0"],
    ["679", "2"],
    ["367", "6"],
    ["489", "1"],
    ["158", "4"],
    ["447", "5"],
    ["247", "3"],
    ["589", "2"],
    ["124", "7"],
    ["129", "2"],
    ["346", "3"],
    ["450", "9"],
  ],
  "29/01/2024": [
    ["120", "3"],
    ["134", "8"],
    ["360", "9"],
    ["290", "1"],
    ["167", "4"],
    ["289", "9"],
    ["668", "0"],
    ["456", "5"],
    ["789", "4"],
    ["346", "3"],
    ["258", "5"],
    ["569", "0"],
  ],
  "30/01/2024": [
    ["289", "9"],
    ["123", "6"],
    ["458", "7"],
    ["567", "8"],
    ["246", "2"],
    ["190", "0"],
    ["257", "4"],
    ["570", "2"],
    ["339", "5"],
    ["670", "3"],
    ["456", "5"],
    ["149", "4"],
  ],
  "31/01/2024": [
    ["348", "5"],
    ["189", "8"],
    ["244", "0"],
    ["345", "2"],
    ["258", "5"],
    ["190", "0"],
    ["259", "6"],
    ["478", "9"],
    ["148", "3"],
    ["257", "4"],
    ["358", "6"],
    ["278", "7"],
  ],
  "01/02/2024": [
    ["167", "4"],
    ["456", "5"],
    ["570", "2"],
    ["379", "9"],
    ["260", "8"],
    ["389", "0"],
    ["156", "2"],
    ["135", "9"],
    ["123", "6"],
    ["578", "0"],
    ["140", "5"],
    ["246", "2"],
  ],
  "02/02/2024": [
    ["156", "2"],
    ["270", "9"],
    ["235", "0"],
    ["345", "2"],
    ["357", "5"],
    ["446", "4"],
    ["360", "9"],
    ["389", "0"],
    ["140", "5"],
    ["167", "4"],
    ["790", "6"],
    ["289", "9"],
  ],
  "03/02/2024": [
    ["123", "6"],
    ["234", "9"],
    ["578", "0"],
    ["589", "2"],
    ["235", "0"],
    ["345", "2"],
    ["170", "8"],
    ["567", "8"],
    ["390", "2"],
    ["600", "6"],
    ["568", "9"],
    ["356", "4"],
  ],
  "04/02/2024": [
    ["370", "0"],
    ["347", "4"],
    ["456", "5"],
    ["568", "9"],
    ["457", "6"],
    ["260", "8"],
    ["789", "4"],
    ["160", "7"],
    ["200", "2"],
    ["346", "3"],
    ["356", "4"],
    ["139", "3"],
  ],
  "05/02/2024": [
    ["179", "7"],
    ["245", "1"],
    ["344", "1"],
    ["129", "2"],
    ["346", "3"],
    ["390", "2"],
    ["134", "8"],
    ["789", "4"],
    ["134", "8"],
    ["457", "6"],
    ["180", "9"],
    ["467", "7"],
  ],
  "06/02/2024": [
    ["245", "1"],
    ["578", "0"],
    ["568", "9"],
    ["110", "2"],
    ["890", "7"],
    ["157", "3"],
    ["234", "9"],
    ["356", "4"],
    ["230", "5"],
    ["468", "8"],
    ["358", "6"],
    ["355", "3"],
  ],
  "07/02/2024": [
    ["369", "8"],
    ["148", "3"],
    ["100", "1"],
    ["890", "7"],
    ["590", "4"],
    ["138", "2"],
    ["346", "3"],
    ["489", "1"],
    ["150", "6"],
    ["347", "4"],
    ["140", "5"],
    ["235", "0"],
  ],
  "08/02/2024": [
    ["459", "8"],
    ["569", "0"],
    ["690", "5"],
    ["189", "8"],
    ["689", "3"],
    ["137", "1"],
    ["246", "2"],
    ["356", "4"],
    ["237", "2"],
    ["150", "6"],
    ["144", "9"],
    ["890", "7"],
  ],
  "09/02/2024": [
    ["890", "7"],
    ["190", "0"],
    ["289", "9"],
    ["226", "0"],
    ["129", "2"],
    ["240", "6"],
    ["125", "8"],
    ["245", "1"],
    ["580", "3"],
    ["356", "4"],
    ["258", "5"],
    ["110", "2"],
  ],
  "10/02/2024": [
    ["160", "7"],
    ["569", "0"],
    ["346", "3"],
    ["259", "6"],
    ["570", "2"],
    ["290", "1"],
    ["125", "8"],
    ["379", "9"],
    ["127", "0"],
    ["670", "3"],
    ["140", "5"],
    ["467", "7"],
  ],
  "11/02/2024": [
    ["389", "0"],
    ["569", "0"],
    ["256", "3"],
    ["146", "1"],
    ["356", "4"],
    ["179", "7"],
    ["147", "2"],
    ["390", "2"],
    ["230", "5"],
    ["129", "2"],
    ["690", "5"],
    ["136", "0"],
  ],
  "12/02/2024": [
    ["670", "3"],
    ["345", "2"],
    ["358", "6"],
    ["890", "7"],
    ["156", "2"],
    ["190", "0"],
    ["130", "4"],
    ["258", "5"],
    ["128", "1"],
    ["349", "6"],
    ["289", "9"],
    ["134", "8"],
  ],
  "13/02/2024": [
    ["349", "6"],
    ["123", "6"],
    ["120", "3"],
    ["249", "5"],
    ["450", "9"],
    ["678", "1"],
    ["100", "1"],
    ["156", "2"],
    ["158", "4"],
    ["458", "7"],
    ["340", "7"],
    ["145", "0"],
  ],
  "14/02/2024": [
    ["560", "1"],
    ["145", "0"],
    ["235", "0"],
    ["357", "5"],
    ["245", "1"],
    ["150", "6"],
    ["258", "5"],
    ["345", "2"],
    ["289", "9"],
    ["445", "3"],
    ["468", "8"],
    ["458", "7"],
  ],
  "15/02/2024": [
    ["337", "3"],
    ["245", "1"],
    ["469", "9"],
    ["135", "9"],
    ["100", "1"],
    ["358", "6"],
    ["567", "8"],
    ["288", "8"],
    ["348", "5"],
    ["122", "5"],
    ["356", "4"],
    ["234", "9"],
  ],
  "16/02/2024": [
    ["100", "1"],
    ["356", "4"],
    ["128", "1"],
    ["689", "3"],
    ["246", "2"],
    ["478", "9"],
    ["990", "8"],
    ["578", "0"],
    ["258", "5"],
    ["457", "6"],
    ["467", "7"],
    ["346", "3"],
  ],
  "17/02/2024": [
    ["145", "0"],
    ["550", "0"],
    ["567", "8"],
    ["367", "6"],
    ["457", "6"],
    ["369", "8"],
    ["156", "2"],
    ["356", "4"],
    ["368", "7"],
    ["120", "3"],
    ["147", "2"],
    ["290", "1"],
  ],
  "18/02/2024": [
    ["145", "0"],
    ["459", "8"],
    ["678", "1"],
    ["250", "7"],
    ["248", "4"],
    ["180", "9"],
    ["200", "2"],
    ["346", "3"],
    ["290", "1"],
    ["140", "5"],
    ["367", "6"],
    ["330", "6"],
  ],
  "19/02/2024": [
    ["348", "5"],
    ["122", "5"],
    ["890", "7"],
    ["458", "7"],
    ["369", "8"],
    ["239", "4"],
    ["560", "1"],
    ["345", "2"],
    ["256", "3"],
    ["150", "6"],
    ["568", "9"],
    ["145", "0"],
  ],
  "20/02/2024": [
    ["357", "5"],
    ["100", "1"],
    ["145", "0"],
    ["578", "0"],
    ["256", "3"],
    ["156", "2"],
    ["356", "4"],
    ["178", "6"],
    ["245", "1"],
    ["146", "1"],
    ["467", "7"],
    ["577", "9"],
  ],
  "21/02/2024": [
    ["155", "1"],
    ["145", "0"],
    ["478", "9"],
    ["359", "7"],
    ["349", "6"],
    ["144", "9"],
    ["357", "5"],
    ["356", "4"],
    ["390", "2"],
    ["139", "3"],
    ["459", "8"],
    ["467", "7"],
  ],
  "22/02/2024": [
    ["145", "0"],
    ["489", "1"],
    ["580", "3"],
    ["129", "2"],
    ["790", "6"],
    ["456", "5"],
    ["136", "0"],
    ["400", "4"],
    ["125", "8"],
    ["137", "1"],
    ["689", "3"],
    ["457", "6"],
  ],
  "23/02/2024": [
    ["370", "0"],
    ["245", "1"],
    ["256", "3"],
    ["890", "7"],
    ["110", "2"],
    ["239", "4"],
    ["680", "4"],
    ["126", "9"],
    ["270", "9"],
    ["237", "2"],
    ["900", "9"],
    ["356", "4"],
  ],
  "24/02/2024": [
    ["190", "0"],
    ["257", "4"],
    ["140", "5"],
    ["456", "5"],
    ["157", "3"],
    ["345", "2"],
    ["356", "4"],
    ["348", "5"],
    ["900", "9"],
    ["780", "5"],
    ["344", "1"],
    ["123", "6"],
  ],
  "25/02/2024": [
    ["460", "0"],
    ["256", "3"],
    ["140", "5"],
    ["199", "9"],
    ["245", "1"],
    ["123", "6"],
    ["149", "4"],
    ["600", "6"],
    ["110", "2"],
    ["120", "3"],
    ["245", "1"],
    ["157", "3"],
  ],
  "26/02/2024": [
    ["289", "9"],
    ["470", "1"],
    ["890", "7"],
    ["147", "2"],
    ["256", "3"],
    ["700", "7"],
    ["338", "4"],
    ["456", "5"],
    ["245", "1"],
    ["145", "0"],
    ["459", "8"],
    ["125", "8"],
  ],
  "27/02/2024": [
    ["467", "7"],
    ["379", "9"],
    ["137", "1"],
    ["148", "3"],
    ["358", "6"],
    ["357", "5"],
    ["480", "2"],
    ["257", "4"],
    ["147", "2"],
    ["550", "0"],
    ["468", "8"],
    ["259", "6"],
  ],
  "28/02/2024": [
    ["247", "3"],
    ["356", "4"],
    ["138", "2"],
    ["569", "0"],
    ["135", "9"],
    ["258", "5"],
    ["477", "8"],
    ["245", "1"],
    ["579", "1"],
    ["200", "2"],
    ["167", "4"],
    ["457", "6"],
  ],
  "29/02/2024": [
    ["347", "4"],
    ["489", "1"],
    ["550", "0"],
    ["140", "5"],
    ["390", "2"],
    ["125", "8"],
    ["700", "7"],
    ["160", "7"],
    ["145", "0"],
    ["337", "3"],
    ["280", "0"],
    ["134", "8"],
  ],
  "01/03/2024": [
    ["140", "5"],
    ["359", "7"],
    ["300", "3"],
    ["236", "1"],
    ["369", "8"],
    ["789", "4"],
    ["457", "6"],
    ["123", "6"],
    ["900", "9"],
    ["135", "9"],
    ["230", "5"],
    ["370", "0"],
  ],
  "02/03/2024": [
    ["347", "4"],
    ["140", "5"],
    ["100", "1"],
    ["238", "3"],
    ["589", "2"],
    ["990", "8"],
    ["234", "9"],
    ["457", "6"],
    ["126", "9"],
    ["124", "7"],
    ["145", "0"],
    ["129", "2"],
  ],
  "03/03/2024": [
    ["377", "7"],
    ["370", "0"],
    ["559", "9"],
    ["260", "8"],
    ["146", "1"],
    ["147", "2"],
    ["230", "5"],
    ["139", "3"],
    ["240", "6"],
    ["347", "4"],
    ["480", "2"],
    ["125", "8"],
  ],
  "04/03/2024": [
    ["358", "6"],
    ["340", "7"],
    ["100", "1"],
    ["145", "0"],
    ["136", "0"],
    ["160", "7"],
    ["567", "8"],
    ["356", "4"],
    ["110", "2"],
    ["369", "8"],
    ["129", "2"],
    ["150", "6"],
  ],
  "05/03/2024": [
    ["220", "4"],
    ["160", "7"],
    ["130", "4"],
    ["248", "4"],
    ["245", "1"],
    ["299", "0"],
    ["468", "8"],
    ["190", "0"],
    ["289", "9"],
    ["480", "2"],
    ["148", "3"],
    ["120", "3"],
  ],
  "06/03/2024": [
    ["299", "0"],
    ["170", "8"],
    ["789", "4"],
    ["169", "6"],
    ["400", "4"],
    ["489", "1"],
    ["110", "2"],
    ["168", "5"],
    ["689", "3"],
    ["246", "2"],
    ["288", "8"],
    ["145", "0"],
  ],
  "07/03/2024": [
    ["589", "2"],
    ["220", "4"],
    ["189", "8"],
    ["180", "9"],
    ["368", "7"],
    ["499", "2"],
    ["130", "4"],
    ["459", "8"],
    ["280", "0"],
    ["128", "1"],
    ["249", "5"],
    ["137", "1"],
  ],
  "08/03/2024": [
    ["389", "0"],
    ["257", "4"],
    ["200", "2"],
    ["359", "7"],
    ["560", "1"],
    ["140", "5"],
    ["689", "3"],
    ["290", "1"],
    ["289", "9"],
    ["256", "3"],
    ["159", "5"],
    ["180", "9"],
  ],
  "09/03/2024": [
    ["356", "4"],
    ["258", "5"],
    ["235", "0"],
    ["467", "7"],
    ["900", "9"],
    ["120", "3"],
    ["290", "1"],
    ["129", "2"],
    ["359", "7"],
    ["340", "7"],
    ["257", "4"],
    ["126", "9"],
  ],
  "10/03/2024": [
    ["467", "7"],
    ["258", "5"],
    ["120", "3"],
    ["199", "9"],
    ["359", "7"],
    ["235", "0"],
    ["447", "5"],
    ["290", "1"],
    ["129", "2"],
    ["257", "4"],
    ["123", "6"],
    ["180", "9"],
  ],
  "11/03/2024": [
    ["140", "5"],
    ["247", "3"],
    ["123", "6"],
    ["800", "8"],
    ["149", "4"],
    ["550", "0"],
    ["180", "9"],
    ["290", "1"],
    ["360", "9"],
    ["557", "7"],
    ["780", "5"],
    ["160", "7"],
  ],
  "12/03/2024": [
    ["149", "4"],
    ["247", "3"],
    ["440", "8"],
    ["146", "1"],
    ["169", "6"],
    ["190", "0"],
    ["400", "4"],
    ["390", "2"],
    ["249", "5"],
    ["145", "0"],
    ["588", "1"],
    ["199", "9"],
  ],
  "13/03/2024": [
    ["448", "6"],
    ["246", "2"],
    ["125", "8"],
    ["179", "7"],
    ["568", "9"],
    ["239", "4"],
    ["136", "0"],
    ["459", "8"],
    ["890", "7"],
    ["780", "5"],
    ["148", "3"],
    ["128", "1"],
  ],
  "14/03/2024": [
    ["566", "7"],
    ["660", "2"],
    ["249", "5"],
    ["126", "9"],
    ["120", "3"],
    ["267", "5"],
    ["138", "2"],
    ["124", "7"],
    ["235", "0"],
    ["567", "8"],
    ["130", "4"],
    ["568", "9"],
  ],
  "15/03/2024": [
    ["200", "2"],
    ["280", "0"],
    ["117", "9"],
    ["890", "7"],
    ["369", "8"],
    ["126", "9"],
    ["679", "2"],
    ["133", "7"],
    ["127", "0"],
    ["157", "3"],
    ["788", "3"],
    ["680", "4"],
  ],
  "16/03/2024": [
    ["345", "2"],
    ["133", "7"],
    ["778", "2"],
    ["125", "8"],
    ["150", "6"],
    ["149", "4"],
    ["235", "0"],
    ["356", "4"],
    ["290", "1"],
    ["566", "7"],
    ["690", "5"],
    ["135", "9"],
  ],
  "17/03/2024": [
    ["128", "1"],
    ["677", "0"],
    ["367", "6"],
    ["117", "9"],
    ["159", "5"],
    ["267", "5"],
    ["347", "4"],
    ["890", "7"],
    ["900", "9"],
    ["480", "2"],
    ["140", "5"],
    ["389", "0"],
  ],
  "18/03/2024": [
    ["136", "0"],
    ["239", "4"],
    ["467", "7"],
    ["589", "2"],
    ["348", "5"],
    ["450", "9"],
    ["770", "4"],
    ["678", "1"],
    ["360", "9"],
    ["569", "0"],
    ["458", "7"],
    ["489", "1"],
  ],
  "19/03/2024": [
    ["269", "7"],
    ["350", "8"],
    ["689", "3"],
    ["569", "0"],
    ["140", "5"],
    ["345", "2"],
    ["258", "5"],
    ["155", "1"],
    ["120", "3"],
    ["257", "4"],
    ["220", "4"],
    ["389", "0"],
  ],
  "20/03/2024": [
    ["114", "6"],
    ["450", "9"],
    ["690", "5"],
    ["247", "3"],
    ["569", "0"],
    ["470", "1"],
    ["345", "2"],
    ["130", "4"],
    ["889", "5"],
    ["490", "3"],
    ["100", "1"],
    ["246", "2"],
  ],
  "21/03/2024": [
    ["115", "7"],
    ["590", "4"],
    ["267", "5"],
    ["477", "8"],
    ["380", "1"],
    ["789", "4"],
    ["578", "0"],
    ["340", "7"],
    ["238", "3"],
    ["237", "2"],
    ["489", "1"],
    ["348", "5"],
  ],
  "22/03/2024": [
    ["490", "3"],
    ["259", "6"],
    ["127", "0"],
    ["568", "9"],
    ["359", "7"],
    ["889", "5"],
    ["370", "0"],
    ["468", "8"],
    ["690", "5"],
    ["558", "8"],
    ["670", "3"],
    ["799", "5"],
  ],
  "23/03/2024": [
    ["489", "1"],
    ["345", "2"],
    ["135", "9"],
    ["568", "9"],
    ["235", "0"],
    ["790", "6"],
    ["557", "7"],
    ["148", "3"],
    ["690", "5"],
    ["349", "6"],
    ["237", "2"],
    ["368", "7"],
  ],
  "24/03/2024": [
    ["158", "4"],
    ["360", "9"],
    ["490", "3"],
    ["258", "5"],
    ["179", "7"],
    ["457", "6"],
    ["237", "2"],
    ["579", "1"],
    ["338", "4"],
    ["180", "9"],
    ["277", "6"],
    ["235", "0"],
  ],
  "25/03/2024": [
    ["269", "7"],
    ["389", "0"],
    ["568", "9"],
    ["367", "6"],
    ["230", "5"],
    ["560", "1"],
    ["117", "9"],
    ["380", "1"],
    ["558", "8"],
    ["560", "1"],
    ["123", "6"],
    ["457", "6"],
  ],
  "26/03/2024": [
    ["129", "2"],
    ["160", "7"],
    ["578", "0"],
    ["288", "8"],
    ["459", "8"],
    ["679", "2"],
    ["349", "6"],
    ["299", "0"],
    ["478", "9"],
    ["150", "6"],
    ["467", "7"],
    ["799", "5"],
  ],
  "27/03/2024": [
    ["127", "0"],
    ["220", "4"],
    ["789", "4"],
    ["448", "6"],
    ["569", "0"],
    ["450", "9"],
    ["178", "6"],
    ["345", "2"],
    ["234", "9"],
    ["336", "2"],
    ["890", "7"],
    ["369", "8"],
  ],
  "28/03/2024": [
    ["169", "6"],
    ["470", "1"],
    ["148", "3"],
    ["346", "3"],
    ["457", "6"],
    ["290", "1"],
    ["480", "2"],
    ["478", "9"],
    ["258", "5"],
    ["379", "9"],
    ["567", "8"],
    ["234", "9"],
  ],
  "29/03/2024": [
    ["449", "7"],
    ["590", "4"],
    ["358", "6"],
    ["347", "4"],
    ["578", "0"],
    ["127", "0"],
    ["457", "6"],
    ["279", "8"],
    ["179", "7"],
    ["678", "1"],
    ["236", "1"],
    ["790", "6"],
  ],
  "30/03/2024": [
    ["238", "3"],
    ["466", "6"],
    ["224", "8"],
    ["359", "7"],
    ["789", "4"],
    ["347", "4"],
    ["169", "6"],
    ["236", "1"],
    ["128", "1"],
    ["689", "3"],
    ["168", "5"],
    ["237", "2"],
  ],
  "31/03/2024": [
    ["235", "0"],
    ["348", "5"],
    ["478", "9"],
    ["679", "2"],
    ["113", "5"],
    ["668", "0"],
    ["248", "4"],
    ["277", "6"],
    ["156", "2"],
    ["360", "9"],
    ["689", "3"],
    ["357", "5"]
],
"01/04/2024": [
    ["149", "4"],
    ["778", "2"],
    ["346", "3"],
    ["559", "9"],
    ["490", "3"],
    ["267", "5"],
    ["699", "4"],
    ["369", "8"],
    ["279", "8"],
    ["140", "5"],
    ["245", "1"],
    ["119", "1"]
],
"02/04/2024": [
    ["236", "1"],
    ["589", "2"],
    ["288", "8"],
    ["149", "4"],
    ["580", "3"],
    ["678", "1"],
    ["237", "2"],
    ["469", "9"],
    ["126", "9"],
    ["347", "4"],
    ["224", "8"],
    ["680", "4"]
],
"03/04/2024": [
    ["568", "9"],
    ["689", "3"],
    ["456", "5"],
    ["134", "8"],
    ["238", "3"],
    ["569", "0"],
    ["123", "6"],
    ["347", "4"],
    ["489", "1"],
    ["349", "6"],
    ["260", "8"],
    ["129", "2"]
],
"04/04/2024": [
    ["789", "4"],
    ["145", "0"],
    ["236", "1"],
    ["349", "6"],
    ["690", "5"],
    ["479", "0"],
    ["238", "3"],
    ["357", "5"],
    ["178", "6"],
    ["227", "1"],
    ["346", "3"],
    ["149", "4"]
],
"05/04/2024": [
    ["568", "9"],
    ["480", "2"],
    ["120", "3"],
    ["369", "8"],
    ["235", "0"],
    ["169", "6"],
    ["690", "5"],
    ["278", "7"],
    ["346", "3"],
    ["247", "3"],
    ["558", "8"],
    ["367", "6"]
],
"06/04/2024": [
    ["356", "4"],
    ["267", "5"],
    ["458", "7"],
    ["134", "8"],
    ["460", "0"],
    ["268", "6"],
    ["580", "3"],
    ["146", "1"],
    ["278", "7"],
    ["560", "1"],
    ["225", "9"],
    ["890", "7"]
],
"07/04/2024": [
    ["290", "1"],
    ["358", "6"],
    ["159", "5"],
    ["447", "5"],
    ["148", "3"],
    ["480", "2"],
    ["277", "6"],
    ["450", "9"],
    ["138", "2"],
    ["467", "7"],
    ["256", "3"],
    ["379", "9"]
],
"08/04/2024": [
    ["450", "9"],
    ["589", "2"],
    ["134", "8"],
    ["228", "2"],
    ["680", "4"],
    ["135", "9"],
    ["359", "7"],
    ["124", "7"],
    ["258", "5"],
    ["356", "4"],
    ["567", "8"],
    ["780", "5"]
],
"09/04/2024": [
    ["118", "0"],
    ["280", "0"],
    ["345", "2"],
    ["269", "7"],
    ["480", "2"],
    ["126", "9"],
    ["578", "0"],
    ["469", "9"],
    ["690", "5"],
    ["589", "2"],
    ["367", "6"],
    ["458", "7"]
],
"10/04/2024": [
    ["249", "5"],
    ["890", "7"],
    ["358", "6"],
    ["168", "5"],
    ["123", "6"],
    ["145", "0"],
    ["567", "8"],
    ["455", "4"],
    ["679", "2"],
    ["235", "0"],
    ["356", "4"],
    ["234", "9"]
],
"11/04/2024": [
    ["567", "8"],
    ["359", "7"],
    ["779", "3"],
    ["260", "8"],
    ["356", "4"],
    ["457", "6"],
    ["578", "0"],
    ["159", "5"],
    ["480", "2"],
    ["258", "5"],
    ["670", "3"],
    ["257", "4"]
],
"12/04/2024": [
    ["349", "6"],
    ["167", "4"],
    ["479", "0"],
    ["225", "9"],
    ["679", "2"],
    ["599", "3"],
    ["457", "6"],
    ["138", "2"],
    ["379", "9"],
    ["990", "8"],
    ["247", "3"],
    ["578", "0"]
]
,"13/04/2024": [
    ["588", "1"],
    ["118", "0"],
    ["470", "1"],
    ["223", "7"],
    ["340", "7"],
    ["230", "5"],
    ["558", "8"],
    ["469", "9"],
    ["347", "4"],
    ["568", "9"],
    ["570", "2"],
    ["277", "6"]
],
"14/04/2024": [
    ["379", "9"],
    ["256", "3"],
    ["134", "8"],
    ["678", "1"],
    ["245", "1"],
    ["690", "5"],
    ["179", "7"],
    ["334", "0"],
    ["139", "3"],
    ["257", "4"],
    ["168", "5"],
    ["479", "0"]
],
"15/04/2024": [
    ["229", "3"],
    ["390", "2"],
    ["456", "5"],
    ["334", "0"],
    ["124", "7"],
    ["450", "9"],
    ["357", "5"],
    ["120", "3"],
    ["367", "6"],
    ["248", "4"],
    ["457", "6"],
    ["115", "7"]
],
"16/04/2024": [
    ["778", "2"],
    ["159", "5"],
    ["167", "4"],
    ["237", "2"],
    ["789", "4"],
    ["139", "3"],
    ["233", "8"],
    ["346", "3"],
    ["560", "1"],
    ["468", "8"],
    ["349", "6"],
    ["234", "9"]
],
"17/04/2024": [
    ["114", "6"],
    ["145", "0"],
    ["579", "1"],
    ["259", "6"],
    ["378", "8"],
    ["279", "8"],
    ["478", "9"],
    ["235", "0"],
    ["690", "5"],
    ["278", "7"],
    ["359", "7"],
    ["490", "3"]
],
"18/04/2024": [
    ["338", "4"],
    ["130", "4"],
    ["359", "7"],
    ["225", "9"],
    ["567", "8"],
    ["489", "1"],
    ["260", "8"],
    ["146", "1"],
    ["889", "5"],
    ["569", "0"],
    ["460", "0"],
    ["557", "7"]
],
"19/04/2024": [
    ["235", "0"],
    ["237", "2"],
    ["589", "2"],
    ["356", "4"],
    ["357", "5"],
    ["890", "7"],
    ["457", "6"],
    ["136", "0"],
    ["358", "6"],
    ["300", "3"],
    ["669", "1"],
    ["159", "5"]
],
"20/04/2024": [
    ["189", "8"],
    ["350", "8"],
    ["148", "3"],
    ["550", "0"],
    ["470", "1"],
    ["235", "0"],
    ["456", "5"],
    ["388", "9"],
    ["670", "3"],
    ["379", "9"],
    ["468", "8"],
    ["245", "1"]
],
"21/04/2024": [
    ["690", "5"],
    ["330", "6"],
    ["259", "6"],
    ["458", "7"],
    ["389", "0"],
    ["140", "5"],
    ["237", "2"],
    ["158", "4"],
    ["789", "4"],
    ["480", "2"],
    ["137", "1"],
    ["245", "1"]
],
"22/04/2024": [
    ["379", "9"],
    ["590", "4"],
    ["678", "1"],
    ["247", "3"],
    ["779", "3"],
    ["168", "5"],
    ["560", "1"],
    ["449", "7"],
    ["230", "5"],
    ["589", "2"],
    ["790", "6"],
    ["129", "2"]
],
"23/04/2024": [
    ["490", "3"],
    ["124", "7"],
    ["224", "8"],
    ["168", "5"],
    ["257", "4"],
    ["350", "8"],
    ["579", "1"],
    ["347", "4"],
    ["668", "0"],
    ["479", "0"],
    ["268", "6"],
    ["780", "5"]
],
"24/04/2024": [
    ["460", "0"],
    ["699", "4"],
    ["569", "0"],
    ["245", "1"],
    ["790", "6"],
    ["178", "6"],
    ["500", "5"],
    ["388", "9"],
    ["357", "5"],
    ["478", "9"],
    ["560", "1"],
    ["140", "5"]
],
"25/04/2024": [
    ["136", "0"],
    ["270", "9"],
    ["448", "6"],
    ["370", "0"],
    ["568", "9"],
    ["377", "7"],
    ["367", "6"],
    ["589", "2"],
    ["336", "2"],
    ["236", "1"],
    ["468", "8"],
    ["457", "6"]
],
"26/04/2024": [
    ["480", "2"],
    ["779", "3"],
    ["680", "4"],
    ["455", "4"],
    ["256", "3"],
    ["145", "0"],
    ["360", "9"],
    ["168", "5"],
    ["247", "3"],
    ["389", "0"],
    ["456", "5"],
    ["360", "9"]
]
,
"13/04/2024": [
    ["588", "1"],
    ["118", "0"],
    ["470", "1"],
    ["223", "7"],
    ["340", "7"],
    ["230", "5"],
    ["558", "8"],
    ["469", "9"],
    ["347", "4"],
    ["568", "9"],
    ["570", "2"],
    ["277", "6"]
],
"14/04/2024": [
    ["379", "9"],
    ["256", "3"],
    ["134", "8"],
    ["678", "1"],
    ["245", "1"],
    ["690", "5"],
    ["179", "7"],
    ["334", "0"],
    ["139", "3"],
    ["257", "4"],
    ["168", "5"],
    ["479", "0"]
],
"15/04/2024": [
    ["229", "3"],
    ["390", "2"],
    ["456", "5"],
    ["334", "0"],
    ["124", "7"],
    ["450", "9"],
    ["357", "5"],
    ["120", "3"],
    ["367", "6"],
    ["248", "4"],
    ["457", "6"],
    ["115", "7"]
],
"16/04/2024": [
    ["778", "2"],
    ["159", "5"],
    ["167", "4"],
    ["237", "2"],
    ["789", "4"],
    ["139", "3"],
    ["233", "8"],
    ["346", "3"],
    ["560", "1"],
    ["468", "8"],
    ["349", "6"],
    ["234", "9"]
],
"17/04/2024": [
    ["114", "6"],
    ["145", "0"],
    ["579", "1"],
    ["259", "6"],
    ["378", "8"],
    ["279", "8"],
    ["478", "9"],
    ["235", "0"],
    ["690", "5"],
    ["278", "7"],
    ["359", "7"],
    ["490", "3"]
],
"18/04/2024": [
    ["338", "4"],
    ["130", "4"],
    ["359", "7"],
    ["225", "9"],
    ["567", "8"],
    ["489", "1"],
    ["260", "8"],
    ["146", "1"],
    ["889", "5"],
    ["569", "0"],
    ["460", "0"],
    ["557", "7"]
],
"19/04/2024": [
    ["235", "0"],
    ["237", "2"],
    ["589", "2"],
    ["356", "4"],
    ["357", "5"],
    ["890", "7"],
    ["457", "6"],
    ["136", "0"],
    ["358", "6"],
    ["300", "3"],
    ["669", "1"],
    ["159", "5"]
],
"20/04/2024": [
    ["189", "8"],
    ["350", "8"],
    ["148", "3"],
    ["550", "0"],
    ["470", "1"],
    ["235", "0"],
    ["456", "5"],
    ["388", "9"],
    ["670", "3"],
    ["379", "9"],
    ["468", "8"],
    ["245", "1"]
],
"21/04/2024": [
    ["690", "5"],
    ["330", "6"],
    ["259", "6"],
    ["458", "7"],
    ["389", "0"],
    ["140", "5"],
    ["237", "2"],
    ["158", "4"],
    ["789", "4"],
    ["480", "2"],
    ["137", "1"],
    ["245", "1"]
],
"22/04/2024": [
    ["379", "9"],
    ["590", "4"],
    ["678", "1"],
    ["247", "3"],
    ["779", "3"],
    ["168", "5"],
    ["560", "1"],
    ["449", "7"],
    ["230", "5"],
    ["589", "2"],
    ["790", "6"],
    ["129", "2"]
],
"23/04/2024": [
    ["490", "3"],
    ["124", "7"],
    ["224", "8"],
    ["168", "5"],
    ["257", "4"],
    ["350", "8"],
    ["579", "1"],
    ["347", "4"],
    ["668", "0"],
    ["479", "0"],
    ["268", "6"],
    ["780", "5"]
],
"24/04/2024": [
    ["460", "0"],
    ["699", "4"],
    ["569", "0"],
    ["245", "1"],
    ["790", "6"],
    ["178", "6"],
    ["500", "5"],
    ["388", "9"],
    ["357", "5"],
    ["478", "9"],
    ["560", "1"],
    ["140", "5"]
],
"25/04/2024": [
    ["136", "0"],
    ["270", "9"],
    ["448", "6"],
    ["370", "0"],
    ["568", "9"],
    ["377", "7"],
    ["367", "6"],
    ["589", "2"],
    ["336", "2"],
    ["236", "1"],
    ["468", "8"],
    ["457", "6"]
],
"26/04/2024": [
    ["480", "2"],
    ["779", "3"],
    ["680", "4"],
    ["455", "4"],
    ["256", "3"],
    ["145", "0"],
    ["360", "9"],
    ["168", "5"],
    ["247", "3"],
    ["389", "0"],
    ["456", "5"],
    ["360", "9"]
]
,

"27/04/2024": [
  ["178", "6"], 
  ["570", "2"], 
  ["239", "4"], 
  ["168", "5"], 
  ["348", "5"], 
  ["123", "6"], 
  ["789", "4"], 
  ["224", "8"], 
  ["279", "8"], 
  ["115", "7"], 
  ["158", "4"], 
  ["359", "7"]
],
"28/04/2024": [
  ["230", "5"], 
  ["229", "3"], 
  ["379", "9"], 
  ["150", "6"], 
  ["236", "1"], 
  ["119", "1"], 
  ["470", "1"], 
  ["240", "6"], 
  ["250", "7"], 
  ["556", "6"], 
  ["680", "4"], 
  ["480", "2"]
],
"29/04/2024": [
  ["466", "6"], 
  ["170", "8"], 
  ["180", "9"], 
  ["258", "5"], 
  ["128", "1"], 
  ["124", "7"], 
  ["569", "0"], 
  ["278", "7"], 
  ["349", "6"], 
  ["568", "9"], 
  ["458", "7"], 
  ["346", "3"]
],
"30/04/2024": [
  ["450", "9"], 
  ["129", "2"], 
  ["357", "5"], 
  ["467", "7"], 
  ["270", "9"], 
  ["478", "9"], 
  ["260", "8"], 
  ["168", "5"], 
  ["157", "3"], 
  ["389", "0"], 
  ["677", "0"], 
  ["780", "5"]
],
"01/05/2024": [
  ["360", "9"], 
  ["140", "5"], 
  ["455", "4"], 
  ["778", "2"], 
  ["567", "8"], 
  ["669", "1"], 
  ["248", "4"], 
  ["347", "4"], 
  ["234", "9"], 
  ["378", "8"], 
  ["340", "7"], 
  ["116", "8"]
],
"02/05/2024": [
  ["250", "7"], 
  ["129", "2"], 
  ["468", "8"], 
  ["356", "4"], 
  ["234", "9"], 
  ["135", "9"], 
  ["469", "9"], 
  ["336", "2"], 
  ["678", "1"], 
  ["138", "2"], 
  ["226", "0"], 
  ["146", "1"]
],
"03/05/2024": [
  ["156", "2"], 
  ["556", "6"], 
  ["457", "6"], 
  ["366", "5"], 
  ["680", "4"], 
  ["256", "3"], 
  ["790", "6"], 
  ["577", "9"], 
  ["246", "2"], 
  ["139", "3"], 
  ["379", "9"], 
  ["257", "4"]
],
"04/05/2024": [
  ["600", "6"], 
  ["137", "1"], 
  ["140", "5"], 
  ["247", "3"], 
  ["337", "3"], 
  ["234", "9"], 
  ["169", "6"], 
  ["237", "2"], 
  ["459", "8"], 
  ["189", "8"], 
  ["340", "7"], 
  ["470", "1"]
],
"05/05/2024": [
  ["340", "7"], 
  ["136", "0"], 
  ["678", "1"], 
  ["260", "8"], 
  ["147", "2"], 
  ["360", "9"], 
  ["257", "4"], 
  ["228", "2"], 
  ["130", "4"], 
  ["478", "9"], 
  ["267", "5"], 
  ["889", "5"]
],
"06/05/2024": [
  ["357", "5"], 
  ["567", "8"], 
  ["277", "6"], 
  ["350", "8"], 
  ["356", "4"], 
  ["125", "8"], 
  ["229", "3"], 
  ["679", "2"], 
  ["340", "7"], 
  ["237", "2"], 
  ["110", "2"], 
  ["245", "1"]
],
"07/05/2024": [
  ["390", "2"], 
  ["489", "1"], 
  ["244", "0"], 
  ["137", "1"], 
  ["279", "8"], 
  ["468", "8"], 
  ["890", "7"], 
  ["258", "5"], 
  ["780", "5"], 
  ["690", "5"], 
  ["259", "6"], 
  ["370", "0"]
],
"08/05/2024": [
  ["135", "9"], 
  ["460", "0"], 
  ["789", "4"], 
  ["139", "3"], 
  ["590", "4"], 
  ["344", "1"], 
  ["170", "8"], 
  ["240", "6"], 
  ["456", "5"], 
  ["468", "8"], 
  ["457", "6"], 
  ["359", "7"]
],
"09/05/2024": [
  ["379", "9"], 
  ["258", "5"], 
  ["228", "2"], 
  ["458", "7"], 
  ["179", "7"], 
  ["445", "3"], 
  ["390", "2"], 
  ["268", "6"], 
  ["480", "2"], 
  ["588", "1"], 
  ["579", "1"], 
  ["490", "3"]
],
"10/05/2024": [
  ["890", "7"], 
  ["367", "6"], 
  ["668", "0"], 
  ["250", "7"], 
  ["125", "8"], 
  ["289", "9"], 
  ["770", "4"], 
  ["356", "4"], 
  ["149", "4"], 
  ["115", "7"], 
  ["249", "5"], 
  ["113", "5"]
],
"11/05/2024": [
  ["150", "6"], 
  ["346", "3"], 
  ["336", "2"], 
  ["689", "3"], 
  ["459", "8"], 
  ["460", "0"], 
  ["480", "2"], 
  ["290", "1"], 
  ["129", "2"], 
  ["227", "1"], 
  ["155", "1"], 
  ["120", "3"]
],
"12/05/2024": [
  ["790", "6"], 
  ["130", "4"], 
  ["199", "9"], 
  ["450", "9"], 
  ["224", "8"], 
  ["550", "0"], 
  ["590", "4"], 
  ["136", "0"], 
  ["129", "2"], 
  ["560", "1"], 
  ["127", "0"], 
  ["125", "8"]
],
"13/05/2024": [
  ["450", "9"], 
  ["780", "5"], 
  ["136", "0"], 
  ["157", "3"], 
  ["569", "0"], 
  ["233", "8"], 
  ["566", "7"], 
  ["350", "8"], 
  ["459", "8"], 
  ["389", "0"], 
  ["268", "6"], 
  ["114", "6"]
],
"14/05/2024": [
        ["288", "8"], 
        ["145", "0"], 
        ["169", "6"], 
        ["356", "4"], 
        ["268", "6"], 
        ["480", "2"], 
        ["378", "8"], 
        ["119", "1"], 
        ["150", "6"], 
        ["389", "0"], 
        ["468", "8"], 
        ["370", "0"]
    ],
    "15/05/2024": [
        ["449", "7"], 
        ["128", "1"], 
        ["590", "4"], 
        ["346", "3"], 
        ["225", "9"], 
        ["140", "5"], 
        ["469", "9"], 
        ["268", "6"], 
        ["220", "4"], 
        ["680", "4"], 
        ["126", "9"], 
        ["139", "3"]
    ],
    "16/05/2024": [
        ["138", "2"], 
        ["169", "6"], 
        ["790", "6"], 
        ["680", "4"], 
        ["278", "7"], 
        ["120", "3"], 
        ["157", "3"], 
        ["339", "5"], 
        ["223", "7"], 
        ["489", "1"], 
        ["460", "0"], 
        ["134", "8"]
    ],
    "17/05/2024": [
        ["990", "8"], 
        ["160", "7"], 
        ["569", "0"], 
        ["250", "7"], 
        ["579", "1"], 
        ["379", "9"], 
        ["239", "4"], 
        ["135", "9"], 
        ["347", "4"], 
        ["289", "9"], 
        ["125", "8"], 
        ["178", "6"]
    ],
    "18/05/2024": [
        ["459", "8"], 
        ["110", "2"], 
        ["157", "3"], 
        ["226", "0"], 
        ["159", "5"], 
        ["124", "7"], 
        ["389", "0"], 
        ["345", "2"], 
        ["460", "0"], 
        ["456", "5"], 
        ["230", "5"], 
        ["458", "7"]
    ],
    "19/05/2024": [
      ["129", "2"], 
      ["235", "0"], 
      ["269", "7"], 
      ["150", "6"], 
      ["358", "6"], 
      ["360", "9"], 
      ["489", "1"], 
      ["280", "0"], 
      ["334", "0"], 
      ["112", "4"], 
      ["347", "4"], 
      ["688", "2"]
  ],
  "20/05/2024": [
      ["168", "5"], 
      ["338", "4"], 
      ["134", "8"], 
      ["300", "3"], 
      ["279", "8"], 
      ["348", "5"], 
      ["267", "5"], 
      ["590", "4"], 
      ["344", "1"], 
      ["245", "1"], 
      ["158", "4"], 
      ["488", "0"]
  ],
  "21/05/2024": [
      ["350", "8"], 
      ["268", "6"], 
      ["115", "7"], 
      ["680", "4"], 
      ["480", "2"], 
      ["233", "8"], 
      ["129", "2"], 
      ["358", "6"], 
      ["259", "6"], 
      ["124", "7"], 
      ["299", "0"], 
      ["113", "5"]
  ],
  "22/05/2024": [
      ["468", "8"], 
      ["389", "0"], 
      ["150", "6"], 
      ["270", "9"], 
      ["456", "5"], 
      ["689", "3"], 
      ["128", "1"], 
      ["249", "5"], 
      ["660", "2"], 
      ["257", "4"], 
      ["148", "3"], 
      ["568", "9"]
  ],
  "23/05/2024": [
    ["234", "9"], 
    ["147", "2"], 
    ["449", "7"], 
    ["349", "6"], 
    ["479", "0"], 
    ["140", "5"], 
    ["338", "4"], 
    ["245", "1"], 
    ["126", "9"], 
    ["477", "8"], 
    ["238", "3"], 
    ["578", "0"]
],
"24/05/2024": [
    ["289", "9"], 
    ["368", "7"], 
    ["240", "6"], 
    ["579", "1"], 
    ["345", "2"], 
    ["236", "1"], 
    ["247", "3"], 
    ["789", "4"], 
    ["479", "0"], 
    ["780", "5"], 
    ["237", "2"], 
    ["340", "7"]
],
"25/05/2024": [
    ["479", "0"], 
    ["160", "7"], 
    ["348", "5"], 
    ["270", "9"], 
    ["119", "1"], 
    ["346", "3"], 
    ["233", "8"], 
    ["690", "5"], 
    ["589", "2"], 
    ["157", "3"], 
    ["125", "8"], 
    ["246", "2"]
],
"26/05/2024": [
    ["116", "8"], 
    ["178", "6"], 
    ["280", "0"], 
    ["470", "1"], 
    ["347", "4"], 
    ["300", "3"], 
    ["679", "2"], 
    ["334", "0"], 
    ["346", "3"], 
    ["560", "1"], 
    ["590", "4"], 
    ["369", "8"]
],
"27/05/2024": [
  ["478", "9"], 
  ["226", "0"], 
  ["356", "4"], 
  ["223", "7"], 
  ["159", "5"], 
  ["146", "1"], 
  ["578", "0"], 
  ["490", "3"], 
  ["450", "9"], 
  ["330", "6"], 
  ["258", "5"], 
  ["358", "6"]
],
"28/05/2024": [
  ["227", "1"], 
  ["240", "6"], 
  ["589", "2"], 
  ["479", "0"], 
  ["367", "6"], 
  ["249", "5"], 
  ["115", "7"], 
  ["480", "2"], 
  ["135", "9"], 
  ["239", "4"], 
  ["116", "8"], 
  ["270", "9"]
],
"29/05/2024": [
  ["578", "0"], 
  ["166", "3"], 
  ["167", "4"], 
  ["358", "6"], 
  ["247", "3"], 
  ["449", "7"], 
  ["189", "8"], 
  ["234", "9"], 
  ["236", "1"], 
  ["468", "8"], 
  ["380", "1"], 
  ["122", "5"]
],
"30/05/2024": [
  ["480", "2"], 
  ["125", "8"], 
  ["190", "0"], 
  ["149", "4"], 
  ["550", "0"], 
  ["335", "1"], 
  ["246", "2"], 
  ["150", "6"], 
  ["389", "0"], 
  ["225", "9"], 
  ["790", "6"], 
  ["469", "9"]
],
"31/05/2024": [
  ["469", "9"], 
  ["248", "4"], 
  ["500", "5"], 
  ["290", "1"], 
  ["468", "8"], 
  ["359", "7"], 
  ["148", "3"], 
  ["269", "7"], 
  ["134", "8"], 
  ["370", "0"], 
  ["458", "7"], 
  ["589", "2"]
],
"01/06/2024": [
  ["240", "6"], 
  ["117", "9"], 
  ["226", "0"], 
  ["246", "2"], 
  ["169", "6"], 
  ["560", "1"], 
  ["234", "9"], 
  ["770", "4"], 
  ["129", "2"], 
  ["369", "8"], 
  ["123", "6"], 
  ["390", "2"]
],
"02/06/2024": [
  ["237", "2"], 
  ["470", "1"], 
  ["458", "7"], 
  ["358", "6"], 
  ["145", "0"], 
  ["580", "3"], 
  ["166", "3"], 
  ["460", "0"], 
  ["233", "8"], 
  ["136", "0"], 
  ["167", "4"], 
  ["367", "6"]
],
"03/06/2024": [
  ["179", "7"], 
  ["279", "8"], 
  ["388", "9"], 
  ["113", "5"], 
  ["456", "5"], 
  ["189", "8"], 
  ["880", "6"], 
  ["345", "2"], 
  ["780", "5"], 
  ["356", "4"], 
  ["480", "2"], 
  ["120", "3"]
],
"04/06/2024": [
  ["578", "0"], 
  ["159", "5"], 
  ["123", "6"], 
  ["440", "8"], 
  ["149", "4"], 
  ["369", "8"], 
  ["377", "7"], 
  ["155", "1"], 
  ["560", "1"], 
  ["178", "6"], 
  ["336", "2"], 
  ["137", "1"]
],
"05/06/2024": [
  ["570", "2"], 
  ["134", "8"], 
  ["229", "3"], 
  ["170", "8"], 
  ["490", "3"], 
  ["388", "9"], 
  ["580", "3"], 
  ["144", "9"], 
  ["469", "9"], 
  ["890", "7"], 
  ["556", "6"], 
  ["678", "1"]
],
"06/06/2024": [
  ["180", "9"], 
  ["245", "1"], 
  ["359", "7"], 
  ["238", "3"], 
  ["357", "5"], 
  ["140", "5"], 
  ["300", "3"], 
  ["446", "4"], 
  ["590", "4"], 
  ["258", "5"], 
  ["347", "4"], 
  ["779", "3"]
],
"07/06/2024": [
  ["689", "3"], 
  ["366", "5"], 
  ["467", "7"], 
  ["116", "8"], 
  ["299", "0"], 
  ["168", "5"], 
  ["150", "6"], 
  ["235", "0"], 
  ["127", "0"], 
  ["248", "4"], 
  ["336", "2"], 
  ["568", "9"]
],
"08/06/2024": [
  ["578", "0"], 
  ["344", "1"], 
  ["245", "1"], 
  ["124", "7"], 
  ["249", "5"], 
  ["569", "0"], 
  ["350", "8"], 
  ["225", "9"], 
  ["138", "2"], 
  ["667", "9"], 
  ["459", "8"], 
  ["288", "8"]
],
"09/06/2024": [
  ["360", "9"], 
  ["125", "8"], 
  ["300", "3"], 
  ["689", "3"], 
  ["127", "0"], 
  ["246", "2"], 
  ["489", "1"], 
  ["239", "4"], 
  ["128", "1"], 
  ["346", "3"], 
  ["126", "9"], 
  ["280", "0"]
],
"10/06/2024": [
  ["460", "0"], 
  ["188", "7"], 
  ["260", "8"], 
  ["579", "1"], 
  ["225", "9"], 
  ["178", "6"], 
  ["257", "4"], 
  ["348", "5"], 
  ["189", "8"], 
  ["390", "2"], 
  ["238", "3"], 
  ["679", "2"]
],
"11/06/2024": [
        ["110", "2"], 
        ["167", "4"], 
        ["259", "6"], 
        ["160", "7"], 
        ["389", "0"], 
        ["450", "9"], 
        ["558", "8"], 
        ["335", "1"], 
        ["150", "6"], 
        ["378", "8"], 
        ["367", "6"], 
        ["770", "4"]
    ],
    "12/06/2024": [
        ["478", "9"], 
        ["588", "1"], 
        ["280", "0"], 
        ["339", "5"], 
        ["445", "3"], 
        ["135", "9"], 
        ["358", "6"], 
        ["260", "8"], 
        ["379", "9"], 
        ["490", "3"], 
        ["236", "1"], 
        ["589", "2"]
    ],
    "13/06/2024": [
        ["190", "0"], 
        ["160", "7"], 
        ["469", "9"], 
        ["169", "6"], 
        ["590", "4"], 
        ["448", "6"], 
        ["235", "0"], 
        ["399", "1"], 
        ["580", "3"], 
        ["256", "3"], 
        ["134", "8"], 
        ["289", "9"]
    ],
    "14/06/2024": [
        ["139", "3"], 
        ["799", "5"], 
        ["149", "4"], 
        ["890", "7"], 
        ["577", "9"], 
        ["267", "5"], 
        ["336", "2"], 
        ["237", "2"], 
        ["330", "6"], 
        ["246", "2"], 
        ["350", "8"], 
        ["889", "5"]
    ],
    "15/06/2024": [
      ["348", "5"], 
      ["127", "0"], 
      ["500", "5"], 
      ["689", "3"], 
      ["580", "3"], 
      ["470", "1"], 
      ["788", "3"], 
      ["230", "5"], 
      ["100", "1"], 
      ["349", "6"], 
      ["128", "1"], 
      ["190", "0"]
  ],
  "16/06/2024": [
      ["157", "3"], 
      ["499", "2"], 
      ["225", "9"], 
      ["147", "2"], 
      ["380", "1"], 
      ["112", "4"], 
      ["579", "1"], 
      ["149", "4"], 
      ["240", "6"], 
      ["389", "0"], 
      ["159", "5"], 
      ["250", "7"]
  ],
  "17/06/2024": [
      ["569", "0"], 
      ["169", "6"], 
      ["245", "1"], 
      ["158", "4"], 
      ["699", "4"], 
      ["230", "5"], 
      ["688", "2"], 
      ["557", "7"], 
      ["233", "8"], 
      ["130", "4"], 
      ["124", "7"], 
      ["390", "2"]
  ],
  "18/06/2024": [
      ["369", "8"], 
      ["458", "7"], 
      ["145", "0"], 
      ["228", "2"], 
      ["166", "3"], 
      ["460", "0"], 
      ["690", "5"], 
      ["448", "6"], 
      ["256", "3"], 
      ["588", "1"], 
      ["136", "0"], 
      ["239", "4"]
  ],
  "19/06/2024": [
    ["237", "2"], 
    ["338", "4"], 
    ["144", "9"], 
    ["135", "9"], 
    ["669", "1"], 
    ["568", "9"], 
    ["368", "7"], 
    ["169", "6"], 
    ["236", "1"], 
    ["235", "0"], 
    ["137", "1"], 
    ["889", "5"]
],
"20/06/2024": [
    ["477", "8"], 
    ["136", "0"], 
    ["360", "9"], 
    ["129", "2"], 
    ["178", "6"], 
    ["588", "1"], 
    ["120", "3"], 
    ["144", "9"], 
    ["779", "3"], 
    ["490", "3"], 
    ["156", "2"], 
    ["569", "0"]
],
"21/06/2024": [
    ["139", "3"], 
    ["455", "4"], 
    ["156", "2"], 
    ["490", "3"], 
    ["380", "1"], 
    ["267", "5"], 
    ["159", "5"], 
    ["126", "9"], 
    ["458", "7"], 
    ["589", "2"], 
    ["389", "0"], 
    ["450", "9"]
],
"22/06/2024": [
    ["457", "6"], 
    ["346", "3"], 
    ["260", "8"], 
    ["780", "5"], 
    ["116", "8"], 
    ["358", "6"], 
    ["280", "0"], 
    ["449", "7"], 
    ["370", "0"], 
    ["356", "4"], 
    ["179", "7"], 
    ["249", "5"]
],
"23/06/2024": [
  ["127", "0"], 
  ["270", "9"], 
  ["267", "5"], 
  ["380", "1"], 
  ["230", "5"], 
  ["235", "0"], 
  ["137", "1"], 
  ["340", "7"], 
  ["346", "3"], 
  ["460", "0"], 
  ["238", "3"], 
  ["279", "8"]
],
"24/06/2024": [
  ["490", "3"], 
  ["235", "0"], 
  ["567", "8"], 
  ["679", "2"], 
  ["233", "8"], 
  ["590", "4"], 
  ["114", "6"], 
  ["145", "0"], 
  ["589", "2"], 
  ["377", "7"], 
  ["140", "5"], 
  ["367", "6"]
],
"25/06/2024": [
  ["289", "9"], 
  ["135", "9"], 
  ["344", "1"], 
  ["237", "2"], 
  ["889", "5"], 
  ["277", "6"], 
  ["456", "5"], 
  ["112", "4"], 
  ["378", "8"], 
  ["168", "5"], 
  ["347", "4"], 
  ["236", "1"]
],
"26/06/2024": [
  ["149", "4"], 
  ["190", "0"], 
  ["249", "5"], 
  ["790", "6"], 
  ["468", "8"], 
  ["380", "1"], 
  ["778", "2"], 
  ["568", "9"], 
  ["479", "0"], 
  ["569", "0"], 
  ["138", "2"], 
  ["268", "6"]
],
"27/06/2024": [
  ["114", "6"], 
  ["679", "2"], 
  ["457", "6"], 
  ["599", "3"], 
  ["127", "0"], 
  ["478", "9"], 
  ["280", "0"], 
  ["355", "3"], 
  ["223", "7"], 
  ["450", "9"], 
  ["167", "4"], 
  ["233", "8"]
],
"28/06/2024": [
  ["118", "0"], 
  ["130", "4"], 
  ["366", "5"], 
  ["380", "1"], 
  ["239", "4"], 
  ["379", "9"], 
  ["267", "5"], 
  ["680", "4"], 
  ["139", "3"], 
  ["567", "8"], 
  ["689", "3"], 
  ["346", "3"]
],
"29/06/2024": [
  ["237", "2"], 
  ["147", "2"], 
  ["160", "7"], 
  ["169", "6"], 
  ["156", "2"], 
  ["150", "6"], 
  ["370", "0"], 
  ["114", "6"], 
  ["179", "7"], 
  ["590", "4"], 
  ["125", "8"], 
  ["130", "4"]
],
"30/06/2024": [
  ["458", "7"], 
  ["260", "8"], 
  ["229", "3"], 
  ["168", "5"], 
  ["480", "2"], 
  ["148", "3"], 
  ["220", "4"], 
  ["780", "5"], 
  ["357", "5"], 
  ["244", "0"], 
  ["467", "7"], 
  ["345", "2"]
],
"01/07/2024": [
  ["189", "8"], 
  ["560", "1"], 
  ["448", "6"], 
  ["225", "9"], 
  ["140", "5"], 
  ["277", "6"], 
  ["457", "6"], 
  ["690", "5"], 
  ["279", "8"], 
  ["469", "9"], 
  ["566", "7"], 
  ["226", "0"]
],
"02/07/2024": [
  ["369", "8"], 
  ["589", "2"], 
  ["180", "9"], 
  ["278", "7"], 
  ["167", "4"], 
  ["556", "6"], 
  ["148", "3"], 
  ["668", "0"], 
  ["446", "4"], 
  ["370", "0"], 
  ["166", "3"], 
  ["257", "4"]
],
"03/07/2024": [
  ["230", "5"], 
  ["346", "3"], 
  ["179", "7"], 
  ["350", "8"], 
  ["117", "9"], 
  ["569", "0"], 
  ["130", "4"], 
  ["349", "6"], 
  ["679", "2"], 
  ["680", "4"], 
  ["169", "6"], 
  ["467", "7"]
],
"04/07/2024": [
  ["269", "7"], 
  ["245", "1"], 
  ["115", "7"], 
  ["580", "3"], 
  ["389", "0"], 
  ["170", "8"], 
  ["126", "9"], 
  ["289", "9"], 
  ["390", "2"], 
  ["224", "8"], 
  ["458", "7"], 
  ["136", "0"]
],
"05/07/2024": [
  ["338", "4"], 
  ["157", "3"], 
  ["366", "5"], 
  ["249", "5"], 
  ["145", "0"], 
  ["348", "5"], 
  ["570", "2"], 
  ["378", "8"], 
  ["227", "1"], 
  ["357", "5"], 
  ["579", "1"], 
  ["160", "7"]
],
"06/07/2024": [
  ["144", "9"], 
  ["330", "6"], 
  ["178", "6"], 
  ["558", "8"], 
  ["168", "5"], 
  ["258", "5"], 
  ["336", "2"], 
  ["457", "6"], 
  ["129", "2"], 
  ["550", "0"], 
  ["570", "2"], 
  ["279", "8"]
],
"07/07/2024": [
  ["130", "4"], 
  ["400", "4"], 
  ["166", "3"], 
  ["470", "1"], 
  ["680", "4"], 
  ["357", "5"], 
  ["245", "1"], 
  ["160", "7"], 
  ["258", "5"], 
  ["137", "1"], 
  ["140", "5"], 
  ["120", "3"]
],
"08/07/2024": [
  ["123", "6"], 
  ["229", "3"], 
  ["127", "0"], 
  ["780", "5"], 
  ["458", "7"], 
  ["117", "9"], 
  ["124", "7"], 
  ["239", "4"], 
  ["157", "3"], 
  ["467", "7"], 
  ["669", "1"], 
  ["478", "9"]
],
"09/07/2024": [
  ["366", "5"], 
  ["679", "2"], 
  ["388", "9"], 
  ["233", "8"], 
  ["159", "5"], 
  ["445", "3"], 
  ["268", "6"], 
  ["356", "4"], 
  ["378", "8"], 
  ["578", "0"], 
  ["367", "6"], 
  ["256", "3"]
],
"10/07/2024": [
  ["220", "4"], 
  ["350", "8"], 
  ["557", "7"], 
  ["259", "6"], 
  ["678", "1"], 
  ["134", "8"], 
  ["148", "3"], 
  ["240", "6"], 
  ["580", "3"], 
  ["234", "9"], 
  ["459", "8"], 
  ["159", "5"]
],
"11/07/2024": [
  ["390", "2"], 
  ["248", "4"], 
  ["670", "3"], 
  ["133", "7"], 
  ["457", "6"], 
  ["360", "9"], 
  ["560", "1"], 
  ["279", "8"], 
  ["230", "5"], 
  ["149", "4"], 
  ["368", "7"], 
  ["237", "2"]
],
"12/07/2024": [
  ["340", "7"], 
  ["144", "9"], 
  ["150", "6"], 
  ["356", "4"], 
  ["488", "0"], 
  ["349", "6"], 
  ["244", "0"], 
  ["790", "6"], 
  ["166", "3"], 
  ["339", "5"], 
  ["136", "0"], 
  ["257", "4"]
],
"13/07/2024": [
  ["370", "0"], 
  ["233", "8"], 
  ["169", "6"], 
  ["290", "1"], 
  ["356", "4"], 
  ["110", "2"], 
  ["560", "1"], 
  ["200", "2"], 
  ["579", "1"], 
  ["470", "1"], 
  ["499", "2"], 
  ["116", "8"]
],
"14/07/2024": [
  ["889", "5"], 
  ["137", "1"], 
  ["680", "4"], 
  ["223", "7"], 
  ["467", "7"], 
  ["578", "0"], 
  ["170", "8"], 
  ["348", "5"], 
  ["147", "2"], 
  ["270", "9"], 
  ["456", "5"], 
  ["269", "7"]
],
"15/07/2024": [
  ["157", "3"], 
  ["250", "7"], 
  ["336", "2"], 
  ["134", "8"], 
  ["470", "1"], 
  ["389", "0"], 
  ["225", "9"], 
  ["167", "4"], 
  ["447", "5"], 
  ["158", "4"], 
  ["235", "0"], 
  ["168", "5"]
],
"16/07/2024": [
  ["450", "9"], 
  ["569", "0"], 
  ["259", "6"], 
  ["367", "6"], 
  ["160", "7"], 
  ["246", "2"], 
  ["380", "1"], 
  ["566", "7"], 
  ["224", "8"], 
  ["128", "1"], 
  ["445", "3"], 
  ["112", "4"]
],
"17/07/2024": [
  ["339", "5"], 
  ["390", "2"], 
  ["489", "1"], 
  ["266", "4"], 
  ["469", "9"], 
  ["347", "4"], 
  ["145", "0"], 
  ["378", "8"], 
  ["157", "3"], 
  ["346", "3"], 
  ["789", "4"], 
  ["156", "2"]
],
"18/07/2024": [
  ["139", "3"], 
  ["468", "8"], 
  ["360", "9"], 
  ["236", "1"], 
  ["179", "7"], 
  ["690", "5"], 
  ["457", "6"], 
  ["125", "8"], 
  ["136", "0"], 
  ["460", "0"], 
  ["279", "8"], 
  ["347", "4"]
],
"19/07/2024": [
  ["340", "7"], 
  ["126", "9"], 
  ["490", "3"], 
  ["355", "3"], 
  ["578", "0"], 
  ["990", "8"], 
  ["289", "9"], 
  ["389", "0"], 
  ["137", "1"], 
  ["227", "1"], 
  ["447", "5"], 
  ["140", "5"]
],
"20/07/2024": [
  ["146", "1"], 
  ["280", "0"], 
  ["379", "9"], 
  ["258", "5"], 
  ["349", "6"], 
  ["457", "6"], 
  ["580", "3"], 
  ["133", "7"], 
  ["234", "9"], 
  ["368", "7"], 
  ["578", "0"], 
  ["240", "6"]
],
"21/07/2024": [
  ["137", "1"], 
  ["159", "5"], 
  ["180", "9"], 
  ["779", "3"], 
  ["188", "7"], 
  ["477", "8"], 
  ["390", "2"], 
  ["469", "9"], 
  ["378", "8"], 
  ["359", "7"], 
  ["500", "5"], 
  ["270", "9"]
],
"22/07/2024": [
  ["226", "0"], 
  ["179", "7"], 
  ["233", "8"], 
  ["567", "8"], 
  ["346", "3"], 
  ["230", "5"], 
  ["148", "3"], 
  ["480", "2"], 
  ["150", "6"], 
  ["139", "3"], 
  ["459", "8"], 
  ["368", "7"]
],
"23/07/2024": [
  ["245", "1"],
  ["488", "0"],
  ["556", "6"],
  ["126", "9"],
  ["450", "9"],
  ["224", "8"],
  ["246", "2"],
  ["135", "9"],
  ["337", "3"],
  ["236", "1"],
  ["258", "5"],
  ["350", "8"]
],
"24/07/2024": [
  ["189", "8"],
  ["223", "7"],
  ["479", "0"],
  ["268", "6"],
  ["370", "0"],
  ["589", "2"],
  ["156", "2"],
  ["790", "6"],
  ["489", "1"],
  ["238", "3"],
  ["270", "9"],
  ["168", "5"]
],
"25/07/2024": [
  ["780", "5"],
  ["568", "9"],
  ["128", "1"],
  ["678", "1"],
  ["229", "3"],
  ["478", "9"],
  ["250", "7"],
  ["334", "0"],
  ["169", "6"],
  ["160", "7"],
  ["228", "2"],
  ["379", "9"]
],
"26/07/2024": [
  ["126", "9"],
  ["237", "2"],
  ["670", "3"],
  ["350", "8"],
  ["269", "7"],
  ["248", "4"],
  ["124", "7"],
  ["225", "9"],
  ["112", "4"],
  ["289", "9"],
  ["580", "3"],
  ["448", "6"]
],
"27/07/2024": [
  ["800", "8"],
  ["280", "0"],
  ["566", "7"],
  ["267", "5"],
  ["289", "9"],
  ["245", "1"],
  ["447", "5"],
  ["159", "5"],
  ["147", "2"],
  ["336", "2"],
  ["677", "0"],
  ["110", "2"]
],
"28/07/2024": [
  ["380", "1"],
  ["559", "9"],
  ["135", "9"],
  ["345", "2"],
  ["880", "6"],
  ["489", "1"],
  ["170", "8"],
  ["680", "4"],
  ["257", "4"],
  ["358", "6"],
  ["158", "4"],
  ["355", "3"]
],
"29/07/2024": [
  ["590", "4"],
  ["359", "7"],
  ["255", "2"],
  ["260", "8"],
  ["458", "7"],
  ["579", "1"],
  ["237", "2"],
  ["677", "0"],
  ["240", "6"],
  ["140", "5"],
  ["556", "6"],
  ["137", "1"]
],
"30/07/2024": [
  ["457", "6"],
  ["159", "5"],
  ["239", "4"],
  ["347", "4"],
  ["267", "5"],
  ["660", "2"],
  ["117", "9"],
  ["470", "1"],
  ["368", "7"],
  ["250", "7"],
  ["145", "0"],
  ["234", "9"]
],
"31/07/2024": [
  ["270", "9"],
  ["134", "8"],
  ["478", "9"],
  ["149", "4"],
  ["569", "0"],
  ["477", "8"],
  ["114", "6"],
  ["289", "9"],
  ["344", "1"],
  ["177", "5"],
  ["189", "8"],
  ["229", "3"]
],
"01/08/2024": [
  ["678", "1"],
  ["366", "5"],
  ["157", "3"],
  ["580", "3"],
  ["238", "3"],
  ["479", "0"],
  ["179", "7"],
  ["560", "1"],
  ["679", "2"],
  ["380", "1"],
  ["467", "7"],
  ["235", "0"]
],
"02/08/2024": [
  ["456", "5"],
  ["233", "8"],
  ["139", "3"],
  ["567", "8"],
  ["345", "2"],
  ["119", "1"],
  ["277", "6"],
  ["134", "8"],
  ["226", "0"],
  ["790", "6"],
  ["349", "6"],
  ["579", "1"]
], "03/08/2024": [
  ["266", "4"],
  ["120", "3"],
  ["245", "1"],
  ["667", "9"],
  ["133", "7"],
  ["359", "7"],
  ["290", "1"],
  ["167", "4"],
  ["670", "3"],
  ["228", "2"],
  ["147", "2"],
  ["490", "3"]
],
"04/08/2024": [
  ["138", "2"],
  ["500", "5"],
  ["470", "1"],
  ["277", "6"],
  ["127", "0"],
  ["178", "6"],
  ["157", "3"],
  ["167", "4"],
  ["224", "8"],
  ["499", "2"],
  ["118", "0"],
  ["380", "1"]
],
"05/08/2024": [
  ["257", "4"],
  ["370", "0"],
  ["247", "3"],
  ["346", "3"],
  ["459", "8"],
  ["148", "3"],
  ["268", "6"],
  ["159", "5"],
  ["357", "5"],
  ["447", "5"],
  ["236", "1"],
  ["160", "7"]
],
"06/08/2024": [
  ["360", "9"],
  ["250", "7"],
  ["223", "7"],
  ["556", "6"],
  ["168", "5"],
  ["358", "6"],
  ["669", "1"],
  ["180", "9"],
  ["445", "3"],
  ["290", "1"],
  ["233", "8"],
  ["134", "8"]
],
"07/08/2024": [
  ["114", "6"],
  ["269", "7"],
  ["580", "3"],
  ["380", "1"],
  ["489", "1"],
  ["122", "5"],
  ["129", "2"],
  ["558", "8"],
  ["246", "2"],
  ["480", "2"],
  ["890", "7"],
  ["169", "6"]
],
"08/08/2024": [
  ["470", "1"],
  ["156", "2"],
  ["567", "8"],
  ["140", "5"],
  ["349", "6"],
  ["557", "7"],
  ["238", "3"],
  ["469", "9"],
  ["330", "6"],
  ["778", "2"],
  ["550", "0"],
  ["126", "9"]
],
"09/08/2024": [
  ["457", "6"],
  ["239", "4"],
  ["590", "4"],
  ["255", "2"],
  ["449", "7"],
  ["240", "6"],
  ["178", "6"],
  ["800", "8"],
  ["167", "4"],
  ["134", "8"],
  ["259", "6"],
  ["789", "4"]
],
"10/08/2024": [
  ["288", "8"],
  ["190", "0"],
  ["569", "0"],
  ["256", "3"],
  ["347", "4"],
  ["600", "6"],
  ["235", "0"],
  ["790", "6"],
  ["400", "4"],
  ["120", "3"],
  ["166", "3"],
  ["677", "0"]
],
"11/08/2024": [
  ["580", "3"],
  ["169", "6"],
  ["679", "2"],
  ["478", "9"],
  ["570", "2"],
  ["156", "2"],
  ["460", "0"],
  ["278", "7"],
  ["140", "5"],
  ["468", "8"],
  ["690", "5"],
  ["359", "7"]
],
"12/08/2024": [
        ["448", "6"],
        ["280", "0"],
        ["236", "1"],
        ["145", "0"],
        ["355", "3"],
        ["137", "1"],
        ["245", "1"],
        ["455", "4"],
        ["366", "5"],
        ["168", "5"],
        ["190", "0"],
        ["599", "3"]
    ],
    "13/08/2024": [
        ["127", "0"],
        ["689", "3"],
        ["380", "1"],
        ["378", "8"],
        ["255", "2"],
        ["135", "9"],
        ["880", "6"],
        ["334", "0"],
        ["130", "4"],
        ["277", "6"],
        ["379", "9"],
        ["790", "6"]
    ],
    "14/08/2024": [
        ["346", "3"],
        ["237", "2"],
        ["117", "9"],
        ["450", "9"],
        ["344", "1"],
        ["480", "2"],
        ["266", "4"],
        ["560", "1"],
        ["136", "0"],
        ["390", "2"],
        ["235", "0"],
        ["179", "7"]
    ],
    "15/08/2024": [
        ["126", "9"],
        ["356", "4"],
        ["699", "4"],
        ["460", "0"],
        ["300", "3"],
        ["469", "9"],
        ["223", "7"],
        ["160", "7"],
        ["148", "3"],
        ["347", "4"],
        ["890", "7"],
        ["359", "7"]
    ],
    "16/08/2024": [
      ["669", "1"],
      ["368", "7"],
      ["246", "2"],
      ["139", "3"],
      ["350", "8"],
      ["670", "3"],
      ["166", "3"],
      ["220", "4"],
      ["333", "9"],
      ["170", "8"],
      ["244", "0"],
      ["260", "8"]
  ],
  "17/08/2024": [
      ["478", "9"],
      ["224", "8"],
      ["120", "3"],
      ["358", "6"],
      ["270", "9"],
      ["578", "0"],
      ["458", "7"],
      ["389", "0"],
      ["558", "8"],
      ["140", "5"],
      ["457", "6"],
      ["360", "9"]
  ],
  "18/08/2024": [
      ["112", "4"],
      ["469", "9"],
      ["579", "1"],
      ["199", "9"],
      ["228", "2"],
      ["155", "1"],
      ["136", "0"],
      ["169", "6"],
      ["599", "3"],
      ["290", "1"],
      ["126", "9"],
      ["240", "6"]
  ],
  "19/08/2024": [
    ["234", "9"],
    ["456", "5"],
    ["159", "5"],
    ["245", "1"],
    ["157", "3"],
    ["356", "4"],
    ["470", "1"],
    ["589", "2"],
    ["158", "4"],
    ["680", "4"],
    ["147", "2"],
    ["290", "1"]
],
"20/08/2024": [
    ["350", "8"],
    ["889", "5"],
    ["229", "3"],
    ["450", "9"],
    ["349", "6"],
    ["267", "5"],
    ["113", "5"],
    ["380", "1"],
    ["256", "3"],
    ["699", "4"],
    ["390", "2"],
    ["246", "2"]
],
"21/08/2024": [
    ["137", "1"],
    ["235", "0"],
    ["370", "0"],
    ["230", "5"],
    ["124", "7"],
    ["567", "8"],
    ["670", "3"],
    ["139", "3"],
    ["477", "8"],
    ["277", "6"],
    ["226", "0"],
    ["159", "5"]
],
"22/08/2024": [
    ["578", "0"],
    ["799", "5"],
    ["336", "2"],
    ["145", "0"],
    ["570", "2"],
    ["236", "1"],
    ["660", "2"],
    ["445", "3"],
    ["480", "2"],
    ["119", "1"],
    ["556", "6"],
    ["111", "3"]
],
"23/08/2024": [
  ["168", "5"],
  ["277", "6"],
  ["199", "9"],
  ["345", "2"],
  ["489", "1"],
  ["467", "7"],
  ["138", "2"],
  ["389", "0"],
  ["569", "0"],
  ["460", "0"],
  ["156", "2"],
  ["468", "8"]
],
"24/08/2024": [
  ["239", "4"],
  ["367", "6"],
  ["600", "6"],
  ["149", "4"],
  ["125", "8"],
  ["124", "7"],
  ["145", "0"],
  ["157", "3"],
  ["358", "6"],
  ["370", "0"],
  ["390", "2"],
  ["169", "6"]
],
"25/08/2024": [
  ["590", "4"],
  ["679", "2"],
  ["236", "1"],
  ["790", "6"],
  ["248", "4"],
  ["290", "1"],
  ["780", "5"],
  ["348", "5"],
  ["160", "7"],
  ["137", "1"],
  ["267", "5"],
  ["135", "9"]
],
"26/08/2024": [
  ["458", "7"],
  ["148", "3"],
  ["278", "7"],
  ["145", "0"],
  ["235", "0"],
  ["567", "8"],
  ["470", "1"],
  ["677", "0"],
  ["335", "1"],
  ["577", "9"],
  ["336", "2"],
  ["238", "3"]
],
"27/08/2024": [
  ["778", "2"],
  ["899", "6"],
  ["130", "4"],
  ["579", "1"],
  ["257", "4"],
  ["158", "4"],
  ["147", "2"],
  ["448", "6"],
  ["118", "0"],
  ["570", "2"],
  ["444", "2"],
  ["337", "3"]
],
"28/08/2024": [
  ["469", "9"],
  ["259", "6"],
  ["168", "5"],
  ["368", "7"],
  ["680", "4"],
  ["890", "7"],
  ["357", "5"],
  ["180", "9"],
  ["356", "4"],
  ["269", "7"],
  ["366", "5"],
  ["267", "5"]
],
"29/08/2024": [
  ["159", "5"],
  ["334", "0"],
  ["149", "4"],
  ["479", "0"],
  ["278", "7"],
  ["350", "8"],
  ["134", "8"],
  ["369", "8"],
  ["248", "4"],
  ["127", "0"],
  ["460", "0"],
  ["128", "1"]
],
"30/08/2024": [
  ["458", "7"],
  ["245", "1"],
  ["189", "8"],
  ["139", "3"],
  ["669", "1"],
  ["560", "1"],
  ["125", "8"],
  ["270", "9"],
  ["568", "9"],
  ["268", "6"],
  ["900", "9"],
  ["790", "6"]
],
"31/08/2024": [
  ["150", "6"],
  ["237", "2"],
  ["990", "8"],
  ["570", "2"],
  ["129", "2"],
  ["240", "6"],
  ["235", "0"],
  ["447", "5"],
  ["227", "1"],
  ["137", "1"],
  ["559", "9"],
  ["145", "0"]
],
"01/09/2024": [
  ["770", "4"],
  ["689", "3"],
  ["399", "1"],
  ["127", "0"],
  ["449", "7"],
  ["138", "2"],
  ["369", "8"],
  ["117", "9"],
  ["126", "9"],
  ["267", "5"],
  ["468", "8"],
  ["567", "8"]
],
"02/09/2024": [
  ["167", "4"],
  ["339", "5"],
  ["480", "2"],
  ["166", "3"],
  ["256", "3"],
  ["244", "0"],
  ["445", "3"],
  ["469", "9"],
  ["789", "4"],
  ["149", "4"],
  ["580", "3"],
  ["335", "1"]
],
"03/09/2024": [
  ["500", "5"],
  ["466", "6"],
  ["790", "6"],
  ["360", "9"],
  ["119", "1"],
  ["340", "7"],
  ["800", "8"],
  ["289", "9"],
  ["366", "5"],
  ["236", "1"],
  ["379", "9"],
  ["225", "9"]
],
"04/09/2024": [
  ["237", "2"],
  ["670", "3"],
  ["446", "4"],
  ["370", "0"],
  ["189", "8"],
  ["169", "6"],
  ["116", "8"],
  ["239", "4"],
  ["337", "3"],
  ["450", "9"],
  ["160", "7"],
  ["678", "1"]
],
"05/09/2024": [
  ["570", "2"],
  ["226", "0"],
  ["148", "3"],
  ["480", "2"],
  ["379", "9"],
  ["180", "9"],
  ["350", "8"],
  ["259", "6"],
  ["140", "5"],
  ["249", "5"],
  ["457", "6"],
  ["330", "6"]
],
"06/09/2024": [
  ["179", "7"],
  ["367", "6"],
  ["679", "2"],
  ["460", "0"],
  ["356", "4"],
  ["237", "2"],
  ["168", "5"],
  ["257", "4"],
  ["789", "4"],
  ["338", "4"],
  ["480", "2"],
  ["289", "9"]
],
"07/09/2024": [
  ["236", "1"],
  ["128", "1"],
  ["246", "2"],
  ["160", "7"],
  ["117", "9"],
  ["899", "6"],
  ["356", "4"],
  ["335", "1"],
  ["130", "4"],
  ["390", "2"],
  ["600", "6"],
  ["178", "6"]
],
"08/09/2024": [
  ["470", "1"],
  ["568", "9"],
  ["158", "4"],
  ["230", "5"],
  ["268", "6"],
  ["359", "7"],
  ["124", "7"],
  ["478", "9"],
  ["334", "0"],
  ["140", "5"],
  ["449", "7"],
  ["255", "2"]
],
"09/09/2024": [
  ["569", "0"],
  ["457", "6"],
  ["250", "7"],
  ["699", "4"],
  ["135", "9"],
  ["245", "1"],
  ["345", "2"],
  ["189", "8"],
  ["588", "1"],
  ["479", "0"],
  ["567", "8"],
  ["680", "4"]
],
"10/09/2024": [
        ["156", "2"],
        ["278", "7"],
        ["357", "5"],
        ["459", "8"],
        ["678", "1"],
        ["270", "9"],
        ["559", "9"],
        ["167", "4"],
        ["226", "0"],
        ["480", "2"],
        ["159", "5"],
        ["157", "3"]
    ],
    "11/09/2024": [
        ["377", "7"],
        ["280", "0"],
        ["448", "6"],
        ["790", "6"],
        ["578", "0"],
        ["237", "2"],
        ["188", "7"],
        ["229", "3"],
        ["269", "7"],
        ["149", "4"],
        ["336", "2"],
        ["679", "2"]
    ],
    "12/09/2024": [
        ["127", "0"],
        ["337", "3"],
        ["440", "8"],
        ["179", "7"],
        ["556", "6"],
        ["130", "4"],
        ["335", "1"],
        ["570", "2"],
        ["137", "1"],
        ["380", "1"],
        ["300", "3"],
        ["560", "1"]
    ],
    "13/09/2024": [
        ["248", "4"],
        ["146", "1"],
        ["229", "3"],
        ["369", "8"],
        ["490", "3"],
        ["890", "7"],
        ["259", "6"],
        ["368", "7"],
        ["780", "5"],
        ["348", "5"],
        ["178", "6"],
        ["237", "2"]
    ],
    "18/09/2024": [
      ["460", "0"],
      ["155", "1"],
      ["557", "7"],
      ["247", "3"],
      ["199", "9"],
      ["147", "2"],
      ["558", "8"],
      ["456", "5"],
      ["158", "4"],
      ["349", "6"],
      ["148", "3"],
      ["448", "6"]
  ],
  "19/09/2024": [
      ["559", "9"],
      ["289", "9"],
      ["133", "7"],
      ["248", "4"],
      ["347", "4"],
      ["128", "1"],
      ["135", "9"],
      ["237", "2"],
      ["379", "9"],
      ["600", "6"],
      ["227", "1"],
      ["268", "6"]
  ],
  "20/09/2024": [
      ["178", "6"],
      ["255", "2"],
      ["224", "8"],
      ["589", "2"],
      ["900", "9"],
      ["167", "4"],
      ["568", "9"],
      ["478", "9"],
      ["345", "2"],
      ["590", "4"],
      ["279", "8"],
      ["127", "0"]
  ],
  "21/09/2024": [
      ["399", "1"],
      ["226", "0"],
      ["179", "7"],
      ["145", "0"],
      ["139", "3"],
      ["249", "5"],
      ["380", "1"],
      ["469", "9"],
      ["790", "6"],
      ["456", "5"],
      ["117", "9"],
      ["290", "1"]
  ],   "22/09/2024": [
    ["569", "0"],
    ["116", "8"],
    ["359", "7"],
    ["137", "1"],
    ["277", "6"],
    ["338", "4"],
    ["138", "2"],
    ["190", "0"],
    ["460", "0"],
    ["589", "2"],
    ["150", "6"],
    ["259", "6"]
],
"23/09/2024": [
    ["389", "0"],
    ["246", "2"],
    ["140", "5"],
    ["257", "4"],
    ["357", "5"],
    ["780", "5"],
    ["467", "7"],
    ["167", "4"],
    ["360", "9"],
    ["680", "4"],
    ["135", "9"],
    ["490", "3"]
],
"24/09/2024": [
    ["270", "9"],
    ["256", "3"],
    ["340", "7"],
    ["446", "4"],
    ["166", "3"],
    ["237", "2"],
    ["480", "2"],
    ["113", "5"],
    ["590", "4"],
    ["155", "1"],
    ["245", "1"],
    ["368", "7"]
],
"25/09/2024": [
    ["137", "1"],
    ["233", "8"],
    ["138", "2"],
    ["449", "7"],
    ["369", "8"],
    ["157", "3"],
    ["450", "9"],
    ["358", "6"],
    ["247", "3"],
    ["567", "8"],
    ["670", "3"],
    ["126", "9"]
],
"26/09/2024": [
    ["168", "5"],
    ["360", "9"],
    ["780", "5"],
    ["346", "3"],
    ["479", "0"],
    ["289", "9"],
    ["380", "1"],
    ["190", "0"],
    ["237", "2"],
    ["145", "0"],
    ["248", "4"],
    ["127", "0"]
],
"27/09/2024": [
  ["234", "9"],
  ["334", "0"],
  ["770", "4"],
  ["367", "6"],
  ["566", "7"],
  ["455", "4"],
  ["134", "8"],
  ["459", "8"],
  ["556", "6"],
  ["250", "7"],
  ["388", "9"],
  ["269", "7"]
],
"28/09/2024": [
  ["480", "2"],
  ["235", "0"],
  ["560", "1"],
  ["778", "2"],
  ["114", "6"],
  ["147", "2"],
  ["344", "1"],
  ["468", "8"],
  ["236", "1"],
  ["225", "9"],
  ["150", "6"],
  ["678", "1"]
],
"29/09/2024": [
  ["690", "5"],
  ["140", "5"],
  ["490", "3"],
  ["119", "1"],
  ["144", "9"],
  ["478", "9"],
  ["600", "6"],
  ["467", "7"],
  ["900", "9"],
  ["349", "6"],
  ["380", "1"],
  ["240", "6"]
],
"30/09/2024": [
  ["289", "9"],
  ["358", "6"],
  ["159", "5"],
  ["890", "7"],
  ["169", "6"],
  ["389", "0"],
  ["130", "4"],
  ["268", "6"],
  ["180", "9"],
  ["167", "4"],
  ["999", "7"],
  ["390", "2"]
],
"01/10/2024": [
  ["568", "9"],
  ["679", "2"],
  ["280", "0"],
  ["457", "6"],
  ["245", "1"],
  ["149", "4"],
  ["470", "1"],
  ["128", "1"],
  ["780", "5"],
  ["340", "7"],
  ["489", "1"],
  ["460", "0"]
],
// "07/10/2024": [
//   ["147", "2"],
//   ["260", "8"],
//   ["200", "2"],
//   ["158", "4"],
//   ["449", "7"],
//   ["340", "7"],
//   ["139", "3"],
//   ["235", "0"],
//   ["790", "6"],
//   ["347", "4"],
//   ["178", "6"],
//   ["370", "0"]
// ],
// "08/10/2024": [
//   ["167", "4"],
//   ["560", "1"],
//   ["479", "0"],
//   ["238", "3"],
//   ["590", "4"],
//   ["127", "0"],
//   ["246", "2"],
//   ["160", "7"],
//   ["369", "8"],
//   ["477", "8"],
//   ["290", "1"],
//   ["458", "7"]
// ],
// "09/10/2024": [
//   ["159", "5"],
//   ["499", "2"],
//   ["669", "1"],
//   ["136", "0"],
//   ["138", "2"],
//   ["400", "4"],
//   ["890", "7"],
//   ["346", "3"],
//   ["244", "0"],
//   ["179", "7"],
//   ["237", "2"],
//   ["690", "5"]
// ],
// "10/10/2024": [
//   ["360", "9"],
//   ["228", "2"],
//   ["379", "9"],
//   ["277", "6"],
//   ["700", "7"],
//   ["259", "6"],
//   ["129", "2"],
//   ["280", "0"],
//   ["349", "6"],
//   ["137", "1"],
//   ["457", "6"],
//   ["250", "7"]
// ],



};
